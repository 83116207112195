import * as React from "react";
import "./styles.scss";
import cow from "assests/icons/statsCard/Group.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { column } from "./data";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/Vector (4).png";
import AddProduce from "components/Modals/AddProduce";
import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import { getManufacturingSummary, getManufacturingTransactions } from "store/slice/TransactionSlice/TransactionSlice";
import DateRangePicker from "components/DateRangePicker";
import { format } from "date-fns";
import { getProduceChart } from "store/slice/InventorySlice/InventorySlice";
import Chart from "components/Chart";
import { checkPermission } from "utils/utils";
import GenerateReportButton from "components/GenerateReportButton";
import axios from "axios";
type Props = {};

const Manufacturing: React.FunctionComponent<Props> = () => {
  // State
  const [page, setPage] = useState<number>(1);
  const [produceId, setProduceId] = useState<string>("");
  const [produceName, setProduceName] = useState<string>("");
  const [perpage, setPerpage] = useState<number>(10);
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [modal, setModal] = useState<boolean>(false);
  const componentJustMounted = useRef<boolean>(true);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date()
  });
  const [csvData, setCsvData] = useState([]);

  //Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const { produceChart } = useSelector((state: RootState) => state.inventory);
  const { userToken } = useSelector((state: RootState) => state.user);
  const { manufacturingSummary, manufacturingTransactions } = useSelector((state: RootState) => state.transaction);
  const { permissions } = useSelector((state: RootState) => state.user);

  // Get Chart Data for Produce
  const chartData =
    produceChart &&
    produceChart?.map(produce => {
      return {
        quantity: produce.totalQuantity,
        time: format(new Date(produce._id["date"]), "yyyy-MM-dd | hh:mmbb")
      };
    });

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  //Get produce_id from history state
  useEffect(() => {
    let isMounted = true;
    if (location.state && isMounted) {
      const { produceID, produceName }: any = location.state;
      if (produceID) {
        setProduceId(produceID);
        setProduceName(produceName);
        dispatch(getManufacturingTransactions(page, perpage, undefined, produceID));
        dispatch(getManufacturingSummary(produceID));
        dispatch(getProduceChart(produceID));

        const DownloadDataReport = async () => {
          const response = await axios.get(
            `${BASE_URL}/transaction/manufacturing/get?page=1&perpage=3000&produceId=${produceID}`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
          );
          setCsvData(response?.data?.transactions);
        };

        DownloadDataReport();
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  //CSV Report Headers
  const headers = [
    { label: "Date", key: "date" },
    { label: "Produce", key: "produce_name" },
    { label: "Quantity", key: "produce_quantity" },
    { label: "Rating", key: "produce_rating" }
  ];

  //search
  useEffect(() => {
    let timer: any;
    if (!componentJustMounted.current) {
      timer = setTimeout(() => {
        if (search === inputRef.current!.value) {
          const { produceID }: any = location.state;
          dispatch(getManufacturingTransactions(1, perpage, search, produceID));
        }
      }, 500);
    }

    componentJustMounted.current = false;
    return () => {
      clearTimeout(timer);
    };
  }, [search, inputRef]);

  //event handler
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
  };

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback((page: number, perpage: number) => {
    setPage(page);
    dispatch(getManufacturingTransactions(page, perpage, search, produceId));
  }, []);

  const paginate = useCallback((pageNo: number, perpage: number) => {
    setPage(pageNo);
    dispatch(getManufacturingTransactions(pageNo, perpage, search, produceId));
  }, []);

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = (pageNo: number, page = 1) => {
    setPerpage(pageNo);
    setPage(1);
    dispatch(getManufacturingTransactions(page, pageNo, search, produceId));
  };

  //------------------------------------------------
  //modal toggler
  //------------------------------------------------
  const closeModal = () => {
    setModal(!modal);
  };

  //Date Handler
  const handleDateChange = date => {
    setDateRange({ ...dateRange, startDate: date.startDate, endDate: date.endDate });
    dispatch(
      getManufacturingTransactions(
        1,
        1000,
        search !== "" ? search : undefined,
        produceId,
        format(new Date(date.startDate), "yyy-MM-dd"),
        format(new Date(date.endDate), "yyy-MM-dd")
      )
    );
  };

  return (
    <div id="manufacturing-page">
      {modal && checkPermission(permissions, "INVENTORY_CREAT") && (
        <AddProduce closeModal={closeModal} produceID={produceId} produceName={produceName} />
      )}
      <div className="container">
        <CardContainer title={`${produceName} Inventory Stats`} classes="mb-4">
          <StatsCard
            statsNumber={manufacturingSummary?.totalQuantity || 0}
            text="Produce Qty"
            classes="stats-icon-orange"
            image={cow}
            litre="Lt."
          />
          <StatsCard image={Icon} classes="middle-img" centerText="Add Produce" onClick={closeModal} />
        </CardContainer>

        <div className="chart">
          <h4 className="page-title mb-3">{`Volume Graph - ${produceName}`}</h4>
          <Chart chartData={chartData} />
        </div>

        <h4 className="page-title mb-2"> {`${produceName} Inventory Logs`} </h4>
        <div className="search-field mb-2 ">
          <SearchIcon
            placeholder="Search for Produce or Inventory log"
            boxClasses=" mb-2"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>
        <div className="row gap mb-3">
          <div className=" col-md-3 col-sm-12 col-xs-12">
            <DateRangePicker range={dateRange} handleDateChange={handleDateChange} />
          </div>
          <div className=" col-md col-sm-12 col-xs-12 d-flex justify-content-end">
            <GenerateReportButton
              filename={`Inventory Manfactury ProduceId:${produceId}`}
              header={headers}
              data={csvData}
            />
          </div>
        </div>

        <DataTable columns={column} data={manufacturingTransactions?.transactions} />
        <Pagination
          page={page}
          lastPage={manufacturingTransactions?.pagination?.lastPage}
          paginate={paginate}
          total={manufacturingTransactions?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Manufacturing;
