import "./styles.scss";

export const collectionData = [
  {
    point: "Ojuju C.point",
    location: "Ikeja"
  },
  {
    point: "Ojuju C.point",
    location: "Ikeja"
  }
];

export const collectionColumn = [
  {
    name: "C.Point Name",
    index: "pointName",
    id: 1
  },
  {
    name: "Location",
    index: "location",
    id: 2
  },
  {
    name: " Community",
    index: "community_name",
    id: 3
  },
  {
    name: " LGA",
    index: "lga",
    id: 4
  },
  {
    name: "Actions",
    id: 5,
    render: ({ edit, id, deleteCol, pointName }) => {
      return (
        <div className="span-container">
          <p className="edit" onClick={() => edit(id)}>
            Edit
          </p>
          <p className="delete pl-3" onClick={() => deleteCol(id, pointName)}>
            Delete
          </p>
        </div>
      );
    }
  }
];
