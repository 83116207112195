import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createUser, editUser, getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { CreateUserPayload } from "store/slice/UserMgtSlice/interface";
import "./styles.scss";
import { getSingleUser } from "store/slice/UserMgtSlice/UserMgtSlice";

type Props = {
    closeModal: () => void;
    modalData: { userId: string; viewStatus: boolean; editStatus: boolean };
};
const CreateUser = ({ closeModal, modalData }: Props) => {
    const [error, setError] = React.useState("");
    const { register, handleSubmit, errors, setValue } = useForm<FormData>({
        mode: "onChange"
    });

    type FormData = {
        firstName: string;
        lastName: string;
        // password: string;
        email: string;
        phoneNo: string;
    };

    const { userId, viewStatus, editStatus } = modalData;
    //Redux Store
    const dispatch = useDispatch();
    const { roles } = useSelector((state: RootState) => state.roles);
    const { isLoading, singleUser } = useSelector((state: RootState) => state.userMgt);

    //Get roles from backend
    React.useEffect(() => {
        dispatch(getRoles());
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Get single user details

    React.useEffect(() => {
        if (viewStatus || editStatus) {
            dispatch(getSingleUser(userId));
        }
    }, [userId, viewStatus, editStatus]);

    //Update fields
    React.useEffect(() => {
        if (singleUser !== null && (editStatus || viewStatus)) {
            setValue("firstName", singleUser?.firstName);
            setValue("email", singleUser?.email);
            setValue("lastName", singleUser?.lastName);
            setValue("phoneNo", singleUser?.phoneNo);
        }
    }, [singleUser, editStatus, viewStatus]);

    const rolesList =
        roles &&
        roles?.myroles?.map((data: any) => {
            return {
                name: data.role_name,
                id: data._id
            };
        });

    const roleName = () => {
        let theRole;
        if (rolesList && (editStatus || viewStatus)) {
            rolesList.forEach((role) => {
                if (role.id === singleUser?.role) theRole = role.name;
            });
        }
        return theRole;
    };

    const { open, selectedOption, handleDropdownChange, handleAction, keyOption, dropdownRef } = useArrowDropdown();

    const validatePhoneNo = (value: any) => {
        let values = value.replace(/[^0-9]*/g, "");
        if (value.length > 11 || value !== values) return "Should be a phone number";
    };

    const onFormSubmit = (vals: FormData) => {
        if (isLoading) return;
        if (selectedOption === "" && !editStatus) {
            setError("Please select a role");
            return;
        }
        const { ...rest } = vals;
        const data: CreateUserPayload = { ...rest, role: keyOption, password: "test" };

        const editData = {
            firstName: vals.firstName,
            lastName: vals.lastName,
            role: keyOption === "" ? singleUser?.role : keyOption,
            phoneNo: vals.phoneNo,
            email: vals.email
        };
        if (editStatus) {
            dispatch(
                editUser(editData, userId, () => {
                    closeModal();
                    dispatch(getUsers(1, 10));
                })
            );
        } else {
            dispatch(
                createUser(data, () => {
                    closeModal();
                    dispatch(getUsers(1, 10));
                })
            );
        }
    };

    //Remove error message
    React.useEffect(() => {
        if (keyOption) {
            setError("");
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyOption]);

    return (
        <Modal
            modalText={editStatus ? "Edit User" : "Create User"}
            onClose={closeModal}
            modallength={!viewStatus ? "modal-length" : ""}
        >
            <div id="createuser-modal">
                {!viewStatus && !editStatus ? (
                    <p className="">Complete this form with accurate data to create a new user on Sebore farms.</p>
                ) : null}
                <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
                    <Textbox
                        type="text"
                        name="firstName"
                        label="First Name"
                        placeholder="First Name"
                        error={errors.firstName && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        disabled={viewStatus}
                    />
                    <Textbox
                        type="text"
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name"
                        boxClasses="mt-3"
                        error={errors.lastName && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        disabled={viewStatus}
                    />
                    <ArrowDropdown
                        text="Role"
                        options={rolesList}
                        handleDropdownChange={handleDropdownChange}
                        handleAction={handleAction}
                        selectedOption={selectedOption || roleName()}
                        open={open}
                        boxClasses="mt-3"
                        ref={dropdownRef}
                    />
                    <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
                    <Textbox
                        type="email"
                        label="Email address"
                        placeholder="Email address"
                        boxClasses="mt-3"
                        name="email"
                        error={errors.email && errors.email.message}
                        customRef={register({
                            required: "This field is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                        disabled={viewStatus}
                    />
                    {/* {!viewStatus && !editStatus ? (
                        <Textbox
                            type="password"
                            label="Password"
                            placeholder="Password"
                            boxClasses="mt-3"
                            name="password"
                            error={errors.password && "This field is required"}
                            customRef={register({
                                required: true
                            })}
                        />
                    ) : null} */}

                    <Textbox
                        type="text"
                        name="phoneNo"
                        label="Phone Number"
                        error={errors.phoneNo && errors.phoneNo.message}
                        customRef={register({
                            required: "This field is required",
                            validate: validatePhoneNo
                        })}
                        placeholder="Phone Number"
                        boxClasses="mt-3"
                        disabled={viewStatus}
                    />
                    {!viewStatus ? (
                        <Button
                            label={editStatus ? "Edit User" : "Create User"}
                            btnType="btn-primary"
                            boxClasses="mt-3 mb-5"
                            btnStyle="100%"
                            loading={isLoading}
                        />
                    ) : null}
                </form>
            </div>
        </Modal>
    );
};

export default CreateUser;
