import "./styles.scss";

export const roleColumn = [
  {
    name: "Role Name",
    index: "role_name",
    id: 1
  },
  {
    name: "Number of users",
    index: "users",
    id: 2
  },
  {
    name: "Actions",
    id: 3,
    render: ({ edit, role_name, deleteRoleId, id, permission }) => {
      return (
        <span className="span-container">
          <p className="edit" onClick={() => edit(role_name, id, permission)}>
            Edit
          </p>
          <p className="delete pl-3" onClick={() => deleteRoleId(id, role_name)}>
            Delete
          </p>
        </span>
      );
    }
  }
];
