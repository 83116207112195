import { toastr } from "react-redux-toastr";
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { Actions, TransactionState } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: TransactionState = {
    isLoading: false,
    topFarmers: null,
    successMessage: "",
    errorMessage: "",
    transactions: null,
    unpaid: null,
    paid: null,
    summary: null,
    loading: null,
    payingBulk: null,
    manufacturingSummary: null,
    manufacturingTransactions: null,
    topCommunity: null,
    eyowoBalance: null
};

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        getTopFarmersLoading: (state) => {
            state.isLoading = true;
        },
        getTopFarmersSuccess: (state, action: PayloadAction<Actions>) => {
            state.isLoading = false;
            state.topFarmers = action.payload;
        },
        getTopFarmersFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        getTopCommunityLoading: (state) => {
            state.isLoading = true;
        },
        getTopCommunitySuccess: (state, action: PayloadAction<Actions>) => {
            state.isLoading = false;
            state.topCommunity = action.payload;
        },
        getTopCommunityFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        payFarmerLoading: (state) => {
            state.isLoading = true;
        },
        payFarmerSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        payFarmFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        getTranactionsLoading: (state) => {
            state.isLoading = true;
        },
        getTransactionsSuccessFul: (state, action: PayloadAction<Actions>) => {
            state.isLoading = false;
            state.transactions = action.payload;
        },
        getTransactionsFail: (state) => {
            state.isLoading = false;
        },
        getTransactionSummaryLoading: (state) => {
            // state.isLoading = true;
        },
        getTransactionSummaryPaid: (state, action: PayloadAction<Actions>) => {
            state.paid = action.payload;
        },
        getTransactionSummaryUnpaid: (state, action: PayloadAction<Actions>) => {
            state.unpaid = action.payload;
        },
        getTransactionSummaryOthers: (state, action: PayloadAction<Actions>) => {
            state.summary = action.payload;
        },
        getTransactionSummaryFail: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },
        getManufacturingSummaryRequest: (state) => {
            state.loading = true;
        },
        getManufacturingSummarySuccess: (state, action: PayloadAction<Actions>) => {
            state.loading = false;
            state.manufacturingSummary = action.payload;
        },
        getManufacturingSummaryFail: (state, action: PayloadAction<string>) => {
            state.loading = false;
        },
        getManufacturingTransactionsRequest: (state) => {
            state.loading = true;
        },
        getManufacturingTransactionsSuccess: (state, action: PayloadAction<Actions>) => {
            state.loading = false;
            state.manufacturingTransactions = action.payload;
        },
        getManufacturingTransactionsFail: (state, action: PayloadAction<string>) => {
            state.loading = false;
        },
        payBulkRequest: (state) => {
            state.payingBulk = true;
        },
        payBulkSuccess: (state) => {
            state.payingBulk = false;
        },
        payBulkFail: (state, action: PayloadAction<string>) => {
            state.payingBulk = false;
        },
        getQauntityRequest: (state) => {
            state.isLoading = true;
        },
        getQauntitySuccess: (state) => {
            state.isLoading = false;
        },
        getQauntityFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        eyowoBalance: (state, action: PayloadAction<Actions>) => {
            state.eyowoBalance = action.payload;
        }
    }
});

const { actions, reducer } = transactionSlice;

export const {
    getTopFarmersLoading,
    getTopFarmersSuccess,
    getTopFarmersFail,
    payFarmFail,
    payFarmerLoading,
    payFarmerSuccess,
    getTranactionsLoading,
    getTransactionsSuccessFul,
    getTransactionsFail,
    getTransactionSummaryLoading,
    getTransactionSummaryFail,
    getTransactionSummaryOthers,
    getTransactionSummaryPaid,
    getTransactionSummaryUnpaid,
    getManufacturingSummaryRequest,
    getManufacturingSummarySuccess,
    getManufacturingSummaryFail,
    getManufacturingTransactionsRequest,
    getManufacturingTransactionsSuccess,
    getManufacturingTransactionsFail,
    payBulkRequest,
    payBulkSuccess,
    payBulkFail,
    getTopCommunityLoading,
    getTopCommunitySuccess,
    getTopCommunityFail,
    getQauntityRequest,
    getQauntitySuccess,
    getQauntityFail,
    eyowoBalance
} = actions;

export const getTopFarmers =
    (page: number, perpage: number, search?: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getTopFarmersLoading());
        try {
            const response = await axios.get(`${BASE_URL}/transaction/get/farmers/top`, {
                params: {
                    page,
                    perpage,
                    search
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getTopFarmersSuccess(response.data));
            }
        } catch (error) {
            dispatch(getTopFarmersFail(error?.response?.data?.message));
        }
    };
export const getTopCommunity =
    (page: number, perpage: number, search?: string, community?: string, state?: string, lga?: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getTopCommunityLoading());
        try {
            const response = await axios.get(`${BASE_URL}/transaction/get/community/top`, {
                params: {
                    page,
                    perpage,
                    search,
                    community,
                    state,
                    lga
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getTopCommunitySuccess(response.data));
            }
        } catch (error) {
            console.log(error);

            dispatch(getTopCommunityFail(error?.response?.data?.message));
        }
    };
type PayFarmer = {
    farmer_id: string;
    produce_id: string;
    produce_quantity: number;
    rating: number;
    deduction: number;
};
export const payFarmer =
    (payload: PayFarmer, cb): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(payFarmerLoading());
        try {
            const response = await axios.post(`${BASE_URL}/transaction/pay`, payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.status) {
                dispatch(payFarmerSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb();
            }
        } catch (error) {
            dispatch(payFarmFail(error?.response?.data?.message));
            toastr.error(error.response.data.message);
        }
    };

export const getTransactions =
    (
        page?: number,
        perpage?: number,
        search?: string,
        dateFrom?: string,
        dateTo?: string,
        farmerId?: string,
        category?: string,
        status?: string,
        produceId?: string,
        householdId?: string,
        householdGeneratedId?: string,
        payFrequency?: string,
        community?: string,
        eyowoStatus?: string,
        messageStatus?: string
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getTranactionsLoading());
        try {
            const response = await axios.get(`${BASE_URL}/transaction/get`, {
                params: {
                    search,
                    dateFrom,
                    dateTo,
                    category,
                    status,
                    produceId,
                    householdGeneratedId,
                    householdId,
                    farmerId,
                    page,
                    perpage,
                    payFrequency,
                    community,
                    eyowoStatus,
                    messageStatus
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.status) {
                dispatch(getTransactionsSuccessFul(response.data));
            }
        } catch (error) {
            // console.log(error);
            dispatch(getTransactionsFail());
        }
    };

export const getSummary =
    (
        search?: string,
        status?: string,
        payFrequency?: string,
        community?: string,
        eyowoStatus?: string,
        messageStatus?: string,
        dateFrom?: string,
        dateTo?: string,
        produceId?: string,
        householdId?: string
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getTransactionSummaryLoading());

        try {
            const response = await axios.get(`${BASE_URL}/transaction/summary`, {
                params: {
                    search,
                    status,
                    payFrequency,
                    community,
                    eyowoStatus,
                    messageStatus,
                    dateFrom,
                    dateTo,
                    produceId,
                    householdId
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.status) {
                dispatch(getTransactionSummaryOthers(response.data));
            }
        } catch (error) {
            console.log(error);
            dispatch(getTransactionSummaryFail("Fail"));
        }
        // try {
        //     const response = await axios.get(`${BASE_URL}/transaction/summary`, {
        //         params: {
        //             search,
        //             dateFrom,
        //             dateTo,
        //             status: "paid",
        //             produceId,
        //             householdId
        //         },
        //         headers: {
        //             Authorization: `Bearer ${userToken}`
        //         }
        //     });
        //     if (response.status) {
        //         dispatch(getTransactionSummaryPaid(response.data.numberOfTransactions));
        //     }
        // } catch (error) {
        //     console.log(error);
        //     dispatch(getTransactionSummaryFail("Fail"));
        // }
        // try {
        //     const response = await axios.get(`${BASE_URL}/transaction/summary`, {
        //         params: {
        //             search,
        //             dateFrom,
        //             dateTo,
        //             status: "owing",
        //             produceId,
        //             householdId
        //         },
        //         headers: {
        //             Authorization: `Bearer ${userToken}`
        //         }
        //     });
        //     if (response.status) {
        //         dispatch(getTransactionSummaryUnpaid(response.data.numberOfTransactions));
        //     }
        // } catch (error) {
        //     dispatch(getTransactionSummaryFail("Fail"));
        // }
        // try {
        //     const response = await axios.get(`${BASE_URL}/transaction/summary`, {
        //         params: {
        //             search,
        //             dateFrom,
        //             dateTo,
        //             status,
        //             produceId,
        //             householdId
        //         },
        //         headers: {
        //             Authorization: `Bearer ${userToken}`
        //         }
        //     });
        //     if (response.status) {
        //         dispatch(getTransactionSummaryOthers(response.data));
        //     }
        // } catch (error) {
        //     dispatch(getTransactionSummaryFail("Fail"));
        // }
    };

export const getManufacturingSummary =
    (
        produceId?: string,
        search?: string,
        dateFrom?: string,
        dateTo?: string,
        category?: string,
        status?: string
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;

        dispatch(getManufacturingSummaryRequest());
        try {
            const response = await axios.get(`${BASE_URL}/transaction/manufacturing/summary`, {
                params: {
                    produceId,
                    search,
                    dateFrom,
                    dateTo,
                    category,
                    status
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getManufacturingSummarySuccess(response.data));
            }
        } catch (error) {
            console.log(error);

            dispatch(getManufacturingSummaryFail(error?.response?.data?.message));
        }
    };

export const getManufacturingTransactions =
    (
        page?: number,
        perpage?: number,
        search?: string,
        produceId?: string,
        dateFrom?: string,
        dateTo?: string,
        status?: string
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;

        dispatch(getManufacturingTransactionsRequest());
        try {
            const response = await axios.get(`${BASE_URL}/transaction/manufacturing/get`, {
                params: {
                    page,
                    perpage,
                    search,
                    produceId,
                    dateFrom,
                    dateTo,
                    status
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getManufacturingTransactionsSuccess(response.data));
            }
        } catch (error) {
            console.log(error);

            dispatch(getManufacturingTransactionsFail(error?.response?.data?.message));
        }
    };

export const payBulk =
    (data: object, cb?: any): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(payBulkRequest());
        try {
            const response = await axios.post(`${BASE_URL}/transaction/pay/bulk`, data, {
                // params: {
                //     dateFrom,
                //     dateTo
                // },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(payBulkSuccess());
                toastr.success("", response.data.message);
                cb();
            }
        } catch (error) {
            // console.log(error);

            dispatch(payBulkFail(error?.response?.data?.message));
            toastr.error(error?.response?.data?.message);
        }
    };
export const getQuantity =
    (community_id?: string, commuinity_name?: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getQauntityRequest());
        try {
            const response = await axios.get(`${BASE_URL}/transaction/quantity/get`, {
                params: {
                    community_id,
                    commuinity_name
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getQauntitySuccess());
            }
        } catch (error) {
            dispatch(getQauntityFail(error?.response?.data?.message));
        }
    };
export const getEyowoBalance = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
        const response = await axios.get(`${BASE_URL}/transaction/eyowoBalance`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(eyowoBalance(response.data.balance));
        }
    } catch (error) {}
};

export default reducer;
