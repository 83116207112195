import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { lgaState, Action } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: lgaState = {
    isLoading: false,
    lga: null,
    singlelgaId: null,
    successMessage: "",
    errorMessage: ""
};

const LgaSlice = createSlice({
    name: "lga",
    initialState,
    reducers: {
        getLgaLoading: (state) => {
            state.isLoading = true;
        },
        getLgaSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.lga = action.payload;
        },
        getLgaFail: (state) => {
            state.isLoading = false;
        },
        deleteLgaLoading: (state) => {
            state.isLoading = true;
        },
        deleteLgaSuccess: (state) => {
            state.isLoading = false;
        },
        deleteLgaFail: (state) => {
            state.isLoading = false;
        },
        getLgaIdLoading: (state) => {
            state.isLoading = true;
        },
        getLgaIdsuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.singlelgaId = action.payload;
        },
        getLgaIdFail: (state) => {
            state.isLoading = false;
        },
        createLgaLoading: (state) => {
            state.isLoading = true;
        },
        createLgaSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createLgaFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editLgaLoading: (state) => {
            state.isLoading = true;
        },
        editLgaSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editLgaFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = LgaSlice;

export const {
    getLgaLoading,
    getLgaSuccess,
    getLgaFail,
    deleteLgaFail,
    deleteLgaLoading,
    deleteLgaSuccess,
    getLgaIdFail,
    getLgaIdLoading,
    getLgaIdsuccess,
    createLgaLoading,
    createLgaSuccess,
    createLgaFail,
    editLgaFail,
    editLgaLoading,
    editLgaSuccess
} = actions;

export const getLga = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getLgaLoading());
    try {
        const response = await axios.get(`${BASE_URL}/lga/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getLgaSuccess(response.data));
        }
    } catch (error) {
        dispatch(getLgaFail());
    }
};

export const deleteLga = (id: string, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteLgaLoading());
    try {
        const response = await axios.post(`${BASE_URL}/lga/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deleteLgaSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(deleteLgaFail());
        toastr.error(error.response.data.message);
    }
};

export const getLgaId = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getLgaIdLoading());
    try {
        const response = await axios.get(`${BASE_URL}/lga/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getLgaIdsuccess(response.data));
        }
    } catch (error) {
        dispatch(getLgaIdFail());
    }
};

export const createLga = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createLgaLoading());
    try {
        const response = await axios.post(`${BASE_URL}/lga/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createLgaSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createLgaFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export const editLga = (data: any, id:string, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editLgaLoading());
    try {
        const response = await axios.post(`${BASE_URL}/lga/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editLgaSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editLgaFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
