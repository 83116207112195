import React, { useEffect, useState } from "react";
import DataTable from "components/DataTable";
import "./styles.scss";
import { loans, loansColumn } from "./data";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store/store";
import { createLoanFail, getLoan } from "store/slice/LoanSlice/loanSlice";
import CreateLoan from "components/Modals/CreateLoan";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";
import useSearch from "hooks/useSearch";
import Dropdown from "components/Dropdown";
import SearchIcon from "components/SearchBar";

type Props = {};
const Loans: React.FunctionComponent<Props> = (props: Props) => {
  //State
  const [modal, setModal] = useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [perpage, setPerpage] = React.useState<number>(10);

  //Hook
  const dispatch = useDispatch();
  const { permissions } = useSelector((state: RootState) => state.user);
  // const { search, inputRef, handleChange } = useSearch(getLoan, perpage);
  let { userId }: any = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    dispatch(getLoan(page, perpage, undefined, userId));
  }, []);
  const { loan, isLoading } = useSelector((state: RootState) => state.loans);

  const loanList =
    loans &&
    loan?.loan?.map((data: any) => {
      return {
        amountDeducted: data.amountDeducted,
        description: data.description,
        amount: data.amount
      };
    });

  //modal handler
  const toggleModal = () => {
    setModal(!modal);
    dispatch(createLoanFail(""));
  };

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perpage: number) => {
    setPage(page);
    dispatch(getLoan(page, perpage, undefined, userId));
  }, []);

  const paginate = React.useCallback((pageNo: number, perpage: number) => {
    setPage(pageNo);
    dispatch(getLoan(pageNo, perpage, undefined, userId));
  }, []);

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = (pageNo: number, page = 1) => {
    setPerpage(pageNo);
    setPage(1);
    dispatch(getLoan(page, pageNo, undefined, userId));
  };

  return (
    <div id="loan-page">
      {modal && checkPermission(permissions, "LOAN_CREATE") && <CreateLoan closeModal={toggleModal} />}
      <div className="col-xs-12 pt-4">
        <div className="d-flex pb-3 mb-4">
          <h4 className="mr-5">Loans</h4>
          <Button
            label="Add New Loan"
            btnType="btn-primary"
            btnStyle="100%"
            xtraClass="xtraClass"
            onClick={toggleModal}
          />
        </div>
        <div className="search-field">
          {/* <SearchIcon
                        placeholder="Search for First name, Phone no. or email"
                        boxClasses=" mb-3"
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    /> */}
          {/* <Dropdown perPage={perpage} action={handleNewPage} /> */}
        </div>
        <DataTable columns={loansColumn} data={loanList} loading={isLoading} />
        <Pagination
          page={page}
          lastPage={loan?.pagination?.lastPage}
          paginate={paginate}
          total={loan?.pagination?.total}
          handlePageChange={(loan?.pagination?.hasNext || loan?.pagination?.hasPrevious) && handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Loans;
