import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "./reducer/index";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { logout } from "store/slice/UserSlice/UserSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["user"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Check for expired token and logout user if Expired
const checkTokenExpirationMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === "user/loggingOut") {
        return;
    }
    const tokenID: any = localStorage.getItem("sebore");
    const token = JSON.parse(tokenID);
    if (token !== null) {
        const decodedToken = JwtDecode<JwtPayload>(token);
        if (decodedToken.exp! < Date.now() / 1000) {
            dispatch(logout());
        }

        next(action);
    } else next(action);
};

const isDevMode = process.env.NODE_ENV === "development";
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(checkTokenExpirationMiddleware),
    devTools: isDevMode //only show dev tools if we are in development mode
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const persistor = persistStore(store);

export default store;
