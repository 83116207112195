import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { communityState, Action, CreateCommunityPayload } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: communityState = {
    isLoading: false,
    community: null,
    communityId: null,
    successMessage: "",
    errorMessage: ""
};

const communitySlice = createSlice({
    name: "community",
    initialState,
    reducers: {
        getCommunityLoading: (state) => {
            state.isLoading = true;
        },
        getCommunitySuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.community = action.payload;
        },
        getCommunityFail: (state) => {
            state.isLoading = false;
        },
        deleteCommunityLoading: (state) => {
            state.isLoading = true;
        },
        deletecommunitySuccess: (state) => {
            state.isLoading = false;
        },
        deleteCommunityFail: (state) => {
            state.isLoading = false;
        },
        getCommunityIdLoading: (state) => {
            state.isLoading = true;
        },
        getCommunityIdsuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.communityId = action.payload;
        },
        getCommunityIdFail: (state) => {
            state.isLoading = false;
        },
        createCommunityLoading: (state) => {
            state.isLoading = true;
        },
        createCommunitySuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createCommunityFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editCommunityLoading: (state) => {
            state.isLoading = true;
        },
        editcommunitySuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editCommunityFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = communitySlice;

export const {
    getCommunityLoading,
    getCommunitySuccess,
    getCommunityFail,
    deleteCommunityFail,
    deleteCommunityLoading,
    deletecommunitySuccess,
    getCommunityIdFail,
    getCommunityIdLoading,
    getCommunityIdsuccess,
    createCommunityLoading,
    createCommunitySuccess,
    createCommunityFail,
    editCommunityFail,
    editCommunityLoading,
    editcommunitySuccess
} = actions;

export const getCommunity = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCommunityLoading());
    try {
        const response = await axios.get(`${BASE_URL}/community/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getCommunitySuccess(response.data));
        }
    } catch (error) {
        dispatch(getCommunityFail());
    }
};

export const deleteCommunity = (id?: string, cb?: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteCommunityLoading());
    try {
        const response = await axios.post(`${BASE_URL}/community/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deletecommunitySuccess());
            toastr.success("", response.data.message);
            cb!();
        }
    } catch (error) {
        dispatch(deleteCommunityFail());
        toastr.error(error.response.data.message);
    }
};

export const getCommunityId = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCommunityIdLoading());
    try {
        const response = await axios.get(`${BASE_URL}/community/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getCommunityIdsuccess(response.data));
        }
    } catch (error) {
        dispatch(getCommunityIdFail());
    }
};

export const createCommunity = (data: CreateCommunityPayload, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(createCommunityLoading());
    try {
        const response = await axios.post(`${BASE_URL}/community/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createCommunitySuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createCommunityFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export const editCommunity = (data: CreateCommunityPayload, id, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(editCommunityLoading());
    try {
        const response = await axios.post(`${BASE_URL}/community/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editcommunitySuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editCommunityFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
