import React from "react";
import "./styles.scss";

type Props = {
    children?: React.ReactNode;
    title: string;
    classes?:string;
};

const CardContainer = ({ children, title, classes }: Props) => {
    return (
        <div className={`quick-stats ${classes}`}>
            <h4 className="page-title">{title}</h4>
            <div className="row mt-2">{children}</div>
        </div>
    );
};
export default CardContainer;
