import React from "react";
import "./styles.scss";
import { format } from "date-fns";


export const CommunityColumn = [
    {
        name: "Community Name",
        index: "community_name",
        id: 1
    },
    {
        name: "Community Code",
        index: "community_code",
        id: 2
    },
    {
        name: "Actions",
        id: 3,
        render: ({ edit, providerName, deleteCommunityHandle, id }) => {
            return (
                <span className="span-container">
                    <p className="edit" onClick={() => edit(providerName, id)}>
                        Edit
                    </p>
                    <p className="delete pl-3" onClick={deleteCommunityHandle}>
                        Delete
                    </p>
                </span>
            );
        }
    }
];
