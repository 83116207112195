import { toastr } from "react-redux-toastr";
import { AppThunk, AppDispatch } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { Action, CreateInventoryPayload, inventoryState } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: inventoryState = {
    isLoading: false,
    inventory: null,
    errorMessage: "",
    summary: null,
    produceChart: null
};

const inventorySlice = createSlice({
    name: "inventory",
    initialState,
    reducers: {
        inventoryRequest: (state) => {
            state.isLoading = true;
        },
        inventorySuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.inventory = action.payload;
        },
        inventoryFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        inventorySummaryLoading: (state) => {
            state.isLoading = true;
        },
        inventorySummarySuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.summary = action.payload;
        },
        inventorySummaryFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        createInventoryLoading: (state) => {
            state.isLoading = true;
        },
        createInventorySuccess: (state) => {
            state.isLoading = false;
        },
        createInventoryFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        getProduceChartLoading: (state) => {
            state.isLoading = true;
        },
        getProduceChartSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.produceChart = action.payload;
        },
        getProduceChartFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = inventorySlice;

export const {
    inventoryRequest,
    inventorySuccess,
    inventoryFail,
    inventorySummaryLoading,
    inventorySummarySuccess,
    inventorySummaryFail,
    createInventoryLoading,
    createInventorySuccess,
    createInventoryFail,
    getProduceChartFail,
    getProduceChartLoading,
    getProduceChartSuccess
} = actions;

export const getInventory = (page: number, perPage: number, produceId?: string, search?: string): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(inventoryRequest());
    try {
        const response = await axios.get(`${BASE_URL}/inventory/get`, {
            params: {
                page,
                perPage,
                produceId,
                search
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(inventorySuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        dispatch(inventoryFail("Fail"));
    }
};

export const getInventorySummary = (produceId?: string, search?: string, category?: string): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(inventorySummaryLoading());
    try {
        const response = await axios.get(`${BASE_URL}/inventory/summary`, {
            params: {
                produceId,
                search,
                category
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(inventorySummarySuccess(response.data));
        }
    } catch (error) {
        dispatch(inventorySummaryFail("Fail"));
    }
};

export const createInventory = (payload: CreateInventoryPayload, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(createInventoryLoading());
    try {
        const response = await axios.post(`${BASE_URL}/inventory/create`, payload, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(createInventorySuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createInventoryFail(""));
        toastr.error(error.response.data.message);
    }
};

export const getProduceChart = (produceId: string, dateFrom?: any, dateTo?: any): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(getProduceChartLoading());
    try {
        const response = await axios.get(`${BASE_URL}/inventory/chart`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            },
            params: {
                produceId,
                dateFrom,
                dateTo
            }
        });
        if (response.status) {
            dispatch(getProduceChartSuccess(response.data.inventories));
        }
    } catch (error) {
        dispatch(getProduceChartFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
