import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/people 2 2.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { usersColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/Vector (4).png";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, activateUser, deactivateUser, adminReset } from "store/slice/UserMgtSlice/UserMgtSlice";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import CreateUserModal from "components/Modals/CreateOrEditUserModal";
import { toastr } from "react-redux-toastr";
import { toastPopUp } from "utils/utils";

type Props = {};

const Users: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = React.useState<number>(1);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [tableID] = React.useState();
  const { search, inputRef, handleChange } = useSearch(getUsers, perpage);
  const [modalData, setModalData] = React.useState({
    userId: "",
    viewStatus: false,
    editStatus: false
  });

  //Redux Hook
  const dispatch = useDispatch();
  const { users } = useSelector((state: RootState) => state.userMgt);
  const { permissions } = useSelector((state: RootState) => state.user);

  //Get Users
  React.useEffect(() => {
    dispatch(getUsers(page, perpage));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (modal === false) {
      setModalData({ ...modalData, userId: "", viewStatus: false, editStatus: false });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const viewUser = (id: string) => {
    closeModal();
    setModalData({ ...modalData, userId: id, viewStatus: true, editStatus: false });
  };

  const editUser = (id: string) => {
    if (!permissions?.includes("USER_EDIT")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    closeModal();
    setModalData({ ...modalData, viewStatus: false, userId: id, editStatus: true });
  };

  const deleteUserId = (id: string, name: string) => {
    if (!permissions?.includes("USER_DELETE")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }

    toastPopUp(
      () => {
        dispatch(
          deleteUser(id, () => {
            dispatch(getUsers(page, perpage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the user",
      name,
      "red"
    );
  };

  const activateUserID = (id: string, name: string) => {
    if (!permissions?.includes("USER_ACTIVATE")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    toastPopUp(
      () => {
        dispatch(
          activateUser(id, () => {
            dispatch(getUsers(page, perpage));
          })
        );
      },
      "Activate",
      "Cancel",
      "Activate",
      name,
      "#00B050"
    );
  };

  const deactivateUserID = (id: string, name: string) => {
    if (!permissions?.includes("USER_DEACTIVATE")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    toastPopUp(
      () => {
        dispatch(
          deactivateUser(id, () => {
            dispatch(getUsers(page, perpage));
          })
        );
      },
      "Deactivate",
      "Cancel",
      "Deactivate",
      name,
      "#737a91"
    );
  };

  const ResetUser = (id: string, name: string) => {
    if (!permissions?.includes("USER_RESET_PASSWORD")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    toastPopUp(
      () => {
        dispatch(
          adminReset(id, () => {
            dispatch(getUsers(page, perpage));
          })
        );
      },
      "Reset",
      "Cancel",
      "Reset the User",
      name,
      "orange"
    );
  };

  const userList =
    users &&
    users?.users?.map((data: any) => {
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNo: data.phoneNo,
        email: data.email,
        role: data.role_name.role_name,
        // toggleDropdown: handleTableDropdown,
        userId: data._id,
        status: data.status,
        tableID: tableID,
        view: () => viewUser(data._id),
        edit: () => editUser(data._id),
        deleteUserId: () => deleteUserId(data._id, data.firstName),
        activateUserID: () => activateUserID(data._id, data.firstName),
        deactivateUserID: () => deactivateUserID(data._id, data.firstName),
        resetUser: () => ResetUser(data._id, data.firstName)
      };
    });

  const handlePageChange = React.useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(getUsers(page, perpage, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(getUsers(pageNo, perpage, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getUsers(page, pageNo, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const closeModal = () => {
    setModal(!modal);
  };

  //Create User
  const createUser = () => {
    if (!permissions?.includes("USER_CREATE")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    closeModal();
  };

  return (
    <div id="user-management-page">
      {modal && <CreateUserModal closeModal={closeModal} modalData={modalData} />}
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={users?.pagination?.total || 0} text="Users" classes="stats-icon" image={People} />
          <StatsCard image={Icon} classes="middle-img" centerText="Create User" onClick={createUser} />
        </div>
        <h4 className="page-title mb-2">User Logs</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for First name, Phone no. or email"
            boxClasses=" mb-3"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>

        <DataTable columns={usersColumn} data={userList} />
        <Pagination
          page={page}
          lastPage={users?.pagination?.lastPage}
          paginate={paginate}
          total={users?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Users;
