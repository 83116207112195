import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import DataTable from "components/DataTable";
import { farmersColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { getTopFarmers } from "store/slice/TransactionSlice/TransactionSlice";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import Pagination from "components/Pagination";
import useSearch from "hooks/useSearch";

type Props = {};

const TopFarmers: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getTopFarmers, perPage);

  //-------------------------------------------------------------------
  const dispatch = useDispatch();
  const transaction = useSelector((state: RootState) => state.transaction);

  useEffect(() => {
    dispatch(getTopFarmers(page, perPage));
  }, []);

  // setpage number to 1 if searched is done
  React.useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = useCallback(
    (pageNo: number, page = 1) => {
      setPerPage(pageNo);
      setPage(1);
      dispatch(getTopFarmers(page, pageNo, search));
    },
    [search]
  );
  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getTopFarmers(page, perPage, search));
    },
    [search]
  );

  const paginate = useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getTopFarmers(pageNo, perPage, search));
    },
    [search]
  );

  return (
    <div className="topfarmers-page mt-4">
      <div className="container">
        <h4 className="page-title mb-2">Top 10 Farmers’</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for farmers’name, BVN or ID"
            boxClasses=" mb-4"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          {/* <Dropdown perPage={perPage} action={handleNewPage} /> */}
        </div>

        <DataTable columns={farmersColumn} data={transaction?.topFarmers?.farmers} loading={transaction?.isLoading} />
        {/* <Pagination
                    page={page}
                    lastPage={transaction?.topFarmers?.pagination?.lastPage}
                    paginate={paginate}
                    total={transaction?.topFarmers?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perPage}
                /> */}
      </div>
    </div>
  );
};

export default TopFarmers;
