import React from "react";
import "./styles.scss";
import DropdownMenu from "components/DropdownMenu";

export const produceData = [
    {
        produce: "Milk",
        scale: "kg",
        unit: "N700"
    },
    {
        produce: "Milk",
        scale: "kg",
        unit: "N700"
    }
];

export const produceColumn = [
    {
        name: "Produce Name",
        index: "produce_name",
        id: 1
    },
    {
        name: "Measurement Scale",
        index: "scale",
        id: 2
    },
    {
        name: " Unit price",
        index: "unit_price",
        id: 3
    },
    {
        name: "Actions",
        id: 4,
        render: ({ deleteProduceId, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteProduceId
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
