import React, { useState, useCallback } from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { inventoryColumn } from "./data";
import CardContainer from "components/CardContainer";
import Cash from "assests/icons/statsCard/cash.png";
import { useDispatch, useSelector } from "react-redux";
import { getInventory, getInventorySummary } from "store/slice/InventorySlice/InventorySlice";
import Pagination from "components/Pagination";
import { RootState } from "store/store";

type Props = {};

const Inventory: React.FunctionComponent<Props> = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getInventory(page, perpage));
    dispatch(getInventorySummary());
  }, []);

  const { inventory, summary } = useSelector((state: RootState) => state.inventory);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback((page: number, perpage: number) => {
    setPage(page);
    dispatch(getInventory(page, perpage));
  }, []);

  const paginate = useCallback((pageNo: number, perpage: number) => {
    setPage(pageNo);
    dispatch(getInventory(pageNo, perpage));
  }, []);

  return (
    <div id="inventory-page">
      <div className="container">
        <CardContainer title="Inventory Stats" classes="mb-5">
          <StatsCard
            statsNumber={summary?.totalNumberOfUniqueProduce || 0}
            text="Produce Rep."
            classes="stats-icon-cash"
            image={Cash}
          />
        </CardContainer>
        <DataTable columns={inventoryColumn} data={inventory?.inventory} />
        <Pagination
          page={page}
          lastPage={inventory?.pagination?.lastPage}
          paginate={paginate}
          total={inventory?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Inventory;
