import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import "./styles.scss";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { ProfilePasswordPayload } from "store/slice/UserSlice/interface";
import { updateProfilePassword } from "store/slice/UserSlice/UserSlice";

const ProfilePassword = () => {
  const { user, profile, loading } = useSelector((state: RootState) => state.user);
  const { register, watch, errors, handleSubmit, reset } = useForm<ProfilePasswordPayload>({
    mode: "onBlur"
  });
  const values = watch();

  const dispatch = useDispatch();
  const onFormSubmit = (vals: ProfilePasswordPayload) => {
    dispatch(
      updateProfilePassword(vals, user._id, () => {
        reset();
      })
    );
  };

  //Validating Password
  const validatePassword = (value: string) => {
    if (value !== values.newPassword) {
      return "The passwords do not match";
    }
  };
  return (
    <div id="profile-password-page">
      <div className="col-xs-12 pt-4">
        <h4 className="mb-3">Change Password</h4>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            label="Current Password"
            placeholder="Current Password"
            boxClasses="mb-3 input-width"
            name="currentPassword"
            type="password"
            customRef={register({
              required: true
            })}
            error={errors.currentPassword && "This field is required"}
          />
          <Textbox
            label="New Password"
            placeholder="New Password"
            boxClasses="mb-3 input-width"
            type="password"
            name="newPassword"
            customRef={register({
              required: true
            })}
            error={errors.newPassword && "This field is required"}
          />
          <Textbox
            label="Confirm New Password"
            placeholder="Confirm New Password"
            boxClasses="mb-3 input-width"
            type="password"
            name="confirmPassword"
            customRef={register({
              required: "This field is required",
              validate: validatePassword
            })}
            error={errors.confirmPassword && errors.confirmPassword.message}
          />
          <Button
            type="submit"
            label="Save Changes"
            btnType="btn-primary"
            btnStyle=""
            loading={loading}
            boxClasses="mt-4 input-width"
            xtraClass="btn-class"
          />
        </form>
      </div>
    </div>
  );
};

export default ProfilePassword;
