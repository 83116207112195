import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";
import {
  createDispatchEmployee,
  editDispatchEmployee,
  getDispatchEmployees
} from "store/slice/DispatchEmployeeSlice/DispatchEmployeeSlice";

type Props = {
  closeModal: () => void;
  employeeId?: string;
  dispatchproviders?: [];
  item?: object;
};
const CreateOrEditEmployee = ({ closeModal, employeeId = "", dispatchproviders = [], item = {} }: Props) => {
  //Redux Store
  const dispatch = useDispatch();
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.dispatchEmployee);
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  //Hooks
  const { open, selectedOption, handleDropdownChange, handleAction, handleToggle, setSelectedOption, dropdownRef } =
    useArrowDropdown();
  const {
    open: soaopen,
    selectedOption: soaselectedOption,
    handleDropdownChange: soahandleDropdownChange,
    handleAction: soahandleAction,
    handleToggle: soahandleToggle,
    setSelectedOption: soasetSelectedOption,
    dropdownRef: soadropdownRef
  } = useArrowDropdown();

  useEffect(() => {
    if (selectedOption) {
      setError("");
    }
    if (soaselectedOption) {
      setError2("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soaselectedOption, selectedOption]);

  useEffect(() => {
    if (item && item !== {}) {
      setValue("employeeName", item["employeeName"]);
      setValue("employeePhoneNo", item["employeePhoneNo"]);
      setSelectedOption(item["dispatchProvider"]);
      soasetSelectedOption(item["status"]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type FormData = {
    employeeName: string;
    employeePhoneNo: string;
    dispatchProvider: string;
    status: string;
  };

  const status = [{ name: "activated" }, { name: "deactivated" }];

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    if (selectedOption === "" && item && !item["dispatchProvider"]) {
      setError("Please select a dispatch provider");
      return;
    }
    if (soaselectedOption === "" && item && !item["status"]) {
      setError2("Please select a status");
      return;
    }

    const data = {
      employeeName: vals.employeeName,
      employeePhoneNo: vals.employeePhoneNo,
      dispatchProvider: selectedOption,
      status: soaselectedOption
    };
    const editData = {
      employeeName: vals.employeeName,
      employeePhoneNo: vals.employeePhoneNo,
      dispatchProvider: selectedOption === "" ? item["dispatchProvider"] : selectedOption,
      status: soaselectedOption === "" ? item["status"] : soaselectedOption
    };
    if (item && item["employeeName"]) {
      dispatch(
        editDispatchEmployee(editData, employeeId, () => {
          closeModal();
          dispatch(getDispatchEmployees(1, 10));
        })
      );
    } else {
      dispatch(
        createDispatchEmployee(data, () => {
          closeModal();
          dispatch(getDispatchEmployees(1, 10));
        })
      );
    }
  };

  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  return (
    <Modal
      modalText={item && item["employeeName"] ? "Edit Employee" : "Create Employee"}
      onClose={closeModal}
      boxClasses=""
    >
      <div className="role-modal">
        <form className="" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            label="Employee Name"
            placeholder="Enter employee name"
            name="employeeName"
            error={errors.employeeName && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="number"
            label="Phone No."
            placeholder="Enter employee phone no."
            name="employeePhoneNo"
            error={errors.employeePhoneNo && errors.employeePhoneNo.message}
            customRef={register({
              required: "Must be a number",
              validate: validatePhoneNo
            })}
            boxClasses="mt-3"
          />
          <ArrowDropdown
            text="Select dispatch Provider"
            options={dispatchproviders}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            handleToggle={handleToggle}
            open={open}
            boxClasses="mt-4"
            ref={dropdownRef}
          />
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          <ArrowDropdown
            text="Select Status"
            options={status}
            handleDropdownChange={soahandleDropdownChange}
            handleAction={soahandleAction}
            selectedOption={soaselectedOption}
            handleToggle={soahandleToggle}
            open={soaopen}
            boxClasses="mt-4"
            ref={soadropdownRef}
          />
          <p className={`${error2 ? "d-block" : "d-none"} error mt-1`}>{error2}</p>
          {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
          <Button
            label={item && item["employeeName"] ? "Edit Employee" : "Create Employee"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default CreateOrEditEmployee;
