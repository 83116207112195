import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { stateState, Action } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: stateState = {
    isLoading: false,
    states: null,
    singleState: null,
    successMessage: "",
    errorMessage: ""
};

const stateSlice = createSlice({
    name: "states",
    initialState,
    reducers: {
        getStatesLoading: (state) => {
            state.isLoading = true;
        },
        getStatesSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.states = action.payload;
        },
        getStatesFail: (state) => {
            state.isLoading = false;
        },
        deleteStateLoading: (state) => {
            state.isLoading = true;
        },
        deleteStateSuccess: (state) => {
            state.isLoading = false;
        },
        deleteStateFail: (state) => {
            state.isLoading = false;
        },
        getStateIdLoading: (state) => {
            state.isLoading = true;
        },
        getStateIdsuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.singleState = action.payload;
        },
        getStateIdFail: (state) => {
            state.isLoading = false;
        },
        createStateLoading: (state) => {
            state.isLoading = true;
        },
        createStateSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createStateFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editStateLoading: (state) => {
            state.isLoading = true;
        },
        editStateSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editStateFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = stateSlice;

export const {
    getStatesLoading,
    getStatesSuccess,
    getStatesFail,
    deleteStateFail,
    deleteStateLoading,
    deleteStateSuccess,
    getStateIdFail,
    getStateIdLoading,
    getStateIdsuccess,
    createStateLoading,
    createStateSuccess,
    createStateFail,
    editStateFail,
    editStateLoading,
    editStateSuccess
} = actions;

export const getStates = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getStatesLoading());
    try {
        const response = await axios.get(`${BASE_URL}/state/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getStatesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getStatesFail());
    }
};

export const deleteState = (id: string, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteStateLoading());
    try {
        const response = await axios.post(`${BASE_URL}/state/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deleteStateSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(deleteStateFail());
        toastr.error(error.response.data.message);
    }
};

export const getStateId = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getStateIdLoading());
    try {
        const response = await axios.get(`${BASE_URL}/state/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getStateIdsuccess(response.data));
        }
    } catch (error) {
        dispatch(getStateIdFail());
    }
};

export const createState = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createStateLoading());
    try {
        const response = await axios.post(`${BASE_URL}/state/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createStateSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createStateFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export const editState = (data: any, id, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editStateLoading());
    try {
        const response = await axios.post(`${BASE_URL}/state/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editStateSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editStateFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
