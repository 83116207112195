import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import Cash from "assests/icons/statsCard/cash.png";
import Leaf from "assests/icons/statsCard/leaf-sharp.png";
import Invalid from "assests/icons/statsCard/Vector (1).png";
import Valid from "assests/icons/statsCard/Vector.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { transactionColumn } from "./data";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getSummary, getTransactions } from "store/slice/TransactionSlice/TransactionSlice";
import Dropdown from "components/Dropdown";
import Pagination from "components/Pagination";
import { RootState } from "store/store";
import ArrowDropdown from "components/ArrowDropdown";
import useArrowDropdown from "hooks/useArrowDropdown";
import Button from "components/Button";
import BulkPay from "components/Modals/BulkPayment";
import TransactionModal from "components/Modals/TransactionModal";
import DateRangePicker from "components/DateRangePicker";
import { format } from "date-fns";
import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import { formatNumber } from "utils/utils";
import GenerateReportButton from "components/GenerateReportButton";
import axios from "axios";
import AsynSelectBox from "components/AsyncSelectBox";
type Props = {};

const Transaction: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [search, setSearch] = useState("");
  const [modal, setModal] = React.useState<boolean>(false);
  const [trans, setTransModal] = React.useState<boolean>(false);
  const [showBulk, setShowBulk] = React.useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [singleTrans, setSingleTrans] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date()
  });
  const [lgaName, setLgaName] = useState("");
  const [stateName, setStateName] = useState("");
  const [selectComboxOption, setSelectComboxOption] = React.useState({});
  const [options, setOptions] = React.useState<any>([]);
  const componentJustMounted = useRef<boolean>(true);
  const [csvData, setCsvData] = useState([]);
  const [inputValue, setValue] = useState("");

  //Status hook
  const { open, selectedOption, handleDropdownChange, handleAction, dropdownRef } = useArrowDropdown();

  //Payment Frequency hook
  const {
    open: open2,
    selectedOption: selectedOption2,
    handleDropdownChange: handleDropdownChange2,
    handleAction: handleAction2,
    dropdownRef: dropdownRef2
  } = useArrowDropdown();

  //Redux store
  const dispatch = useDispatch();
  const { transactions, summary, isLoading } = useSelector((state: RootState) => state.transaction);
  const { userToken } = useSelector((state: RootState) => state.user);
  const { community } = useSelector((state: RootState) => state.community);
  const { states } = useSelector((state: RootState) => state.stateReducer);
  const { lga } = useSelector((state: RootState) => state.lga);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  //This Fires On Compoenent Mount
  useEffect(() => {
    dispatch(getTransactions(page, perpage, search !== "" ? search : undefined));
    dispatch(getSummary());
    dispatch(getCommunity(page, 100));

    const DownloadDataReport = async () => {
      const response = await axios.get(`${BASE_URL}/transaction/get?page=1&perpage=3000`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });

      const reportdata = response?.data?.transactions.map(data => {
        return {
          newStatus: statusChecker(data.status, data.eyowoStatus, data.messageStatus, data.message),
          ...data
        };
      });

      setCsvData(reportdata);
    };

    DownloadDataReport();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Filter querys
  const params: any = [
    format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
    format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
      ? format(new Date(dateRange.startDate), "yyy-MM-dd")
      : undefined,
    format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
    format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
      ? format(new Date(dateRange.endDate), "yyy-MM-dd")
      : undefined,
    undefined,
    undefined,
    selectedOption !== "" && selectedOption !== "All" ? (selectedOption === "Unpaid" ? "owing" : "Paid") : undefined,
    undefined,
    undefined,
    undefined,
    selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
    selectComboxOption["label"] !== "" && selectComboxOption["label"] !== "All"
      ? selectComboxOption["label"]
      : undefined,
    ["Processing", "Failed"].includes(selectedOption) ? "Pending" : selectedOption === "Paid" ? "Complete" : undefined,
    selectedOption === "Failed" ? "true" : selectedOption === "Processing" ? "false" : undefined
  ];

  //Fires On Search Filtering
  useEffect(() => {
    let timer: any;
    if (!componentJustMounted.current) {
      timer = setTimeout(() => {
        if (search === inputRef.current!.value) {
          setPage(1);
          dispatch(getTransactions(1, perpage, search, ...params));
          dispatch(
            getSummary(
              search !== "" ? search : undefined,
              selectedOption !== "" && selectedOption !== "All"
                ? selectedOption === "Unpaid"
                  ? "owing"
                  : "Paid"
                : undefined,
              selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
              selectComboxOption["label"] !== "" && selectComboxOption["label"] !== "All"
                ? selectComboxOption["label"]
                : undefined,
              ["Processing", "Failed"].includes(selectedOption)
                ? "Pending"
                : selectedOption === "Paid"
                ? "Complete"
                : undefined,
              selectedOption === "Failed" ? "true" : selectedOption === "Processing" ? "false" : undefined,
              format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
                format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
                ? format(new Date(dateRange.startDate), "yyy-MM-dd")
                : undefined,
              format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
                format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
                ? format(new Date(dateRange.endDate), "yyy-MM-dd")
                : undefined
            )
          );
        }
      }, 500);
    }
    componentJustMounted.current = false;
    return () => {
      clearTimeout(timer);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, inputRef]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
  };

  //Pagination
  const handlePageChange = useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(getTransactions(page, perpage, search, ...params));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOption, selectedOption2, search, selectComboxOption["label"]]
  );

  const paginate = useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(getTransactions(pageNo, perpage, search, ...params));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOption, selectedOption2, search, selectComboxOption["label"]]
  );

  const handleNewPage = (pageNo: number, page = 1, perpage = pageNo) => {
    setPerpage(pageNo);
    setPage(1);
    dispatch(getTransactions(page, perpage, search, ...params));
  };

  const status = [
    {
      name: "All",
      style: "all_tag"
    },
    {
      name: "Paid",
      style: "paid_tag "
    },
    {
      name: "Unpaid",
      style: "unpaid_tag"
    },
    {
      name: "Processing",
      style: "pro_tag"
    },
    {
      name: "Failed",
      style: "failed_tag"
    }
  ];

  // open Bulk payment modal
  const closeModal = () => {
    setModal(!modal);
  };

  // open transaction modal
  const openTrans = () => {
    setTransModal(!trans);
  };

  const transactionModal = e => {
    openTrans();
    setSingleTrans(e);
  };

  //Function Payment Status
  const statusChecker = (status: string, eyowoStatus: string, messageStatus: string | boolean, msg: string): string => {
    let newStatus: string = "Owing";
    if (status === "Paid" && eyowoStatus === "Complete" && msg === "Successful") {
      newStatus = "Paid";
    }
    if (status === "Paid" && eyowoStatus === "Pending" && msg === "Insufficient Funds" && messageStatus === "true") {
      newStatus = "Failed";
    }
    if (status === "Paid" && eyowoStatus === "Pending" && messageStatus === "false") {
      newStatus = "Processing";
    }

    if (status === "Owing") {
      newStatus = "Owing";
    }
    return newStatus;
  };
  const transactionData =
    transactions &&
    transactions?.transactions?.map((data: any) => {
      return {
        // status: data.status,
        status: statusChecker(data.status, data.eyowoStatus, data.messageStatus, data.message),
        date: data.date,
        made: data.made,
        _id: data._id,
        transaction_ref: data.transaction_ref,
        farmer_id: data.farmer_id,
        farmer: data.farmer,
        farmer_name: data.farmer_name,
        farmer_state: data.farmer_state,
        produce: data.produce,
        produce_name: data.produce_name,
        produce_amount: data.produce_amount,
        produce_rating: data.produce_rating,
        category: data.category,
        produce_quantity: formatNumber(data.produce_quantity),
        eyowoStatus: data.eyowoStatus,
        messageStatus: data.messageStatus,
        message: data.message,
        household_id: data.farmer_household,
        action: transactionModal
      };
    });

  const handleDateChange = date => {
    let status = "owing";
    let eyowoStatus: string | undefined = undefined;

    if (selectedOption === "Paid") {
      status = "Paid";
      eyowoStatus = "Complete";
    }

    if (["Processing", "Failed"].includes(selectedOption)) {
      status = "Paid";
      eyowoStatus = "Pending";
    }

    setDateRange({ ...dateRange, startDate: date.startDate, endDate: date.endDate });
    dispatch(
      getTransactions(
        page,
        perpage,
        search !== "" ? search : undefined,
        // format(new Date(date.startDate), "yyy-MM-dd"),
        // format(new Date(date.endDate), "yyy-MM-dd"),
        format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
          format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
          ? format(new Date(dateRange.startDate), "yyy-MM-dd")
          : undefined,
        format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
          format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
          ? format(new Date(dateRange.endDate), "yyy-MM-dd")
          : undefined,
        undefined,
        undefined,
        selectedOption !== "" && selectedOption !== "All" ? status : undefined,
        undefined,
        undefined,
        undefined,
        selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
        selectComboxOption["label"] !== "" && selectComboxOption["label"] !== "All"
          ? selectComboxOption["label"]
          : undefined,
        eyowoStatus,
        selectedOption === "Failed" ? "true" : selectedOption === "Processing" ? "false" : undefined
      )
    );
    dispatch(
      getSummary(
        search !== "" ? search : undefined,
        selectedOption !== "" && selectedOption !== "All" ? status : undefined,
        selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
        selectComboxOption["label"] !== "" && selectComboxOption["label"] !== "All"
          ? selectComboxOption["label"]
          : undefined,
        eyowoStatus,
        selectedOption === "Failed" ? "true" : selectedOption === "Processing" ? "false" : undefined,
        format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
          format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
          ? format(new Date(dateRange.startDate), "yyy-MM-dd")
          : undefined,
        format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
          format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
          ? format(new Date(dateRange.endDate), "yyy-MM-dd")
          : undefined
      )
    );
  };

  React.useEffect(() => {
    const test = community?.community.map(
      (community: { community_name: string; _id: string; state: string; lga: string }) => {
        return {
          label: community.community_name,
          value: community._id,
          dState: community.state,
          lga: community.lga
        };
      }
    );
    if (test!) setOptions([{ label: "All" }, ...test]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  React.useEffect(() => {
    if (Object.keys(selectComboxOption).length !== 0 && selectComboxOption["value"] !== "") {
      stateList?.map((data: any) => {
        if (selectComboxOption["dState"] === data.id) {
          setStateName(data.name);
        }
      });

      lgaList?.map((data: any) => {
        if (selectComboxOption["lga"] === data.id) {
          setLgaName(data.name);
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectComboxOption]);

  const onSelectChange = vals => {
    setSelectComboxOption(vals);
  };
  //Get State
  const stateList =
    states &&
    states?.state?.map((data: any) => {
      return {
        name: data.state_name,
        id: data._id
      };
    });

  //Get LGA
  const lgaList =
    lga &&
    lga?.lga?.map((data: any) => {
      return {
        name: data.lga_name,
        id: data._id
      };
    });

  const payFrequency = [
    {
      name: "All"
    },
    {
      name: "Weekly"
    },
    {
      name: "Bi-weekly"
    },
    {
      name: "Monthly"
    }
  ];

  //Filter for Payment frequency, Transaction Status and Community
  useEffect(() => {
    let option = selectedOption;
    let eyowoStatus: string | undefined = undefined;
    if (
      (selectedOption2 !== "" && selectedOption2) ||
      (selectComboxOption["label"] !== "" && selectComboxOption["label"]) ||
      (selectedOption !== "" && selectedOption)
    ) {
      //filter State for status to activate/display the Pay All Button
      if (selectedOption && selectedOption === "Unpaid") {
        option = "owing";
        setTimeout(() => {
          setShowBulk(true);
        }, 200);
      } else setShowBulk(false);

      if (["Processing", "Failed"].includes(selectedOption)) {
        option = "Paid";
        eyowoStatus = "Pending";
      }

      if (selectedOption === "Paid") eyowoStatus = "Complete";
      dispatch(
        getTransactions(
          1,
          perpage,
          search !== "" ? search : undefined,
          format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
            format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
            ? format(new Date(dateRange.startDate), "yyy-MM-dd")
            : undefined,
          format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
            format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
            ? format(new Date(dateRange.endDate), "yyy-MM-dd")
            : undefined,
          undefined,
          undefined,
          selectedOption !== "" && selectedOption !== "All" ? option : undefined,
          undefined,
          undefined,
          undefined,
          selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
          selectComboxOption["label"] !== "" && selectComboxOption["label"] !== "All"
            ? selectComboxOption["label"]
            : undefined,
          eyowoStatus,
          selectedOption === "Failed" ? "true" : selectedOption === "Processing" ? "false" : undefined
        )
      );
      dispatch(
        getSummary(
          search !== "" ? search : undefined,
          selectedOption !== "" && selectedOption !== "All" ? option : undefined,
          selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
          selectComboxOption["label"] !== "" && selectComboxOption["label"] !== "All"
            ? selectComboxOption["label"]
            : undefined,
          eyowoStatus,
          selectedOption === "Failed" ? "true" : selectedOption === "Processing" ? "false" : undefined,
          format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
            format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
            ? format(new Date(dateRange.startDate), "yyy-MM-dd")
            : undefined,
          format(new Date(dateRange.startDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd") &&
            format(new Date(dateRange.endDate), "yyy-MM-dd") !== format(new Date(), "yyy-MM-dd")
            ? format(new Date(dateRange.endDate), "yyy-MM-dd")
            : undefined
        )
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption2, selectComboxOption["label"], selectedOption]);

  //Data for PayBulK Modal
  const bulkPayData = {
    payFrequency: selectedOption2,
    community: selectComboxOption["label"],
    lga: lgaName,
    state: stateName
  };

  //CSV Report Headers
  const headers = [
    { label: "Date", key: "date" },
    { label: "Transaction Ref", key: "transaction_ref" },
    { label: "Farmer Name", key: "farmer_name" },
    { label: "Farmer Community", key: "farmer_community" },
    { label: "Payment Freq.", key: "payment_frequency" },
    { label: "Status", key: "newStatus" },
    { label: "Status Message", key: "message" }
  ];

  const showPayAll = !isLoading && showBulk && selectedOption === "Unpaid" && transactionData.length >= 1;

  // handle input change event

  const handleInputChange = (value: React.SetStateAction<string>) => {
    setValue(value);
  };

  //Edge Case
  const loadOptions = (inputValue: string) => {
    return axios
      .get(`${BASE_URL}/community/get?search=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      })
      .then(res =>
        res.data.community.map((community: { community_name: string; _id: string }) => {
          return { label: community.community_name, value: community._id };
        })
      )
      .catch();
  };

  return (
    <div id="transaction-page">
      {modal && <BulkPay closeModal={closeModal} search={search} dateRange={dateRange} bulkPayData={bulkPayData} />}
      <div className="container">
        <CardContainer title="Transaction" classes="mb-5">
          <StatsCard
            statsNumber={summary?.totalAmount ?? 0}
            text="Value (Amount)"
            classes="stats-icon-cash"
            image={Cash}
            amount="N"
          />
          {trans && <TransactionModal closeModal={openTrans} singleTrans={singleTrans} />}

          <StatsCard
            statsNumber={summary?.totalQuantity ?? 0}
            text="Volume"
            classes="stats-icon-leaf"
            image={Leaf}
            volume="kg"
          />

          <StatsCard
            statsNumber={summary?.totalPaidTransactions ?? 0}
            text="Paid Transactions"
            classes="stats-icon-valid"
            image={Valid}
          />

          <StatsCard
            statsNumber={summary?.totalUnPaidTransactions ?? 0}
            text="Unpaid Trans."
            classes="stats-icon-invalid"
            image={Invalid}
          />
        </CardContainer>

        <h4 className="page-title mb-2">Transaction List</h4>

        <div className="search-field">
          <SearchIcon
            placeholder="Search for Farmer ID or Transaction Ref. or Date"
            boxClasses=" mb-4"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>
        {/* <ReportContainer /> */}
        <div className="container">
          <div className="row gap">
            <div className=" col-md-3 col-sm-12 col-xs-12 mb-sm-2 pl-0 pl-lg-0 pr-lg-2 ">
              <DateRangePicker range={dateRange} handleDateChange={handleDateChange} />
            </div>
            <div className=" col-md-2 col-sm-12 col-xs-12 pl-0 pl-lg-0 pr-lg-2">
              <div className="">
                <ArrowDropdown
                  text="Status"
                  options={status}
                  handleDropdownChange={handleDropdownChange}
                  handleAction={handleAction}
                  selectedOption={selectedOption}
                  open={open}
                  ref={dropdownRef}
                />
              </div>
            </div>
            <div className={` col-md-2 col-sm-12 col-xs-12 pl-0 pl-lg-0 pr-lg-2`}>
              <div className="arrow-div">
                {/* <ArrowDropdown
                                text="Community"
                                options={communities}
                                handleDropdownChange={handleDropdownChange3}
                                handleAction={handleAction3}
                                selectedOption={selectComboxOption["label"]}
                                open={open3}
                                boxScroll="box-scroll"
                            /> */}
                {/* 
                                <SelectBox
                                    options={options}
                                    placeholder="Communities"
                                    label="Search Communities"
                                    boxClasses=""
                                    onChange={onSelectChange}
                                    legend={false}
                                    isDisabled={isLoading}
                                    error={selectComboxErrorOption ? "This field is required" : ""}
                                /> */}
                <AsynSelectBox
                  legend={false}
                  placeholder="Communities"
                  options={options}
                  onChange={onSelectChange}
                  onInputChange={handleInputChange}
                  loadedOptions={loadOptions}
                />
              </div>
            </div>
            <div className={` col-md-2 col-sm-12 col-xs-12 pl-0 pl-lg-0 pr-lg-2`}>
              <ArrowDropdown
                text="Payment Frequency"
                options={payFrequency}
                handleDropdownChange={handleDropdownChange2}
                handleAction={handleAction2}
                selectedOption={selectedOption2}
                open={open2}
                ref={dropdownRef2}
              />
            </div>
            <div className=" col-md col-sm-12 col-xs-12 pl-sm-0 pr-lg-0 pb-2 report d-flex justify-content-end">
              {showPayAll && (
                <div className="btn-style mr-auto">
                  <Button label="Pay all" btnType="btn-primary" btnStyle="btn-style" onClick={closeModal} />
                </div>
              )}
              {!showPayAll && <GenerateReportButton filename={`Transaction Data`} header={headers} data={csvData} />}
            </div>
          </div>
        </div>
        <DataTable columns={transactionColumn} data={transactionData} />

        <Pagination
          page={page}
          lastPage={transactions?.pagination?.lastPage}
          paginate={paginate}
          total={transactions?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Transaction;
