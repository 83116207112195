import React, { useEffect } from "react";
import AuthLayout from "./AuthContainer/AuthLayout";
import image from "assests/images/Investment type_ Cow@1x.png";
import ErrorMesssage from "components/ErrorMessage";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginFail, loginRequest } from "store/slice/AuthSlice/AuthSlice";
import { RootState } from "store/store";

type Props = {};
const Login: React.FC = (props: Props) => {
  type FormData = {
    email: string;
    password: string;
  };

  const dispatch = useDispatch();
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.auth);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  const onFormSubmit = (vals: FormData) => {
    dispatch(loginRequest(vals));
  };

  useEffect(() => {
    dispatch(loginFail(""));
  }, []);

  return (
    <AuthLayout
      headerText="Sign in to"
      bottomImage={image}
      content="Enter your email address & password to sign In to your Sebore Farms account."
      colorText="Sebore Farms"
      firstLink="Forgot your password?"
      firstLinkColor="Click here"
      secondLink="Having trouble signing in?"
      secondLinkColor="Contact Support"
      link="/forgot-password"
    >
      <div>
        {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
        <form className="pt-3" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="email"
            label="Email Address"
            placeholder="Enter Email"
            name="email"
            error={errors.email && errors.email.message}
            boxClasses="pb-4"
            customRef={register({
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            })}
          />
          <Textbox
            type="password"
            label="Password"
            placeholder="Enter Password"
            name="password"
            error={errors.password && "This field is required"}
            boxClasses="pb-4"
            customRef={register({
              required: true
            })}
          />
          <Button label="Sign In" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
