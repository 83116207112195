import * as React from "react";
import "./styles.scss";
import TabMenu from "components/TabMenu";
import { withRouter, Route, Switch, Redirect, useRouteMatch, useParams } from "react-router-dom";
import BioData from "./BioData";
import LocationData from "./LocationData/LocationData";
import TransactionData from "./TransactionData/TransactonData";
import Loans from "./Loans";
import OtherInfo from "./Otherinfo/index";

type FarmerPageProps = {};

const FarmerPage: React.FC<FarmerPageProps> = () => {
  const menus = [
    {
      name: "Bio Data",
      path: "bio-data"
    },
    {
      name: "Location Data",
      path: "location-data"
    },
    {
      name: "Transaction Data",
      path: "transaction-data"
    },
    {
      name: "Loans",
      path: "loans"
    },
    {
      name: "Other Information",
      path: "other-information"
    }
  ];
  const match = useRouteMatch();
  // console.log(match);

  return (
    <div id="farmer-page">
      <div className="container">
        <div className="row">
          <div className="menus col-lg-12 col-md-12">
            <TabMenu menus={menus} classes="" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Switch>
              <Route path={match.path} exact>
                <Redirect to={`${match.url}/bio-data`} />
              </Route>
              <Route path={`${match.path}/bio-data`}>
                <BioData />
              </Route>
              <Route path={`${match.path}/location-data`}>
                <LocationData />
              </Route>
              <Route path={`${match.path}/transaction-data`}>
                <TransactionData />
              </Route>
              <Route path={`${match.path}/loans`}>
                <Loans />
              </Route>
              <Route path={`${match.path}/other-information`}>
                <OtherInfo />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmerPage;
