import React, { useEffect } from "react";
import { useRouteMatch, useParams } from "react-router-dom";
import "./styles.scss";
import Textbox from "components/Textbox";
import FingerPrint from "assests/images/fingerPrint.png";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { editFarmer, getSingleFarmer } from "store/slice/FarmerSlice/FarmerSlice";
import { RootState } from "store/store";
import Button from "components/Button";
import useCheckMobileScreen from "hooks/checkMobile";
import { getLga } from "store/slice/LgaSlice/LgaSlice";
import { getStates } from "store/slice/StateSlice/StateSlice";
import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import useArrowDropdown from "hooks/useArrowDropdown";
import { toastr } from "react-redux-toastr";
import ArrowDropdown from "components/ArrowDropdown";
import SelectBox from "components/SelectBox";

type BioDataProps = {};

const BioData: React.FC<BioDataProps> = () => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>();
  const match = useRouteMatch();

  const [error2, setError2] = React.useState("");
  const [error, setError] = React.useState("");

  //-------------------------------------------------------------------
  const dispatch = useDispatch();
  const { singleFarmer, isLoading } = useSelector((state: RootState) => state.farmer);
  const { states } = useSelector((state: RootState) => state.stateReducer);
  const { lga } = useSelector((state: RootState) => state.lga);
  const { community } = useSelector((state: RootState) => state.community);
  const { permissions } = useSelector((state: RootState) => state.user);

  //-------------------------------------------------------------------
  let { userId }: any = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    dispatch(getSingleFarmer(userId));
    dispatch(getLga(1, 1000));
    dispatch(getStates(1, 1000));
    dispatch(getCommunity(1, 1000));
  }, []);

  useEffect(() => {
    if (singleFarmer !== null) {
      setValue("farmer_id", singleFarmer?.farmer_id);
      setValue("household", singleFarmer?.household);
      setValue("first_name", singleFarmer?.first_name);
      setValue("second_name", singleFarmer?.second_name);
      setValue("phone", singleFarmer?.phone);
      setValue("gender", singleFarmer?.gender);
      setValue("state", singleFarmer?.state);
      setValue("lga", singleFarmer?.lga);
      setValue("community", singleFarmer?.community);
      // soasetSelectedOption(singleFarmer?.state);
      // setSelectedOption(singleFarmer?.lga);
      // setSelectedOption3(singleFarmer?.community);
      // setKeyOption(singleFarmer?.lga_id);
      // setKeyOption3(singleFarmer?.community_id);
      // soasetKeyOption(singleFarmer?.state_id);
    }
  }, [singleFarmer]);

  const [keyOption3, setKeyOption3] = React.useState("");
  const [selectedOption3, setSelectedOption3] = React.useState("");
  const [selectComboxErrorOption, setSelectComboxErrorOption] = React.useState(false);

  //Custom hooks LGA
  const {
    open,
    selectedOption,
    handleDropdownChange,
    handleAction,
    handleToggle,
    setSelectedOption,
    keyOption,
    setKeyOption,
    dropdownRef
  } = useArrowDropdown();

  //Custom hooks State
  const {
    open: soaopen,
    selectedOption: soaselectedOption,
    handleDropdownChange: soahandleDropdownChange,
    handleAction: soahandleAction,
    handleToggle: soahandleToggle,
    setSelectedOption: soasetSelectedOption,
    keyOption: soakeyOption,
    setKeyOption: soasetKeyOption,
    dropdownRef: soadropdownRef
  } = useArrowDropdown();

  //Get State
  const stateList =
    states &&
    states?.state?.map((data: any) => {
      return {
        name: data.state_name,
        id: data._id
      };
    });

  //Get LGA
  const lgaList =
    lga &&
    lga?.lga?.map((data: any) => {
      if (soakeyOption === data.state) {
        return {
          name: data.lga_name,
          id: data._id
        };
      } else {
        return {
          name: "",
          id: ""
        };
      }
    });

  //Get Communities
  let array = [] as any;
  community &&
    community?.community.map((data: any) => {
      if (soakeyOption === data.state && keyOption === data.lga) {
        array.push({ label: data.community_name, value: data._id });
      }
    });

  const onSelectChange = vals => {
    if (vals === null) return;
    const { label, value } = vals;
    setSelectedOption3(label);
    setKeyOption3(value);
  };

  //To remove error message from field
  useEffect(() => {
    if (selectedOption) {
      setError("");
    }
    if (soaselectedOption) {
      setError2("");
    }
    if (selectedOption3) {
      setSelectComboxErrorOption(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soaselectedOption, selectedOption, selectedOption3]);

  const submit = vals => {
    if (!permissions?.includes("FARMER_EDIT")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    if (isLoading) return;
    // if (selectedOption === "" || selectedOption === undefined) return setError("Please select LGA");
    // if (soaselectedOption === "" || soaselectedOption === undefined) return setError2("Please select state");
    // if (selectedOption3 === "" || selectedOption3 === undefined) return setSelectComboxErrorOption(true);
    // const data = {
    //     ...vals,
    //     state: soaselectedOption,
    //     state_id: soakeyOption,
    //     lga: selectedOption,
    //     lga_id: keyOption,
    //     community: selectedOption3,
    //     community_id: keyOption3
    // };
    dispatch(
      editFarmer(vals, userId, () => {
        dispatch(getSingleFarmer(userId));
      })
    );
  };

  const mobile = useCheckMobileScreen();
  let selectRef = null as any;

  const clearValue = () => {
    selectRef.select.clearValue();
  };

  type FormData = {
    farmer_id: string;
    household: string;
    first_name: string;
    second_name: string;
    phone: string;
    gender: string;
    state: string;
    lga: string;
    community: string;
  };

  return (
    <div id="bio-page">
      <div className="col-md-8 pl-0 mt-4 mb-4">
        <h4 className="page-title">Profile Photo</h4>
        <div className="d-flex mt-3 profile">
          <div className="profile-image mr-sm-0 mr-lg-3">
            <img src={singleFarmer?.farmer_image} className="farmer_image" alt="" />
          </div>
          <div className="profile-text mt-2">
            <h4>Change Profile Picture</h4>
            <p>Choose a new avatar to be used across Sebore Farms</p>
          </div>
        </div>

        <h4 className="page-title mt-5 ">Basic Information</h4>
        <form onSubmit={handleSubmit(submit)}>
          <div className="row  profile-form">
            <div className="col-lg-6 col-md-12 col-sm-12  form-details">
              <Textbox
                label="Farmer’s ID"
                name="farmer_id"
                customRef={register({
                  required: true
                })}
                placeholder="Farmer’s ID"
                boxClasses="mt-3"
                disabled={true}
              />
              <Textbox
                label="Household ID"
                name="household"
                placeholder="Household ID"
                boxClasses="mt-top"
                customRef={register({
                  required: true
                })}
                disabled={true}
              />
              <Textbox
                label="First Name"
                name="first_name"
                customRef={register({
                  required: true
                })}
                placeholder="First Name"
                boxClasses="mt-top"
                disabled={isLoading}
                error={errors.first_name && "This field is required"}
              />
              <Textbox
                label="Last Name"
                name="second_name"
                customRef={register({
                  required: true
                })}
                placeholder="Last Name"
                boxClasses="mt-top"
                disabled={isLoading}
                error={errors.second_name && "This field is required"}
              />
              <Textbox
                label="Phone Number"
                name="phone"
                customRef={register({
                  required: true
                })}
                placeholder="Phone Number"
                boxClasses="mt-top"
                disabled={isLoading}
                error={errors.phone && "This field is required"}
              />
              <Textbox
                label="Number of Dependants"
                placeholder="Number of Dependants"
                boxClasses="mt-top"
                disabled={true}
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 pl-lg-5 pl-sm-0 pr-0 form-details ">
              <Textbox
                label="Gender"
                name="gender"
                placeholder="Gender"
                customRef={register({
                  required: true
                })}
                boxClasses="mt-3"
                disabled={true}
                error={errors.gender && "This field is required"}
              />
              <Textbox
                label="State of Origin"
                name="state"
                customRef={register({
                  required: true
                })}
                placeholder="State of Origin"
                boxClasses="mt-top"
                disabled={true}
              />
              <Textbox
                label="LGA of Origin"
                customRef={register({
                  required: true
                })}
                placeholder="LGA of Origin"
                boxClasses="mt-top"
                name="lga"
                disabled={true}
              />
              <Textbox
                label="Community"
                customRef={register({
                  required: true
                })}
                placeholder="Community"
                boxClasses="mt-top"
                name="community"
                disabled={true}
              />
              {/* <ArrowDropdown
                                text="Select State"
                                options={stateList}
                                handleDropdownChange={soahandleDropdownChange}
                                handleAction={soahandleAction}
                                selectedOption={soaselectedOption}
                                handleToggle={soahandleToggle}
                                open={soaopen}
                                boxClasses="mt-top-2"
                                cb={() => {
                                    setSelectedOption("");
                                    setKeyOption("");
                                    setSelectedOption3("");
                                    setKeyOption3("");
                                    clearValue();
                                }}
                                ref={soadropdownRef}
                                disabled={true}
                            />
                            <p className={`${error2 ? "d-block" : "d-none"} error mt-1`}>{error2}</p> */}

              {/* <ArrowDropdown
                                text="Select LGA"
                                options={lgaList}
                                handleDropdownChange={handleDropdownChange}
                                handleAction={handleAction}
                                selectedOption={selectedOption}
                                handleToggle={handleToggle}
                                open={open}
                                boxClasses="mt-top-2"
                                cb={() => {
                                    setSelectedOption3("");
                                    setKeyOption3("");
                                    clearValue();
                                }}
                                ref={dropdownRef}
                                disabled={true}
                            />
                            <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
                            {singleFarmer && singleFarmer !== null && (
                                <SelectBox
                                    options={array}
                                    placeholder="Select Community"
                                    boxClasses="mt-top-2"
                                    onChange={onSelectChange}
                                    legend={false}
                                    isDisabled={isLoading}
                                    error={selectComboxErrorOption ? "This field is required" : ""}
                                    customRef={(ref) => {
                                        selectRef = ref;
                                    }}
                                    defaultValue={
                                        singleFarmer !== null
                                            ? {
                                                  value: singleFarmer?.community_id,
                                                  label: singleFarmer?.community
                                              }
                                            : null
                                    }
                                />
                            )}
                            {!singleFarmer && singleFarmer === null && (
                                <SelectBox
                                    options={array}
                                    placeholder="Select Community"
                                    boxClasses="mt-top-2"
                                    onChange={onSelectChange}
                                    legend={false}
                                    isDisabled={isLoading}
                                    error={selectComboxErrorOption ? "This field is required" : ""}
                                    customRef={(ref) => {
                                        selectRef = ref;
                                    }}
                                    defaultValue={
                                        singleFarmer !== null
                                            ? {
                                                  value: singleFarmer?.community_id,
                                                  label: singleFarmer?.community
                                              }
                                            : null
                                    }
                                />
                            )} */}
              <Textbox
                label="Bank Verification Number"
                placeholder="Bank Verification Number"
                boxClasses="mt-top"
                disabled={true}
              />
              <Textbox label="Address" placeholder="Address" boxClasses="mt-top" disabled={true} />
              {/* <Textbox
                                label="Collection Point"
                                placeholder="Collection Point"
                                boxClasses="mt-top"
                                disabled={isLoading}
                            /> */}
            </div>
            <Button
              label="Save Changes"
              btnType="btn-primary"
              boxClasses={`mt-5 w-100 ${mobile ? "pl-0" : "pl-3"}`}
              btnStyle="100%"
              loading={isLoading}
            />
          </div>
        </form>

        {/**FARMER FINGER PRINT */}
        {/* <h4 className="page-title mt-5 ">Finger Print</h4>
                <div className="farm-finger mt-2">
                    <div className="right-finger">
                        <div className="farm-finger_print">
                            <p className="mb-2 text-center ml-4">Right thumb</p>
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                        </div>
                        <div className="farm-finger_print">
                            <p className="mb-2 text-center ml-4">Right Index</p>
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                        </div>
                        <div className="farm-finger_print">
                            <p className="mb-2 text-center ml-4">Right Middle</p>
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                        </div>
                        <div className="farm-finger_print">
                            <p className="mb-2 text-center ml-4">Right Ring</p>
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                        </div>
                        <div className="farm-finger_print">
                            <p className="mb-2 text-center ml-4">Right Little</p>
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                        </div>
                    </div>
                    <div className="left-finger mt-4">
                        <div className="farm-finger_print">
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                            <p className="mb-2 text-center ml-3 mt-2">Left thumb</p>
                        </div>
                        <div className="farm-finger_print">
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                            <p className="mb-2 text-center ml-3 mt-2">Left Index</p>
                        </div>
                        <div className="farm-finger_print">
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                            <p className="mb-2 text-center ml-3 mt-2">Left Middle</p>
                        </div>
                        <div className="farm-finger_print">
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                            <p className="mb-2 text-center ml-3 mt-2">Left Ring</p>
                        </div>
                        <div className="farm-finger_print">
                            <div className="print-card">
                                <img src={FingerPrint} alt="finger_print" />
                            </div>
                            <p className="mb-2 text-center ml-3 mt-2">Left Little</p>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default BioData;
