import React from "react";
import "./styles.scss";

type Props = {
    borderClass?: string;
    message: string;
    success?: boolean;
};

const ErrorMesssage = ({ borderClass = "pt-3", message, success }: Props) => {
    return (
        <div className={`${borderClass}`}>
            <div className={!success ? "error-msg" : "success-msg"}>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default ErrorMesssage;
