import Button from "components/Button";
import Textbox from "components/Textbox";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editAccount, getAccount } from "store/slice/AccountSlice/accountSlice";
import { RootState } from "store/store";
import "./styles.scss";

type Props = {};
const Accounts: React.FunctionComponent<Props> = () => {
  //Hook
  const dispatch = useDispatch();
  const { number, isLoading, id } = useSelector((state: RootState) => state.account);
  const { register, setValue, handleSubmit } = useForm<FormData>();

  //State
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    dispatch(getAccount());
    if (number !== "") setValue("eyowo_number", number);
  }, [number]);

  const onSubmit = vals => {
    if (isLoading || disabled) return;
    dispatch(
      editAccount(vals, id, () => {
        dispatch(getAccount());
        setDisabled(true);
      })
    );
  };

  return (
    <div id="account-page">
      <div className="col-xs-12 pt-4 pl-0 col-md-3">
        <h4 className="mb-4">Eyowo Business Number</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textbox
            label="Eyowo Number"
            placeholder="Eyowo Number"
            boxClasses="mb-4 input-width"
            name="eyowo_number"
            customRef={register({
              required: true
            })}
            disabled={disabled}
          />
          <div className="d-flex justify-content-between">
            <Button
              label="Edit"
              btnType="btn-secondary"
              boxClasses="button-container flex-fill"
              xtraClass="button-position"
              btnStyle="95%"
              onClick={() => {
                setDisabled(!disabled);
              }}
              type="button"
            />
            <Button
              label="Save"
              btnType="btn-primary"
              boxClasses="button-container flex-fill"
              xtraClass={`button-position float-right ${disabled && "disabled"}`}
              btnStyle="95%"
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Accounts;
