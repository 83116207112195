import { toastr } from 'react-redux-toastr';
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { DispatchState, DispatchAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: DispatchState = {
    isLoading: false,
    dispatchRecords: null,
    dispatchRecordSummary: null
};

const dispatchSlice = createSlice({
    name: "dispatch",
    initialState,
    reducers: {
        getDispatchRecordLoading: (state) => {
            state.isLoading = true;
        },
        getDispatchRecordSuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchRecords = action.payload;
        },
        getDispatchRecordFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        getDispatchRecordSummaryLoading: (state) => {
            state.isLoading = true;
        },
        getDispatchRecordSummarySuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchRecordSummary = action.payload;
        },
        getDispatchRecordSummaryFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        confirmDispatchLoading: (state) => {
            state.isLoading = true;
        },
        confirmDispatchSuccess: (state) => {
            state.isLoading = false;
        },
        confirmDispatchFail: (state) => {
            state.isLoading = false;
        },
        createDispatchLoading: (state) => {
            state.isLoading = true;
        },
        createDispatchSuccess: (state) => {
            state.isLoading = false;
        },
        createDispatchFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            // state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = dispatchSlice;

export const {
    getDispatchRecordLoading,
    getDispatchRecordSuccess,
    getDispatchRecordFail,
    getDispatchRecordSummaryLoading,
    getDispatchRecordSummarySuccess,
    getDispatchRecordSummaryFail,
    confirmDispatchFail,
    confirmDispatchLoading,
    confirmDispatchSuccess,
    createDispatchFail,
    createDispatchLoading,
    createDispatchSuccess
} = actions;

export const getDispatchRecord = (
    page?: number,
    perpage?: number,
    search?: string,
    dateFrom?: string,
    dateTo?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDispatchRecordLoading());
    try {
        const response = await axios.get(`${BASE_URL}/dispatchRecord/get`, {
            params: {
                page,
                perpage,
                search,
                dateFrom,
                dateTo
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getDispatchRecordSuccess(response.data));
        }
    } catch (error) {
        dispatch(getDispatchRecordFail(error?.response?.data?.message));
    }
};

export const getDispatchRecordSummary = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDispatchRecordSummaryLoading());
    try {
        const response = await axios.get(`${BASE_URL}/dispatchRecord/summary`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getDispatchRecordSummarySuccess(response.data.summary));
        }
    } catch (error) {
        dispatch(getDispatchRecordSummaryFail(error?.response?.data?.message));
    }
};

export const confirmDispatch = (data: any, id: string, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(confirmDispatchLoading());
    try {
        const response = await axios.post(`${BASE_URL}/dispatchRecord/confirm/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(confirmDispatchSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(confirmDispatchFail());
        toastr.error(error.response.data.error);
    }
};

export const createDispatch = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createDispatchLoading());
    try {
        const response = await axios.post(`${BASE_URL}/dispatchRecord/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createDispatchSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createDispatchFail(error.response.data.error));
        toastr.error(error.response.data.error);
        setTimeout(() => {
            dispatch(createDispatchFail(""));
        }, 3000);

    }
};
export default reducer;
