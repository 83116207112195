import { toastr } from 'react-redux-toastr';
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DispatchEmployeeState, DispatchAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: DispatchEmployeeState = {
    isLoading: false,
    dispatchEmployee: null,
    dispatchEmployeeId: null,
    successMessage: "",
    errorMessage: ""
};

const DispatchEmployeeSlice = createSlice({
    name: "DispatchEmployee",
    initialState,
    reducers: {
        getDispatchEmployeesLoading: (state) => {
            state.isLoading = true;
        },
        getDispatchEmployeesSuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchEmployee = action.payload;
        },
        getDispatchEmployeesFail: (state) => {
            state.isLoading = false;
        },
        deleteDispatchEmployeeLoading: (state) => {
            state.isLoading = true;
        },
        deleteDispatchEmployeeSuccess: (state) => {
            state.isLoading = false;
        },
        deleteDispatchEmployeeFail: (state) => {
            state.isLoading = false;
        },
        getDispatchEmployeeIdLoading: (state) => {
            state.isLoading = true;
        },
        getDispatchEmployeeIdsuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchEmployeeId = action.payload;
        },
        getDispatchEmployeeIdFail: (state) => {
            state.isLoading = false;
        },
        activateDispatchEmployeeIdLoading: (state) => {
            state.isLoading = true;
        },
        activateDispatchEmployeeIdsuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchEmployeeId = action.payload;
        },
        activateDispatchEmployeeIdFail: (state) => {
            state.isLoading = false;
        },
        createDispatchEmployeeLoading: (state) => {
            state.isLoading = true;
        },
        createDispatchEmployeeSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createDispatchEmployeeFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editDispatchEmployeeLoading: (state) => {
            state.isLoading = true;
        },
        editDispatchEmployeeSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editDispatchEmployeeFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = DispatchEmployeeSlice;

export const {
    getDispatchEmployeesLoading,
    getDispatchEmployeesSuccess,
    getDispatchEmployeesFail,
    deleteDispatchEmployeeFail,
    deleteDispatchEmployeeLoading,
    deleteDispatchEmployeeSuccess,
    getDispatchEmployeeIdFail,
    getDispatchEmployeeIdLoading,
    getDispatchEmployeeIdsuccess,
    createDispatchEmployeeLoading,
    createDispatchEmployeeSuccess,
    createDispatchEmployeeFail,
    editDispatchEmployeeFail,
    editDispatchEmployeeLoading,
    editDispatchEmployeeSuccess,
    activateDispatchEmployeeIdFail,
    activateDispatchEmployeeIdLoading,
    activateDispatchEmployeeIdsuccess
} = actions;

export const getDispatchEmployees = (page?: number, perpage?: number, search?: string): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(getDispatchEmployeesLoading());
    try {
        const response = await axios.get(`${BASE_URL}/dispatchEmployee/get`, {
            params: {
                page,
                perpage,
                search
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getDispatchEmployeesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getDispatchEmployeesFail());
    }
};

export const deleteDispatchEmployee = (id: string, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(deleteDispatchEmployeeLoading());
    try {
        const response = await axios.post(`${BASE_URL}/dispatchEmployee/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deleteDispatchEmployeeSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(deleteDispatchEmployeeFail());
        toastr.error(error.response.data.message);
    }
};

export const getDispatchEmployeeId = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDispatchEmployeeIdLoading());
    try {
        const response = await axios.post(`${BASE_URL}/dispatchEmployee/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getDispatchEmployeeIdsuccess(response.data.Employee));
        }
    } catch (error) {
        dispatch(getDispatchEmployeeIdFail());
    }
};

export const createDispatchEmployee = (data: any, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(createDispatchEmployeeLoading());
    try {
        const response = await axios.post(`${BASE_URL}/dispatchEmployee/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createDispatchEmployeeSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createDispatchEmployeeFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export const editDispatchEmployee = (data: any, id: string, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(editDispatchEmployeeLoading());
    try {
        const response = await axios.post(`${BASE_URL}/dispatchEmployee/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editDispatchEmployeeSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editDispatchEmployeeFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export const activateDispatchEmployeeId = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(activateDispatchEmployeeIdLoading());
    try {
        const response = await axios.get(`${BASE_URL}/dispatchEmployee/activate/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(activateDispatchEmployeeIdsuccess(response.data.Employee));
            toastr.success("", response.data.message);
        }
    } catch (error) {
        dispatch(activateDispatchEmployeeIdFail());
        toastr.error(error.response.data.message);
    }
};

export default reducer;
