import { format } from "date-fns";
import "./styles.scss";
import { formatCurrency, formatNumber } from "utils/utils";

export const supplyColumn = [
  {
    name: "Date",
    render: (item: { made: string | number | Date }) => (
      <span className="span-container">
        <p className="date">{format(new Date(item.made), "do LLL, yy")}</p>
        <p className="time pl-3">{format(new Date(item.made), "hh:mmaaa")}</p>
      </span>
    ),
    id: 1
  },
  {
    name: "Household ID",
    index: "farmer_household",
    id: 2
  },
  {
    name: "Community",
    index: "farmer_community",
    id: 3
  },
  {
    name: "Produce",
    index: "produce_name",
    id: 4
  },
  {
    name: "Quantity",
    id: 5,
    render: (item: { produce_quantity: string | number }) => formatNumber(item.produce_quantity)
  },
  {
    name: "Value",
    id: 6,
    index: "",
    render: (item: { produce_amount: string }) => formatCurrency(item.produce_amount)
  }
];
