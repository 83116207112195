import Star from "components/Star";
import Button from "../../../../components/Button/index";
import "./styles.scss";

export const transactionData = [
  {
    date: "2nd Sept. 2019",
    ref: "6720",
    id: 1,
    farmerId: "16",
    produce: "Fresh Milk",
    qty: "32Lt.",
    status: "paid"
  },
  {
    date: "2nd Sept. 2019",
    ref: "6720",
    id: 2,
    name: "Brent Fawaz",
    produce: "Fresh Milk",
    qty: "32Lt.",
    status: "unpaid",
    farmerId: "16"
  },
  {
    date: "2nd Sept. 2019",
    ref: "6720",
    farmerId: "16",
    id: 3,
    name: "Brent Fawaz",
    produce: "Fresh Milk",
    qty: "32Lt.",
    status: "paid"
  },
  {
    date: "2nd Sept. 2019",
    ref: "6720",
    id: 4,
    farmerId: "16",
    name: "Brent Fawaz",
    produce: "Fresh Milk",
    qty: "32Lt.",
    status: "paid"
  },
  {
    date: "2nd Sept. 2019",
    ref: "6720",
    id: 5,
    name: "Brent Fawaz",
    produce: "Fresh Milk",
    farmerId: "16",
    qty: "32Lt.",
    status: "unpaid"
  }
];

export const transactionDataColumn = [
  {
    name: "Date",
    index: "date",
    id: 1
  },
  {
    name: "Transaction Ref",
    index: "transaction_ref",
    id: 2
  },
  {
    name: "Farmer ID",
    index: "farmer_id",
    id: 3
  },
  {
    name: "Produce",
    index: "produce_name",
    id: 4
  },
  {
    name: "Quantity",
    index: "produce_quantity",
    id: 5
  },
  {
    name: "Status",
    id: 6,
    render: item => {
      return (
        <span>
          <Button
            label={item.status}
            btnType="btn-secondary"
            boxClasses="button-container"
            xtraClass={item.status === "Paid" ? "button-position" : "button-position unpaid"}
            btnStyle="6rem"
          />
        </span>
      );
    }
  },
  {
    name: "Quality",
    render: item => <Star disabled={true} stars={item.produce_rating} />,
    id: 7
  }
];
