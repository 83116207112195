import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { ReactComponent as Bell } from "assests/icons/bell.svg";
import { ReactComponent as Avatar } from "assests/icons/silhouette.svg";
import { ReactComponent as ArrowDown } from "assests/icons/dropdown.svg";
import { ReactComponent as ArrowBackDown } from "assests/icons/arrow-back-circle.svg";
import Hamburger from "assests/icons/hamburger.svg";
import DropdownMenu from "components/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/slice/UserSlice/UserSlice";
import { RootState } from "store/store";
import { useHistory, useLocation, Link } from "react-router-dom";
import { toCurrency } from "utils/utils";

type NavbarProps = {
    handleMenu: () => void;
    match?: any;
};

const Navbar = ({ handleMenu, match }: NavbarProps) => {
    //state
    const [pageName, setPageName] = useState("Dashboard");
    const [pathLength, setpathLength] = useState<any>();
    const [path, setPath] = useState("");
    const { pathname } = useLocation();
    const [tabPath, setTabPath] = useState("");

    //hook
    const dropdown = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        const dashboardName = pathname.split("/");
        setPath(dashboardName[1]);
        setpathLength(dashboardName.length);
        setPageName(dashboardName[1]);
    }, [pathname]);

    useEffect(() => {
        switch (path) {
            case "dashboard": {
                setTabPath(path);
                setPageName("Dashboard");
                break;
            }
            case "user-mgt":
                setTabPath(path);
                setPageName("User Management");
                return;
            case "settings":
                setTabPath(path);
                setPageName("Settings");
                return;
            case "profile":
                setTabPath(path);
                setPageName("Profile");
                return;
            default:
                break;
        }
    }, [pageName, path]);

    const { user, profile } = useSelector((state: RootState) => state.user);
    const { eyowoBalance } = useSelector((state: RootState) => state.transaction);
    const { permissions } = useSelector((state: RootState) => state.user);

    const Logout = () => {
        dispatch(logout());
    };

    const Profile = () => {
        history.push("/profile");
    };

    const settings = [
        {
            name: "Profile Settings",
            select: Profile
        },
        {
            name: "Logout",
            select: Logout,
            style: "logout_tag"
        }
    ];

    useEffect(() => {}, [profile]);

    return (
        <div id="navbar" className="d-flex mb-sm-0 mb-lg-2 align-items-sm-center align-items-lg-baseline">
            <img src={Hamburger} alt="menu" className="hamburger cursor-pointer" onClick={handleMenu} />
            <h4 className="navbar-text ml-sm-0 ml-5  flex-grow-1  text-center">
                {pathLength > 2 && path !== tabPath ? (
                    <Link to={`/${path}`}>
                        <ArrowBackDown /> Back to <p className="route_name">{pageName}</p>
                    </Link>
                ) : (
                    <p className="route_name">{pageName}</p>
                )}
            </h4>

            <div className="d-flex ">
                {permissions?.includes("EYOWO_BALANCE") && (
                    <h5 className="eyowo_balance mr-2">Eyowo Balance : {toCurrency(eyowoBalance)}</h5>
                )}
                <DropdownMenu options={settings} boxClasses={"nav-dropdown"}>
                    <div className="profile-tab">
                        <div className="d-flex user-profile flex-row align-items-center justify-content-between cursor-pointer">
                            <Bell className="icon mobile-none" />
                            <img src={profile?.image} alt="" className="avatar mobile-none" />
                            <p className="cursor-pointer">
                                {profile?.firstName} {"  "}
                                {profile?.lastName}
                            </p>
                            <ArrowDown className="icon-dropdown ml-sm-2 ml-lg-3 mb-sm-0  mb-lg-1" />
                        </div>
                    </div>
                </DropdownMenu>
            </div>
        </div>
    );
};

export default Navbar;
