import * as React from "react";
import "./styles.scss";
import cow from "assests/icons/statsCard/Group.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { supplyColumn } from "./data";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";

import curveArrow from "assests/icons/Curve arrow.png";
import Dropdown from "components/Dropdown";
import { useState, useEffect, useCallback } from "react";
import Pagination from "components/Pagination";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInventorySummary, getProduceChart } from "store/slice/InventorySlice/InventorySlice";
import { RootState } from "store/store";
import { getTransactions } from "store/slice/TransactionSlice/TransactionSlice";
import DateRangePicker from "components/DateRangePicker";
import { format } from "date-fns";
import Chart from "components/Chart";

type Props = {};

const SupplyChain: React.FunctionComponent<Props> = () => {
  // State
  const [page, setPage] = useState<number>(1);
  const [produceId, setProduceId] = useState<any>("");
  const [produceName, setProduceName] = useState<string>("");
  const [perpage, setPerpage] = useState<number>(10);
  const [search, setSearch] = useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  //Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const { inventory, summary, produceChart } = useSelector((state: RootState) => state.inventory);
  const { transactions } = useSelector((state: RootState) => state.transaction);

  // Get Chart Data for Produce
  const chartData =
    produceChart &&
    produceChart?.map(produce => {
      return {
        quantity: produce.totalQuantity,
        time: format(new Date(produce._id["date"]), "yyyy-MM-dd | hh:mmbb")
      };
    });

  useEffect(() => {
    let isMounted = true;
    if (location.state && isMounted) {
      const { produceID, produceName }: any = location.state;
      if (produceID) {
        setProduceId(produceID);
        setProduceName(produceName);
        dispatch(
          getTransactions(page, perpage, undefined, undefined, undefined, undefined, undefined, undefined, produceID)
        );
        dispatch(getInventorySummary(produceID));
        dispatch(getProduceChart(produceID));
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search === inputRef.current!.value) {
        const { produceID }: any = location.state;
        dispatch(getTransactions(1, perpage, search, undefined, undefined, undefined, undefined, undefined, produceID));
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [search, inputRef]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
  };

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(
        getTransactions(page, perpage, search, undefined, undefined, undefined, undefined, undefined, produceId)
      );
    },
    [search]
  );

  const paginate = useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(
        getTransactions(pageNo, perpage, search, undefined, undefined, undefined, undefined, undefined, produceId)
      );
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getTransactions(page, pageNo, search, undefined, undefined, undefined, undefined, undefined, produceId));
    },
    [search]
  );

  //Date Handler
  const handleDateChange = date => {
    setDateRange({ ...dateRange, startDate: date.startDate, endDate: date.endDate });
    dispatch(
      getTransactions(
        1,
        1000,
        undefined,
        format(new Date(date.startDate), "yyy-MM-dd"),
        format(new Date(date.endDate), "yyy-MM-dd"),
        undefined,
        undefined,
        undefined,
        produceId
      )
    );
  };

  return (
    <div id="supply-chain-page">
      <div className="container">
        <CardContainer title={`${produceName} Inventory Stats`} classes="mb-4">
          <StatsCard
            statsNumber={summary?.totalProduceQuantity || 0}
            text="Produce Qty"
            classes="stats-icon-orange"
            image={cow}
            litre="Lt."
          />
        </CardContainer>

        <div className="chart">
          <h4 className="page-title mb-3">{`Volume Graph - ${produceName}`}</h4>
          <Chart chartData={chartData} />
        </div>

        <h4 className="page-title mb-2">{`${produceName} Inventory Logs`}</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for Produce or Inventory log"
            boxClasses=" mb-4"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>

        <div className="row">
          <div className=" col-md-3 col-sm-12 col-xs-12">
            <DateRangePicker range={dateRange} handleDateChange={handleDateChange} />
          </div>
          <div className=" col-md-3 col-sm-12 col-xs-12 pb-3 pl-0">
            <div className="arrow-div"></div>
          </div>
          {/* <div className=" col-md col-sm-12 col-xs-12 pb-3 report d-flex justify-content-end">
                        <div className="select-boxs">
                            <img src={curveArrow} alt="alt" />
                            <p>Generate Report</p>
                        </div>
                    </div> */}
          <div className=" col-md col-sm-12 col-xs-12 pb-3 report d-flex justify-content-end">
            <div className="select-boxs">
              <img src={curveArrow} alt="alt" />
              <p>Generate Report</p>
            </div>
          </div>
        </div>
        <DataTable columns={supplyColumn} data={transactions?.transactions} />
        <Pagination
          page={page}
          lastPage={inventory?.pagination?.lastPage}
          paginate={paginate}
          total={inventory?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default SupplyChain;
