import React, { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import "./styles.scss";

type Props = {
    closeModal: () => void;
    singleTrans: object;
};
const TransactionModal = ({ closeModal, singleTrans }: Props) => {
    const { register, setValue } = useForm<FormData>();
    useEffect(() => {
        if (singleTrans) {
            setValue("status", singleTrans["status"] === "Owing" ? "Unpaid" : singleTrans["status"]);
            setValue("date", singleTrans["date"]);
            setValue("transaction_ref", singleTrans["transaction_ref"]);
            setValue("household_id", singleTrans["household_id"]);
            setValue("farmer_id", singleTrans["farmer_id"]);
            setValue("address", singleTrans["address"]);
            setValue("produce_amount", singleTrans["produce_amount"]);
            setValue("farmer_name", singleTrans["farmer_name"]);
            setValue("produce_name", singleTrans["produce_name"]);
            setValue("Collecion_point", singleTrans["Collecion_point"]);
            setValue("phone", singleTrans["phone"]);
            setValue("eyowoStatus", singleTrans["eyowoStatus"]);
            setValue("message", singleTrans["message"]);
        }
    }, [singleTrans]);

    return (
        <Modal modalText="Transaction" onClose={closeModal} modallength="modal-length">
            <div id="transaction-modal">
                <p className="">Here’s a detailed view of the transaction which took place on {singleTrans["date"]}</p>
                <form className="mt-2">
                    <Textbox
                        label="Date"
                        placeholder="Date"
                        boxClasses="mb-3 input-width"
                        name="date"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Transaction Ref"
                        placeholder="Transaction Ref"
                        boxClasses="mb-3 input-width"
                        name="transaction_ref"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Household ID"
                        placeholder="Household ID"
                        boxClasses="mb-3 input-width"
                        name="household_id"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Farmer ID"
                        placeholder="Farmer ID"
                        boxClasses="mb-3 input-width"
                        name="farmer_id"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Farmer Name"
                        placeholder="Farmer Name"
                        boxClasses="mb-3 input-width"
                        name="farmer_name"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Address"
                        placeholder="Address"
                        boxClasses="mb-3 input-width"
                        name="address"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Collection Point"
                        placeholder="Collection Point"
                        boxClasses="mb-3 input-width"
                        name="Collecion_point"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Produce"
                        placeholder="Produce"
                        boxClasses="mb-3 input-width"
                        name="produce_name"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Amount"
                        placeholder="Amount"
                        boxClasses="mb-3 input-width"
                        name="produce_amount"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Phone Number"
                        placeholder="Phone Number"
                        boxClasses="mb-3 input-width"
                        name="phone"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Status"
                        placeholder="Status"
                        boxClasses="mb-3 input-width"
                        name="status"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Eyowo Status"
                        placeholder="Eyowo Status"
                        boxClasses="mb-3 input-width"
                        name="eyowoStatus"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        label="Message"
                        placeholder="Message"
                        boxClasses="mb-3 input-width"
                        name="message"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                </form>
            </div>
        </Modal>
    );
};

export default TransactionModal;
