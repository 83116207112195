import React from "react";
import "./styles.scss";
import { format } from "date-fns";

export const dispatchEmployeeColumn = [
    {
        name: "Date",
        render: (item) => (
            <span className="span-container">
                <p className="date">{format(new Date(item.createdAt), "do LLL, yy")}</p>
                <p className="time pl-3">{format(new Date(item.createdAt), "hh:mmaaa")}</p>
            </span>
        ),
        id: 1
    },
    {
        name: "First Name",
        index: "firstName",
        id: 2
    },
    {
        name: "Last Name",
        index: "lastName",
        id: 3
    },
    {
        name: "Phone No",
        index: "employeePhoneNo",
        id: 4
    },
    {
        name: "Dispatch Company",
        index: "dispatchProvider",
        id: 5
    },
    {
        name: "Actions",
        id: 6,
        render: ({ edit, employeeName, deleteEmployee, status, dispatchProvider, id, employeePhoneNo }) => {
            return (
                <span className="span-container">
                    <p
                        className="edit"
                        onClick={() => edit(employeeName, id, status, dispatchProvider, employeePhoneNo)}
                    >
                        Edit
                    </p>
                    <p className="delete pl-3" onClick={() => deleteEmployee(id, employeeName)}>
                        Delete
                    </p>
                </span>
            );
        }
    }
];
