import * as React from "react";
import "./styles.scss";
import Employee from "assests/icons/statsCard/employee.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { dispatchEmployeeColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/employ_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import CreateProduce from "components/Modals/CreateProduce";
import {
    createDispatchEmployeeFail,
    deleteDispatchEmployee,
    getDispatchEmployees
} from "store/slice/DispatchEmployeeSlice/DispatchEmployeeSlice";
import CreateOrEditEmployee from "components/Modals/CreateOrEditDispatchEmployee";
import { getDispatchProviders } from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import { toastPopUp } from "utils/utils";

type Props = {};

const DispatchEmployee: React.FunctionComponent<Props> = () => {
    const [page, setPage] = React.useState<number>(1);
    const [perpage, setPerpage] = React.useState<number>(10);
    const [modal, setModal] = React.useState<boolean>(false);
    const [item, setItem] = React.useState<object>({});
    const [employeeId, setEmployeeId] = React.useState<string>("");
    const { search, inputRef, handleChange, searched } = useSearch(getDispatchEmployees, perpage);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getDispatchEmployees(page, perpage));
        dispatch(getDispatchProviders());
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // setpage number to 1 if searched is done
    React.useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    const { dispatchEmployee, isLoading } = useSelector((state: RootState) => state.dispatchEmployee);
    const { dispatchProvider } = useSelector((state: RootState) => state.dispatchProvider);

    const edit = (employeeName: any, id: any, status: string, dispatchProvider: string, employeePhoneNo: string) => {
        setModal(!modal);
        setItem({
            dispatchProvider,
            status,
            employeeName,
            employeePhoneNo
        });
        setEmployeeId(id);
    };

    const deleteEmployee = (e: any, name: string) => {
        toastPopUp(
            () => {
                dispatch(
                    deleteDispatchEmployee(e, () => {
                        dispatch(getDispatchEmployees(page, perpage));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete the Employee",
            name,
            "red"
        );
    };

    const dispatchEmployeeList =
        dispatchEmployee &&
        dispatchEmployee?.employees?.map((data: any) => {
            return {
                employeeName: data.employeeName,
                firstName: data.employeeName.split(" ")[0],
                lastName: data.employeeName.split(" ")[1],
                createdAt: data.createdAt,
                employeePhoneNo: data.employeePhoneNo,
                dispatchProvider: data.dispatchProvider,
                status: data.status,
                edit: edit,
                deleteEmployee: deleteEmployee,
                id: data._id
            };
        });

    const dispatchproviders =
        dispatchProvider &&
        dispatchProvider?.provider?.map((data: any) => {
            return {
                name: data.providerName
            };
        });

    //--------------------------------------------
    //Pagination
    //---------------------------------------------
    const handlePageChange = React.useCallback(
        (page: number, perpage: number) => {
            setPage(page);
            dispatch(getDispatchEmployees(page, perpage, search));
        },
        [search]
    );

    const paginate = React.useCallback(
        (pageNo: number, perpage: number) => {
            setPage(pageNo);
            dispatch(getDispatchEmployees(pageNo, perpage, search));
        },
        [search]
    );

    //------------------------------------------------------------
    //Show Number for PerPage Function
    //------------------------------------------------------------
    const handleNewPage = (pageNo: number, page = 1) => {
        setPerpage(pageNo);
        setPage(1);
        dispatch(getDispatchEmployees(page, pageNo, search));
    };

    //------------------------------------------------
    //modal toggler
    //------------------------------------------------
    const closeModal = () => {
        setModal(!modal);
        setItem({});
        dispatch(createDispatchEmployeeFail(""));
    };

    return (
        <div id="dispatch-employee-page">
            {modal && (
                <CreateOrEditEmployee
                    dispatchproviders={dispatchproviders}
                    closeModal={closeModal}
                    employeeId={employeeId}
                    item={item}
                />
            )}
            <div className="container">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={dispatchEmployee?.pagination?.total || 0}
                        text="Employee"
                        classes="stats-icon"
                        image={Employee}
                    />
                    <StatsCard image={Icon} classes="middle-img" centerText="Create Employee" onClick={closeModal} />
                </div>
                <h4 className="page-title mb-2">Employee Logs</h4>
                <div className="search-field">
                    <SearchIcon
                        placeholder="Search for Name or Dispatch Company"
                        boxClasses=" mb-3"
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    />
                    <Dropdown perPage={perpage} action={handleNewPage} />
                </div>
                <DataTable columns={dispatchEmployeeColumn} data={dispatchEmployeeList} loading={isLoading} />
                <Pagination
                    page={page}
                    lastPage={dispatchEmployee?.pagination?.lastPage}
                    paginate={paginate}
                    total={dispatchEmployee?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perpage}
                />
            </div>
        </div>
    );
};

export default DispatchEmployee;
