import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoleState, Action } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: RoleState = {
    isLoading: false,
    roles: null,
    roleId: null,
    permissions: [],
    successMessage: "",
    errorMessage: ""
};

const roleSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        getRolesLoading: (state) => {
            state.isLoading = true;
        },
        getRolesSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.roles = action.payload;
        },
        getRolesFail: (state) => {
            state.isLoading = false;
        },
        deleteRoleLoading: (state) => {
            state.isLoading = true;
        },
        deleteRoleSuccess: (state) => {
            state.isLoading = false;
        },
        deleteRoleFail: (state) => {
            state.isLoading = false;
        },
        getRoleIdLoading: (state) => {
            state.isLoading = true;
        },
        getRoleIdsuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.roleId = action.payload;
        },
        getRoleIdFail: (state) => {
            state.isLoading = false;
        },
        getPermLoading: (state) => {
            state.isLoading = false;
        },
        getPermSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.permissions = action.payload;
        },
        getPermFail: (state) => {
            state.isLoading = false;
        },
        createRoleLoading: (state) => {
            state.isLoading = true;
        },
        createRoleSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createRoleFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editRoleLoading: (state) => {
            state.isLoading = true;
        },
        editRoleSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editRoleFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = roleSlice;

export const {
    getRolesLoading,
    getRolesSuccess,
    getRolesFail,
    deleteRoleFail,
    deleteRoleLoading,
    deleteRoleSuccess,
    getRoleIdFail,
    getRoleIdLoading,
    getRoleIdsuccess,
    getPermFail,
    getPermLoading,
    getPermSuccess,
    createRoleLoading,
    createRoleSuccess,
    createRoleFail,
    editRoleFail,
    editRoleLoading,
    editRoleSuccess
} = actions;

export const getRoles = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getRolesLoading());
    try {
        const response = await axios.get(`${BASE_URL}/roles/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getRolesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getRolesFail());
    }
};

export const deleteRole = (id: string, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteRoleLoading());
    try {
        const response = await axios.post(`${BASE_URL}/roles/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deleteRoleSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(deleteRoleFail());
        toastr.error(error.response.data.message);
    }
};

export const getRoleId = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getRoleIdLoading());
    try {
        const response = await axios.get(`${BASE_URL}/roles/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getRoleIdsuccess(response.data));
        }
    } catch (error) {
        dispatch(getRoleIdFail());
    }
};

export const getPermissions = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPermLoading());
    try {
        const response = await axios.get(`${BASE_URL}/permission/get`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getPermSuccess(response.data.permissions));
        }
    } catch (error) {
        dispatch(getPermFail());
    }
};

export const createRole = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createRoleLoading());
    try {
        const response = await axios.post(`${BASE_URL}/roles/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createRoleSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createRoleFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export const editRole = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editRoleLoading());
    try {
        const response = await axios.post(`${BASE_URL}/roles/edit`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editRoleSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editRoleFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
