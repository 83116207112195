import Button from "components/Button";
import Textbox from "components/Textbox";
import useCheckMobileScreen from "hooks/checkMobile";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editFarmer, getSingleFarmer } from "store/slice/FarmerSlice/FarmerSlice";
import { RootState } from "store/store";
import "./styles.scss";

type Props = {};
const LocationData: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const { singleFarmer, isLoading } = useSelector((state: RootState) => state.farmer);
  const { register, setValue, handleSubmit } = useForm<FormData>();

  //-------------------------------------------------------------------
  let { userId }: any = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    dispatch(getSingleFarmer(userId));
  }, []);

  useEffect(() => {
    if (singleFarmer !== null) {
      setValue("geolocation", singleFarmer?.longitude);
      setValue("lga", singleFarmer?.lga);
      setValue("state", singleFarmer?.state);
    }
  }, [singleFarmer]);

  const submit = vals => {
    if (isLoading) return;
    dispatch(
      editFarmer(vals, userId, () => {
        dispatch(getSingleFarmer(userId));
      })
    );
  };

  return (
    <div id="location-data-page">
      <div className="col-xs-12 pt-4">
        <h4 className="mb-4">Location Data</h4>
        <form onSubmit={handleSubmit(submit)}>
          <Textbox
            label="State of Residence"
            placeholder="State of Residence"
            boxClasses="mb-3 input-width"
            name="state"
            customRef={register({
              required: true
            })}
            disabled={true}
          />
          <Textbox
            label="Geolocation"
            placeholder="Geolocation"
            boxClasses="mb-3 input-width"
            name="geolocation"
            customRef={register({
              required: true
            })}
            disabled={true}
          />
          <Textbox
            label="LGA of Residence"
            placeholder="LGA of Residence"
            boxClasses="mb-3 input-width"
            name="lga"
            customRef={register({
              required: true
            })}
            disabled={true}
          />
          {/* <Button
                            label="Save Changes"
                            btnType="btn-primary"
                            boxClasses="mt-5 w-100 input-width"
                            btnStyle="100%"
                            loading={isLoading}
                        /> */}
        </form>
      </div>
    </div>
  );
};

export default LocationData;
