import React from "react";
import "./styles.scss";
import { format } from "date-fns";

export const dispatchProviderData = [
    {
        produce: "Milk",
        scale: "kg",
        unit: "N700"
    },
    {
        produce: "Milk",
        scale: "kg",
        unit: "N700"
    }
];

export const dispatchProviderColumn = [
    {
        name: "LGA Name",
       index:"lga_name",
        id: 1
    },
    {
        name: "LGA Code",
        index: "lga_code",
        id: 2
    },
    {
        name: "Actions",
        id: 3,
        render: ({ edit, providerName, deleteLgaHandler, id }) => {
            return (
                <span className="span-container">
                    <p className="edit" onClick={() => edit(providerName, id)}>
                        Edit
                    </p>
                    <p className="delete pl-3" onClick={() => deleteLgaHandler()}>
                        Delete
                    </p>
                </span>
            );
        }
    }
];
