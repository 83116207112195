import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { UIState } from "./interface";

const initialState: UIState = {
  isLoading: false,
  payFarmerModal: false
};

const userSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    payFarmer: state => {
      state.payFarmerModal = !state.payFarmerModal;
    }
  }
});

const { actions, reducer } = userSlice;

export const { payFarmer } = actions;

export const showPayFarmerModal = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(payFarmer());
};

export default reducer;
