import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";
import {
    createDispatchProvider,
    editDispatchProvider,
    getDispatchProviders,
    getDispatchProviderId
} from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import NaijaStates from "naija-state-local-government";

type Props = {
    closeModal: () => void;
    value?: string;
    providerId?: string;
};
const CreateOrEditProvider = ({ closeModal, value, providerId = "" }: Props) => {
    //Redux Store
    const dispatch = useDispatch();
    const { errorMessage, isLoading, dispatchProviderId } = useSelector((state: RootState) => state.dispatchProvider);
    const { register, handleSubmit, errors, setValue, reset } = useForm<FormData>({
        mode: "onBlur"
    });

    const [error, setError] = React.useState("");

    //States Hook
    const {
        open: soaopen,
        selectedOption: soaselectedOption,
        handleDropdownChange: soahandleDropdownChange,
        handleAction: soahandleAction,
        handleToggle: soahandleToggle,
        setSelectedOption: soasetSelectedOption,
        keyOption: soakeyOption,
        dropdownRef: soadropdownRef,
        setKeyOption: soasetKeyOption
    } = useArrowDropdown();

    useEffect(() => {
        if (providerId !== "") {
            dispatch(getDispatchProviderId(providerId));
        }
    }, [providerId]);

    useEffect(() => {
        if (dispatchProviderId !== null && providerId !== "") {
            setValue("providerName", dispatchProviderId?.providerName);
            setValue("contactFirstName", dispatchProviderId?.contactFirstName);
            setValue("contactLastName", dispatchProviderId?.contactLastName);
            setValue("companyEmail", dispatchProviderId?.companyEmail);
            setValue("companyPhoneNo", dispatchProviderId?.companyPhoneNo);
            setValue("address", dispatchProviderId?.address);
            soasetSelectedOption(dispatchProviderId?.state);
        }
    }, [dispatchProviderId]);

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    type FormData = {
        providerName: string;
        contactFirstName: string;
        contactLastName: string;
        companyEmail: string;
        companyPhoneNo: string;
        address: string;
    };

    //Create states dropdown
    let ALL_STATES: Array<{ name: string; id: string }> = [];
    NaijaStates.states().forEach((state) => {
        const data = {
            name: state,
            id: state
        };
        ALL_STATES.push(data);
    });

    const validatePhoneNo = (value: any) => {
        let values = value.replace(/[^0-9]*/g, "");
        if (value.length > 11 || value !== values) return "Should be a phone number";
    };

    const onFormSubmit = (vals: FormData) => {
        if (isLoading) return;

        if (soaselectedOption === "") {
            setError("Please select a State");
            return;
        }

        const data = {
            ...vals,
            state: soaselectedOption
        };

        if (value !== "") {
            dispatch(
                editDispatchProvider(data, providerId, () => {
                    closeModal();

                    dispatch(getDispatchProviders());
                })
            );
        } else {
            dispatch(
                createDispatchProvider(data, () => {
                    closeModal();
                    dispatch(getDispatchProviders());
                })
            );
        }
    };

    //To remove error message from field
    useEffect(() => {
        if (soaselectedOption) {
            setError("");
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [soaselectedOption]);

    return (
        <Modal
            modalText={value === "" ? "Create Provider" : "Edit Provider"}
            onClose={closeModal}
            modallength="modal-length"
            boxClasses=""
        >
            <div className="role-modal ">
                <form className="" onSubmit={handleSubmit(onFormSubmit)}>
                    <Textbox
                        type="text"
                        label="Prodiver Name"
                        placeholder="Enter dispatch provider name"
                        name="providerName"
                        error={errors.providerName && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    <Textbox
                        type="text"
                        boxClasses="mt-3"
                        name="contactFirstName"
                        label="Contact First Name"
                        placeholder="Contact First Name"
                        error={errors.contactFirstName && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    <Textbox
                        type="text"
                        label="Contact Last Name"
                        name="contactLastName"
                        placeholder="Contact Last Name"
                        boxClasses="mt-3"
                        error={errors.contactLastName && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    <ArrowDropdown
                        text="Select State"
                        options={ALL_STATES}
                        handleDropdownChange={soahandleDropdownChange}
                        handleAction={soahandleAction}
                        selectedOption={soaselectedOption}
                        handleToggle={soahandleToggle}
                        open={soaopen}
                        boxClasses="mt-4"
                        ref={soadropdownRef}
                    />
                    <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
                    <Textbox
                        type="text"
                        label="Company Address"
                        name="address"
                        placeholder="Company Address"
                        boxClasses="mt-3"
                        error={errors.address && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    <Textbox
                        type="email"
                        label="Company Email"
                        placeholder="Company Email"
                        boxClasses="mt-3"
                        name="companyEmail"
                        error={errors.companyEmail && errors.companyEmail.message}
                        customRef={register({
                            required: "This field is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                    />
                    <Textbox
                        type="text"
                        name="companyPhoneNo"
                        label="Company Phone Number"
                        error={errors.companyPhoneNo && errors.companyPhoneNo.message}
                        customRef={register({
                            required: "This field is required",
                            validate: validatePhoneNo
                        })}
                        placeholder="Company Phone Number"
                        boxClasses="mt-3"
                    />

                    {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
                    <Button
                        label={value === "" ? "Create Provider" : "Edit Provider"}
                        btnType="btn-primary"
                        boxClasses="mt-4 mb-5"
                        btnStyle="100%"
                        loading={isLoading}
                    />
                </form>
                {/* <p className="role-p" onClick={closeModal}>
                    Cancel
                </p> */}
            </div>
        </Modal>
    );
};

export default CreateOrEditProvider;
