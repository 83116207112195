import React from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { createLoan, getLoan } from "store/slice/LoanSlice/loanSlice";
import ErrorMesssage from "components/ErrorMessage";
import { useParams } from "react-router-dom";

type Props = {
  closeModal: () => void;
};
const CreateLoan = ({ closeModal }: Props) => {
  const [error] = React.useState("");
  let { userId }: any = useParams<Record<string, string | undefined>>();

  //Redux Store
  const dispatch = useDispatch();
  const { isLoading, errorMessage } = useSelector((state: RootState) => state.loans);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  type FormData = {
    description: string;
    amountDeducted: number;
    amount: number;
    farmerId: string;
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    let data;
    data = {
      farmerId: userId,
      amount: vals.amount,
      amountDeducted: vals.amountDeducted,
      description: vals.description
    };
    dispatch(
      createLoan(data, () => {
        dispatch(getLoan(1, 10, undefined, userId));
        closeModal();
      })
    );
  };

  return (
    <Modal modalText="Create Loan" onClose={closeModal}>
      <div id="createLoan-page">
        <p className="">Complete this form with accurate data to create a new loan for farmer.</p>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="description"
            label="Loan Name"
            placeholder="Loan Name"
            error={errors.description && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="number"
            label="Amount"
            name="amount"
            placeholder="Amount"
            boxClasses="mt-3"
            error={errors.amount && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          <Textbox
            type="number"
            label="Amount to be deducted"
            placeholder="Amount to be Deducted"
            boxClasses="mt-3"
            name="amountDeducted"
            error={errors.amountDeducted && errors.amountDeducted.message}
            customRef={register({
              required: "This field is required"
            })}
          />
          {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
          <Button
            label="Create Loan"
            btnType="btn-primary"
            boxClasses="mt-4 mb-3"
            btnStyle="100%"
            loading={isLoading}
          />
          <Button
            label="Cancel"
            btnType="btn-primary"
            boxClasses="mb-5"
            xtraClass="xtraClass"
            btnStyle="100%"
            onClick={closeModal}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CreateLoan;
