import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { selectStyles } from "./style";
import "./styles.scss";
import arrowdown from "assests/icons/Vector (2).svg";

type Props = {
    label?: string;
    placeholder?: string;
    boxClasses?: string;
    type?: string;
    error?: string;
    iconUrl?: string;
    onIconClick?: string;
    disabled?: boolean;
    min?: string;
    options?: any | undefined[];
    defaultValue?: any;
    customRef?: string | ((instance: HTMLInputElement | null) => void);
    setSelectComboxOption?: any;
    setHighlightedOption?: any;
    onChange?: (e) => void;
    legend: boolean;
    isDisabled?: boolean;
    value?: object | string | undefined;
    name?: string;
    inputValue?: object;
    loadedOptions?: any | undefined[];
    onInputChange?: (e?) => void;
};

const AsynSelectBox = ({
    label,
    placeholder,
    boxClasses,
    error,
    disabled = false,
    options,
    defaultValue,
    onChange,
    legend,
    isDisabled,
    value,
    customRef,
    name,
    inputValue,
    loadedOptions,
    onInputChange
}: Props) => {
    const newStyle = {
        ...selectStyles
    };

    const CaretDownIcon = () => {
        return <img src={arrowdown} alt="alt" />;
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    };

    return (
        <div className={`${boxClasses} select ${!legend && "no-focus"}`}>
            <fieldset className={`${error && "error"} ${disabled && "disabled-input"}`}>
                {legend && (
                    <legend id="label-legend" className={` pl-2 pr-2 ${error && "label-error"}`}>
                        {label}
                    </legend>
                )}
                <AsyncSelect
                    cacheOptions
                    defaultOptions={options}
                    placeholder={placeholder}
                    loadOptions={loadedOptions}
                    onChange={onChange}
                    isSearchable={true}
                    styles={newStyle}
                    isDisabled={isDisabled}
                    defaultValue={defaultValue}
                    value={value}
                    ref={customRef}
                    name={name}
                    inputValue={inputValue}
                    components={{ DropdownIndicator }}
                    onInputChange={onInputChange}
                />
            </fieldset>
            <p className={`${error ? "d-block" : "d-none"} input-error mt-1`}>{error}</p>
        </div>
    );
};

export default AsynSelectBox;
