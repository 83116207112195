import * as React from "react";
import People from "assests/icons/statsCard/people 2 2.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { permissionCol } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "store/slice/RoleSlice/RoleSlice";
import { RootState } from "store/store";

type Props = {};

const Permissions: React.FunctionComponent<Props> = () => {
  //Redux Hook
  const dispatch = useDispatch();
  const { permissions, isLoading } = useSelector((state: RootState) => state.roles);

  //Get Permissions
  React.useEffect(() => {
    dispatch(getPermissions());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="user-management-page">
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={permissions?.length || 0} text="Permissions" classes="stats-icon" image={People} />
        </div>
        <h4 className="page-title mb-2">Permissions Logs</h4>

        <DataTable columns={permissionCol} data={permissions} loading={isLoading} />
      </div>
    </div>
  );
};

export default Permissions;
