import useCheckMobileScreen from "hooks/checkMobile";
import { Line } from "react-chartjs-2";
import { kFormatter } from "utils/utils";

type Props = {
  chartData: object[];
};

const Chart = ({ chartData }: Props) => {
  const isMobile = useCheckMobileScreen();

  // Sort chart data in ascending by time
  function sort() {
    chartData?.sort((a, b) => {
      let fa = a["time"],
        fb = b["time"];

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return chartData;
  }
  sort();
  let time = chartData?.map(item => item["time"]);

  const produceQauntity = chartData?.map(item => item["quantity"]);

  const data = {
    labels: time ? time : [""],
    datasets: [
      {
        // label: "First dataset",
        display: false,
        data: produceQauntity ? produceQauntity : [0],
        fill: true,
        backgroundColor: "rgba(6, 155, 112, 0.1)",
        borderColor: "rgba(0,176,80,1)",
        pointRadius: time?.length === 1 && produceQauntity?.length === 1 ? "5" : "0",
        pointBackgroundColor: "#00b050"
      }
    ]
  };

  return (
    <Line
      data={data}
      height={isMobile ? 200 : 70}
      options={{
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (quantity) {
                  return kFormatter(quantity, "");
                },
                // beginAtZero: true,
                fontSize: isMobile ? 11 : 12,
                fontColor: "#637381"
              },
              scaleLabel: {
                display: true,
                labelString: "Produce",
                fontSize: isMobile ? 15 : 18,
                fontColor: "#9B9B9B"
              },
              display: true
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Time",
                fontSize: isMobile ? 15 : 18,
                fontColor: "#9B9B9B"
              },
              label: {
                display: false
              },
              display: true,
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false
              }
            }
          ]
        },
        legend: {
          display: false
        }
      }}
    />
  );
};
export default Chart;
