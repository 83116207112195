import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import cow from "assests/icons/statsCard/Group.png";
import Leaf from "assests/icons/statsCard/leaf-sharp.png";
import Cash from "assests/icons/statsCard/cash.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { householdColumns } from "./data";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useParams, useHistory } from "react-router-dom";
import { getSingleHousehold, getSingleHouseholdSummary } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import { getSummary } from "store/slice/TransactionSlice/TransactionSlice";
import PayFarmerModal from "components/Modals/PayFarmerModal";
import { checkPermission } from "utils/utils";

type Props = {};

const Household2: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerpage] = useState<number>(10);
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [modal, setModal] = React.useState<boolean>(false);
  const [farmerDetails, setFarmerDetails] = useState<object>({});
  const componentJustMounted = useRef<boolean>(true);

  //Helpers
  const dispatch = useDispatch();
  const { singleHouseHolds, singleHouseholdSummary } = useSelector((state: RootState) => state.household);
  const { summary } = useSelector((state: RootState) => state.transaction);
  const { permissions } = useSelector((state: RootState) => state.user);

  //-------------------------------------------------------------------
  let { id }: any = useParams<Record<string, string | undefined>>();
  let history = useHistory();

  useEffect(() => {
    let timer: any;
    if (!componentJustMounted.current) {
      timer = setTimeout(() => {
        if (search === inputRef.current!.value) {
          dispatch(getSingleHousehold(id, 1, perPage, search));
        }
      }, 500);
    }

    componentJustMounted.current = false;
    return () => {
      clearTimeout(timer);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, inputRef]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (id) {
      dispatch(getSingleHousehold(id));
      dispatch(getSingleHouseholdSummary(id));
      dispatch(getSummary("", "", "", "", "", "", id));
    } else {
      history.goBack();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback((page: number, perpage: number) => {
    setPage(page);
    dispatch(getSingleHousehold(id, page, perpage, search));
  }, []);

  const paginate = useCallback((pageNo: number, perpage: number) => {
    setPage(pageNo);
    dispatch(getSingleHousehold(id, pageNo, perpage, search));
  }, []);

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = (pageNo: number) => {
    setPerpage(pageNo);
    dispatch(getSingleHousehold(id, page, pageNo, search));
  };

  //Toggle Modal
  const closeModal = () => {
    setModal(!modal);
  };

  const pay = (farmerName: string, phoneNo: string, id: string, farmer_id: string) => {
    setModal(!modal);
    setFarmerDetails({
      farmerName,
      phoneNo,
      id,
      farmer_id,
      disable: true
    });
  };
  const farmersList =
    singleHouseHolds &&
    singleHouseHolds?.farmer?.map((data: any) => {
      return {
        farmer_image: data.farmer_image,
        farmer_id: data.farmer_id,
        first_name: data.first_name,
        second_name: data.second_name,
        phone: data.phone,
        community: data.community,
        lga: data.lga,
        _id: data._id,
        pay: () => pay(`${data.first_name} ${data.second_name}`, data.phone, data._id, data.farmer_id)
      };
    });
  return (
    <div id="singlehousehold-page">
      {modal && checkPermission(permissions, "FARMER_PAY") && (
        <PayFarmerModal closeModal={closeModal} farmerDetails={farmerDetails} />
      )}
      <div className="container">
        <CardContainer title={`${singleHouseHolds?.household?.house_name} Farm`} classes="mb-4">
          <StatsCard
            statsNumber={summary?.totalNumberOfUniqueProduce || 0}
            text="Produce"
            classes="stats-icon-leaf"
            image={Leaf}
          />

          <StatsCard statsNumber={summary?.totalAmount || 0.0} text="Amount" classes="stats-icon-cash" image={Cash} />

          <StatsCard
            statsNumber={singleHouseholdSummary?.totalCows || 0}
            text="No. of Cows"
            classes="stats-icon-orange"
            image={cow}
          />

          <StatsCard
            statsNumber={singleHouseholdSummary?.totalBulls || 0}
            text="No. of Bulls"
            classes="stats-icon-orange"
            image={cow}
          />
        </CardContainer>
        <div className="box-container mb-5">
          <h3 className="pb-2">{singleHouseholdSummary?.totalCows || "N/A"} Milking cows</h3>
          <div className="farmer-stats mt-1">
            <p>PEAK PERIOD - {singleHouseHolds?.household?.peak_milk_period || "N/A"}</p>
            <p className="">LEAN PERIOD - {singleHouseHolds?.household?.lean_milk_period || "N/A"}</p>{" "}
            <p className="">AV. PRODUCE PER DAY- {singleHouseHolds?.household?.average_milk_per_day || "N/A"}lt.</p>{" "}
            <p className="">BUSINESS TYPE - {singleHouseHolds?.household?.business_type || "N/A"}</p>
          </div>
        </div>

        <h4 className="page-title mb-2">{singleHouseHolds?.household?.house_name} Farmers</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for Household name or ID"
            boxClasses=" mb-4"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />

          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>

        <DataTable columns={householdColumns} data={farmersList} />
        <Pagination
          page={page}
          lastPage={singleHouseHolds?.pagination?.lastPage}
          paginate={paginate}
          total={singleHouseHolds?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perPage}
        />
      </div>
    </div>
  );
};

export default Household2;
