import Button from "../../components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";
import { formatCurrency, formatNumber } from "utils/utils";

export const inventoryColumn = [
  {
    name: "Product Name",
    index: "produce_name",
    id: 1
  },
  {
    name: "Quantity",
    id: 2,
    render: (item: { quantity: string | number }) => formatNumber(item.quantity)
  },
  {
    name: "Value",
    index: "",
    id: 3,
    render: (item: { value: string }) => formatCurrency(item.value)
  },
  {
    name: "Category",
    index: "category",
    id: 4
  },
  {
    name: "Action",
    id: 5,
    render: item => (
      <Link
        to={{
          pathname: item.category === "Supply Chain" ? "/inventory/supply-chain" : "/inventory/manufacturing",
          state: { produceID: item.produce_id, produceName: item.produce_name }
        }}
      >
        <Button label="view" btnType="btn-secondary" boxClasses="button-container" xtraClass="button-position" />
      </Link>
    )
  }
];
