import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/Box#3.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { dispatchProviderColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/logistic.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import {
    createDispatchProviderFail,
    deleteDispatchProvider,
    getDispatchProviders
} from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import CreateOrEditProvider from "components/Modals/CreateOrEditProvider";
import { toastPopUp } from "utils/utils";

type Props = {};

const DispatchProvider: React.FunctionComponent<Props> = () => {
    const [page, setPage] = React.useState<number>(1);
    const [perpage, setPerpage] = React.useState<number>(10);
    const [modal, setModal] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>("");
    const [providerId, setProviderId] = React.useState<string>("");
    const { search, inputRef, handleChange, searched } = useSearch(getDispatchProviders, perpage);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getDispatchProviders(page, perpage));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // setpage number to 1 if searched is done
    React.useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    const { dispatchProvider, isLoading } = useSelector((state: RootState) => state.dispatchProvider);

    const edit = (e: any, id: any) => {
        setModal(!modal);
        setValue(e);
        setProviderId(id);
    };

    const deleteProvider = (e: any, name: string) => {
        toastPopUp(
            () => {
                dispatch(
                    deleteDispatchProvider(e, () => {
                        dispatch(getDispatchProviders(page, perpage));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete the Provider",
            name,
            "red"
        );
    };

    const dispatchProviderList =
        dispatchProvider &&
        dispatchProvider?.provider?.map((data: any) => {
            return {
                providerName: data.providerName,
                createdAt: data.createdAt,
                contactName: data.contactFirstName ? `${data.contactFirstName} ${data.contactLastName}` : "",
                companyPhoneNo: data.companyPhoneNo,
                companyEmail: data.companyEmail,
                state: data.state,
                address: data.address,
                edit: edit,
                deleteProvider: deleteProvider,
                id: data._id
            };
        });

    //--------------------------------------------
    //Pagination
    //---------------------------------------------
    const handlePageChange = React.useCallback(
        (page: number, perpage: number) => {
            setPage(page);
            dispatch(getDispatchProviders(page, perpage, search));
        },
        [search]
    );

    const paginate = React.useCallback(
        (pageNo: number, perpage: number) => {
            setPage(pageNo);
            dispatch(getDispatchProviders(pageNo, perpage, search));
        },
        [search]
    );

    //------------------------------------------------------------
    //Show Number for PerPage Function
    //------------------------------------------------------------
    const handleNewPage = (pageNo: number, page = 1) => {
        setPerpage(pageNo);
        setPage(1);
        dispatch(getDispatchProviders(page, pageNo, search));
    };

    //------------------------------------------------
    //modal toggler
    //------------------------------------------------
    const closeModal = () => {
        setModal(!modal);
        setValue("");
        setProviderId("");
        dispatch(createDispatchProviderFail(""));
    };

    return (
        <div id="dispatch-provider-page">
            {modal && <CreateOrEditProvider closeModal={closeModal} providerId={providerId} value={value} />}
            <div className="container">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={dispatchProvider?.pagination?.total || 0}
                        text="Provider"
                        classes="stats-icon"
                        image={People}
                    />
                    <StatsCard image={Icon} classes="middle-img" centerText="Create Provider" onClick={closeModal} />
                </div>
                <h4 className="page-title mb-2">Provider Logs</h4>
                <div className="search-field">
                    <SearchIcon
                        placeholder="Search for Provider Name"
                        boxClasses=" mb-3"
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    />
                    <Dropdown perPage={perpage} action={handleNewPage} />
                </div>
                <DataTable columns={dispatchProviderColumn} data={dispatchProviderList} loading={isLoading} />
                <Pagination
                    page={page}
                    lastPage={dispatchProvider?.pagination?.lastPage}
                    paginate={paginate}
                    total={dispatchProvider?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perpage}
                />
            </div>
        </div>
    );
};

export default DispatchProvider;
