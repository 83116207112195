import React, { useEffect, useState, useRef } from "react";
import arrowdown from "assests/icons/Vector (2).svg";
import "./styles.scss";

type Props = {
  show?: boolean;
  perPage?: number;
  action: (e) => void;
};
const Dropdown = ({ perPage = 10, action }: Props) => {
  //State
  const [page, setPage] = useState<number>(perPage);
  const [open, setOpen] = useState<boolean>(false);
  const dropdown = useRef<HTMLDivElement | null>(null);

  //closes the dropdown
  useEffect(() => {
    const hide = (e: any): void => {
      if (!dropdown!.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    window.addEventListener("mousedown", hide);
    return () => {
      window.removeEventListener("mousedown", hide);
    };
  }, []);

  const pageOption = [
    { value: 10, id: 1 },
    { value: 20, id: 2 },
    { value: 30, id: 3 }
  ];

  useEffect(() => {}, [page]);

  const renderedOptions = pageOption.map(option => {
    return (
      <div
        key={option.id}
        className="menu-select"
        style={option.value === page ? { backgroundColor: "rgb(0 176 79)", color: "white" } : { backgroundColor: "" }}
        onClick={() => {
          setPage(option.value);
          setOpen(false);
          action(option.value);
        }}
      >
        {option.value}
      </div>
    );
  });
  const icon = <img src={arrowdown} alt="alt" className="pl-1" />;

  return (
    <div className="d-flex show-div pr-1" ref={dropdown}>
      <p>Show:</p>{" "}
      <span
        className="page-select ml-1"
        style={open ? { backgroundColor: "rgba(80, 85, 102, 0.1)" } : { backgroundColor: "" }}
      >
        {!open ? (
          <span onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
            {page}
            {icon}
          </span>
        ) : (
          <span>{renderedOptions}</span>
        )}
      </span>
      {/* {icon} */}
    </div>
  );
};
export default Dropdown;
