import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { createRole, editRole, getPermissions, getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";

type Props = {
    closeModal: () => void;
    value?: string;
    roleId?: string;
    checkedPerm?: any;
};
const CreateRole = ({ closeModal, value, roleId, checkedPerm }: Props) => {
    //Redux Store
    const dispatch = useDispatch();
    const { permissions, errorMessage, isLoading } = useSelector((state: RootState) => state.roles);
    const { register, handleSubmit, errors, setValue } = useForm<FormData>({
        mode: "onBlur"
    });
    const [error, setError] = React.useState("");

    const [permission, setPermission] = useState<any>([]);
    const { open, selectedOption, handleDropdownChange, handleAction, handleToggle, dropdownRef } = useArrowDropdown(
        permission,
        setPermission
    );

    useEffect(() => {
        if (value && value !== "") setValue("role_name", value);
        const permission =
            permissions &&
            permissions.map((data: any) => {
                const perm = value && value !== "" && checkedPerm.includes(data._id);
                return {
                    name: data.permission_name,
                    id: data._id,
                    label: data.permission_description,
                    isChecked: value && value !== "" ? perm : false
                };
            });
        setPermission([...permission]);
    }, [permissions]);


    //Clear Error Message 
    useEffect(() => {
        const perm = permission.filter((item) => {
            return item.isChecked === true;
        });
        if (perm.length >= 1) setError("");
    }, [permission]);

    type FormData = {
        role_name: string;
        permission: any;
    };

    const onFormSubmit = (vals: FormData) => {
        if (isLoading) return;
        let checkedPerm = [] as any;
        permission.map((perm) => {
            if (perm.isChecked) {
                checkedPerm.push(perm.id);
            }
            return checkedPerm;
        });
        
        //Validation
        if (checkedPerm.length < 1) {
            setError("Please select atleast one permission");
            return;
        }
        const data = {
            role_name: vals.role_name,
            permission: checkedPerm
        };
        const editData = {
            role_name: vals.role_name,
            permission: checkedPerm,
            role: roleId
        };
        if (value !== "") {
            dispatch(
                editRole(editData, () => {
                    closeModal();
                    dispatch(getRoles());
                })
            );
        } else {
            dispatch(
                createRole(data, () => {
                    closeModal();
                    dispatch(getRoles());
                })
            );
        }
    };
    return (
        <Modal modalText={value === "" ? "Create Role" : "Edit Role"} onClose={closeModal} boxClasses="role-modal-top">
            <div className="role-modal">
                <p className="">Complete this form with accurate data to create a new role on Sebore farms.</p>
                <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
                    <Textbox
                        type="text"
                        label="Role Name"
                        placeholder="Enter Role Name"
                        name="role_name"
                        error={errors.role_name && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    <ArrowDropdown
                        text="Select Permissions"
                        options={permission}
                        handleDropdownChange={handleDropdownChange}
                        handleAction={handleAction}
                        selectedOption={selectedOption}
                        handleToggle={handleToggle}
                        open={open}
                        boxClasses="mt-4"
                        ref={dropdownRef}
                    />
                    <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
                    {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
                    <Button
                        label={value === "" ? "Create Role" : "Edit Role"}
                        btnType="btn-primary"
                        boxClasses="mt-4 mb-5"
                        btnStyle="100%"
                        loading={isLoading}
                    />
                </form>
                <p className="role-p" onClick={closeModal}>
                    Cancel
                </p>
            </div>
        </Modal>
    );
};

export default CreateRole;
