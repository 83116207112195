import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DispatchProviderState, DispatchAction, CreateDispatchProviderPayload } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: DispatchProviderState = {
    isLoading: false,
    dispatchProvider: null,
    dispatchProviderId: null,
    successMessage: "",
    errorMessage: ""
};

const DispatchProviderSlice = createSlice({
    name: "DispatchProviders",
    initialState,
    reducers: {
        getDispatchProvidersLoading: (state) => {
            state.isLoading = true;
        },
        getDispatchProvidersSuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchProvider = action.payload;
        },
        getDispatchProvidersFail: (state) => {
            state.isLoading = false;
        },
        deleteDispatchProviderLoading: (state) => {
            state.isLoading = true;
        },
        deleteDispatchProviderSuccess: (state) => {
            state.isLoading = false;
        },
        deleteDispatchProviderFail: (state) => {
            state.isLoading = false;
        },
        getDispatchProviderIdLoading: (state) => {
            state.isLoading = true;
        },
        getDispatchProviderIdsuccess: (state, action: PayloadAction<DispatchAction>) => {
            state.isLoading = false;
            state.dispatchProviderId = action.payload;
        },
        getDispatchProviderIdFail: (state) => {
            state.isLoading = false;
        },
        createDispatchProviderLoading: (state) => {
            state.isLoading = true;
        },
        createDispatchProviderSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createDispatchProviderFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editDispatchProviderLoading: (state) => {
            state.isLoading = true;
        },
        editDispatchProviderSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editDispatchProviderFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = DispatchProviderSlice;

export const {
    getDispatchProvidersLoading,
    getDispatchProvidersSuccess,
    getDispatchProvidersFail,
    deleteDispatchProviderFail,
    deleteDispatchProviderLoading,
    deleteDispatchProviderSuccess,
    getDispatchProviderIdFail,
    getDispatchProviderIdLoading,
    getDispatchProviderIdsuccess,
    createDispatchProviderLoading,
    createDispatchProviderSuccess,
    createDispatchProviderFail,
    editDispatchProviderFail,
    editDispatchProviderLoading,
    editDispatchProviderSuccess
} = actions;

export const getDispatchProviders =
    (page?: number, perpage?: number, search?: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getDispatchProvidersLoading());
        try {
            const response = await axios.get(`${BASE_URL}/dispatchProvider/get`, {
                params: {
                    page,
                    perpage,
                    search
                },
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if (response.status) {
                dispatch(getDispatchProvidersSuccess(response.data));
            }
        } catch (error) {
            dispatch(getDispatchProvidersFail());
        }
    };

export const deleteDispatchProvider =
    (id: string, cb: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteDispatchProviderLoading());
        try {
            const response = await axios.post(`${BASE_URL}/dispatchProvider/delete/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.status) {
                dispatch(deleteDispatchProviderSuccess());
                toastr.success("", response.data.message);
                cb();
            }
        } catch (error) {
            dispatch(deleteDispatchProviderFail());
            toastr.error(error.response.data.message);
        }
    };

export const getDispatchProviderId =
    (id: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getDispatchProviderIdLoading());
        try {
            const response = await axios.get(`${BASE_URL}/dispatchProvider/get/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if (response.status) {
                dispatch(getDispatchProviderIdsuccess(response.data.provider));
            }
        } catch (error) {
            dispatch(getDispatchProviderIdFail());
        }
    };

export const createDispatchProvider =
    (data: CreateDispatchProviderPayload, cb: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createDispatchProviderLoading());
        try {
            const response = await axios.post(`${BASE_URL}/dispatchProvider/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.status) {
                dispatch(createDispatchProviderSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb();
            }
        } catch (error) {
            dispatch(createDispatchProviderFail(error.response.data.message));
            toastr.error(error.response.data.message);
        }
    };

export const editDispatchProvider =
    (data: any, id: string, cb: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editDispatchProviderLoading());
        try {
            const response = await axios.post(`${BASE_URL}/dispatchProvider/edit/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.status) {
                dispatch(editDispatchProviderSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb();
            }
        } catch (error) {
            dispatch(editDispatchProviderFail(error.response.data.message));
            toastr.error(error.response.data.message);
        }
    };

export default reducer;
