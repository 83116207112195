import React from "react";
import Avatar from "assests/images/avatar.png";
import "./styles.scss";
import { Link } from "react-router-dom";

export const household = [
  {
    farmerid: "6720",
    first: "Dianne",
    id: 1,
    last: "Mckinney.",
    phone: "0803-671-0912"
  },
  {
    farmerid: "6720",
    first: "Dianne",
    id: 2,
    last: "Mckinney.",
    phone: "0803-671-0912"
  },
  {
    farmerid: "6720",
    first: "Dianne",
    id: 3,
    last: "Mckinney.",
    phone: "0803-671-0912"
  },
  {
    farmerid: "6720",
    first: "Dianne",
    id: 4,
    last: "Mckinney.",
    phone: "0803-671-0912"
  },
  {
    farmerid: "6720",
    first: "Dianne",
    id: 5,
    last: "Mckinney.",
    phone: "0803-671-0912"
  },
  {
    farmerid: "6720",
    first: "Dianne",
    id: 6,
    last: "Mckinney.",
    phone: "0803-671-0912"
  }
];

export const householdColumns = [
  {
    name: "",
    index: "",
    id: 1,
    render: item => (
      <span>
        <img src={item.farmer_image} className="avatar" alt="farmer" />
      </span>
    )
  },
  {
    name: "Farmers ID",
    index: "farmer_id",
    id: 2
  },
  {
    name: "First Name",
    index: "first_name",
    id: 3
  },
  {
    name: "Last Name",
    index: "second_name",
    id: 4
  },
  {
    name: "Phone Number",
    index: "phone",
    id: 5
  },
  {
    name: "Community",
    index: "community",
    id: 6
  },
  {
    name: "LGA",
    index: "lga",
    id: 7
  },
  {
    name: "Actions",
    id: 8,
    render: ({ _id, pay }) => (
      <span className="span-container ">
        <p className="pay cursor-pointer " onClick={pay}>
          Pay
        </p>
        <p className="edit pl-3">
          <Link to={`/household/farmer/${_id}`}>Edit</Link>
        </p>
        {/* <p className="deactivate pl-3">Deactivate</p> */}
      </span>
    )
  }
];
