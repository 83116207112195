import Button from "../../components/Button/index";
import "./styles.scss";
import { format } from "date-fns";

export const transactionColumn = [
  {
    name: "Date",
    render: item => (
      <div className="span-container">
        <p className="date">{format(new Date(item.date), "do LLL, yy")}</p>
        <p className="time pl-3">{format(new Date(item.made), "hh:mmaaa")}</p>
      </div>
    ),
    id: 1
  },
  {
    name: "Transaction Ref",
    index: "transaction_ref",
    id: 2
  },
  {
    name: "Name",
    index: "farmer_name",
    id: 3
  },
  {
    name: "Produce",
    index: "produce_name",
    id: 4
  },
  {
    name: "Quantity",
    index: "produce_quantity",
    id: 5
  },
  {
    name: "Status",
    id: 6,
    render: (item: any) => {
      return (
        <span>
          <Button
            label={item.status === "Owing" ? "Unpaid" : item.status}
            btnType="btn-secondary"
            boxClasses="button-container"
            xtraClass={`"button-position" ${item.status} `}
            btnStyle="6rem"
            onClick={() => {
              item.action(item);
            }}
          />
        </span>
      );
    }
  }
];

// : "button-position unpaid"
