import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { DashboardState, SummaryAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: DashboardState = {
    isLoading: false,
    dashboardSummary: null
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        getDashboardSummaryLoading: (state) => {
            state.isLoading = true;
        },
        getDashboardSummarySuccess: (state, action: PayloadAction<SummaryAction>) => {
            state.isLoading = false;
            state.dashboardSummary = action.payload;
        },
        getDashboardSummaryFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = dashboardSlice;

export const { getDashboardSummaryLoading, getDashboardSummarySuccess, getDashboardSummaryFail } = actions;

export const getdashboardSummary = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDashboardSummaryLoading());
    try {
        const response = await axios.get(`${BASE_URL}/dashboard/summary`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getDashboardSummarySuccess(response.data));
        }
    } catch (error) {
        console.log(error);

        dispatch(getDashboardSummaryFail("fail"));
    }
};

export default reducer;
