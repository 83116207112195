import "./styles.scss";
import Star from "components/Star";
import { format } from "date-fns";
import { formatNumber } from "utils/utils";

export const manufacturing = [
  {
    date: "2nd Sept. 19",
    time: "10am",
    houseId: "67",
    id: 1,
    produce: "Fresh Milk",
    qty: "32lt.",
    checked: 4
  },
  {
    date: "2nd Sept. 19",
    time: "10am",
    houseId: "67",
    id: 2,
    produce: "Fresh Milk",
    qty: "32lt.",
    checked: 2
  },
  {
    date: "2nd Sept. 19",
    time: "10am",
    houseId: "67",
    id: 3,
    produce: "Fresh Milk",
    qty: "32lt.",
    checked: 1
  },
  {
    date: "2nd Sept. 19",
    time: "10am",
    houseId: "67",
    id: 4,
    produce: "Fresh Milk",
    qty: "32lt.",
    checked: 0
  },
  {
    date: "2nd Sept. 19",
    time: "10am",
    houseId: "67",
    id: 5,
    produce: "Fresh Milk",
    qty: "32lt.",
    checked: 3
  },
  {
    date: "2nd Sept. 19",
    time: "10am",
    houseId: "67",
    id: 6,
    produce: "Fresh Milk",
    qty: "32lt.",
    checked: 5
  }
];

export const column = [
  {
    name: "Date",
    render: (item: { made: string | number | Date }) => (
      <span className="span-container">
        <p className="date">{format(new Date(item.made), "do LLL, yy")}</p>
        <p className="time pl-3">{format(new Date(item.made), "hh:mmaaa")}</p>
      </span>
    ),
    id: 1
  },
  {
    name: "Produce",
    index: "produce_name",
    id: 2
  },
  {
    name: "Quantity",
    id: 3,
    render: (item: { produce_quantity: string | number }) => formatNumber(item.produce_quantity)
  },
  {
    name: "Rating",
    render: (item: { produce_rating: number }, i: any) => {
      return <Star disabled={true} stars={item.produce_rating} />;
    },

    id: 4
  }
];
