import arrowdown from "assests/icons/Vector (2).svg";
import "./styles.scss";
import React from 'react'


type Props = {
    text: string;
    options: any[];
    handleDropdownChange?: () => any;
    handleAction?: (e: any, f: any, cb: () => void) => void;
    handleToggle?: (e: any) => void;
    selectedOption?: string;
    open?: boolean;
    boxClasses?: string;
    disabled?: boolean;
    boxScroll?: string;
    cb?: () => void;
};
export type Ref = HTMLElement;

const ArrowDropdown = React.forwardRef(({
    text,
    options,
    handleDropdownChange,
    handleAction,
    selectedOption,
    open,
    boxClasses,
    handleToggle,
    disabled = false,
    boxScroll,
    cb,
}: Props, ref:any) => {
    return (
        <div className={` select-dropdown ${boxClasses}`} ref={ref} >
            <div className="select-box cursor-pointer" onClick={handleDropdownChange}>
                <input
                    type="text"
                    name="selectedOption"
                    className="cursor-pointer"
                    placeholder={text}
                    value={selectedOption}
                    disabled
                />
                {!disabled && <img src={arrowdown} alt="alt" />}
            </div>
            <div className="" >
            {open && !disabled && (
                <div className="select-options">
                    <ul className={`options-dropdown cursor-pointer  ${options && options.length > 5 && "scroll"} `}>
                        {options &&
                            options.map((option: any) => {
                                if (options && options[0].label) {
                                    return (
                                        <li>
                                            <input
                                                type="checkbox"
                                                value={option.id}
                                                id={option.id}
                                                className="mr-2"
                                                name={option.name}
                                                checked={option.isChecked}
                                                onChange={handleToggle}
                                            />
                                            <label htmlFor={option.id} className="cursor-pointer">
                                                <p className="mb-0">{option.label && option.label}</p>
                                            </label>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li
                                            className={`mb-2 ${option.style && option.style}`}
                                            key={Math.random() * 1000}
                                            onClick={() =>
                                                handleAction && handleAction(option.name, option.id, () => cb && cb())
                                            }
                                        >
                                            <p className="mb-0">{option.name}</p>
                                            <p className="mb-0">{option.label && option.label}</p>
                                        </li>
                                    );
                                }
                            })}
                    </ul>
                </div>
            )}
            </div>
        </div>
    );
});

export default ArrowDropdown;
