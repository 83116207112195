import * as React from "react";
import "./styles.scss";
import TabMenu from "components/TabMenu";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import ProfileDetails from "./Details/index";
import ProfilePassword from "./Password/index";

type ProfileProps = {};

const Profile: React.FC<ProfileProps> = () => {
    const menus = [
        {
            name: "Details",
            path: "details"
        },
        {
            name: "Password",
            path: "password"
        }
    ];
    const match = useRouteMatch();

    return (
        <div id="profile-page">
            <div className="container">
                <div className="row">
                    <div className="menus col-lg-12 col-md-12">
                        <TabMenu menus={menus} classes="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Switch>
                            <Route path={match.path} exact>
                                <Redirect to={`${match.url}/details`} />
                            </Route>
                            <Route path={`${match.path}/details`}>
                                <ProfileDetails />
                            </Route>
                            <Route path={`${match.path}/password`}>
                                <ProfilePassword />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
