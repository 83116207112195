import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createProduce, editProduce, getProduce, getSingleProduce } from "store/slice/ProduceSlice/ProduceSlice";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";

type Props = {
    closeModal: () => void;
    employeeId?: string;
    dispatchproviders?: [];
    modalData: { edit: boolean; produceID: string };
};
const CreateProduce = ({ closeModal, modalData }: Props) => {
    const { errorMessage, isLoading, singleProduce } = useSelector((state: RootState) => state.produce);
    const { open, selectedOption, handleDropdownChange, handleAction, dropdownRef } = useArrowDropdown();
    const dispatch = useDispatch();
    const [error, setError] = React.useState("");
    const { register, handleSubmit, errors, setValue } = useForm<FormData>({
        mode: "onBlur"
    });
    const { edit, produceID } = modalData;
    const category = [
        {
            name: "Supply Chain"
        },
        {
            name: "Manufacturing"
        }
    ];
    type FormData = {
        scale: string;
        unit_price: string;
        produce_name: string;
        category: string;
        icons: string;
    };
    React.useEffect(() => {
        if (selectedOption) {
            setError("");
        }
    }, [selectedOption]);

    //Get single user details

    React.useEffect(() => {
        if (edit) {
            dispatch(getSingleProduce(produceID));
        }
    }, [produceID, edit]);

    //Update fields
    React.useEffect(() => {
        if (singleProduce !== null && edit) {
            setValue("produce_name", singleProduce?.produce?.produce_name);
            setValue("unit_price", singleProduce?.produce?.unit_price);
            setValue("scale", singleProduce?.produce?.scale);
        }
    }, [edit, singleProduce]);

    const onFormSubmit = (vals: FormData) => {
        if (isLoading) return;
        if (selectedOption === "" && !edit) {
            setError("Please select a Category");
            return;
        }

        const data = {
            scale: vals.scale,
            unit_price: vals.unit_price,
            category: selectedOption,
            icon: "none",
            produce_name: vals.produce_name
        };
        const editData = {
            scale: vals.scale,
            unit_price: vals.unit_price,
            category: selectedOption === "" ? singleProduce?.produce?.category : selectedOption,
            icon: "none",
            produce_name: vals.produce_name
        };
        if (edit) {
            dispatch(
                editProduce(editData, produceID, () => {
                    closeModal();
                    dispatch(getProduce(1, 10));
                })
            );
        } else {
            dispatch(
                createProduce(data, () => {
                    closeModal();
                    dispatch(getProduce(1, 10));
                })
            );
        }
    };
    return (
        <Modal modalText={edit ? "Edit Produce" : "Create Produce"} onClose={closeModal}>
            <div id="create-produce-modal">
                <p className="">Complete this form with accurate data to create a new produce on Sebore farms.</p>
                <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
                    <Textbox
                        type="text"
                        name="produce_name"
                        label="Produce Name"
                        placeholder="Produce Name"
                        error={errors.produce_name && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        disabled={isLoading}
                    />
                    <Textbox
                        type="text"
                        label="Measurement scale"
                        name="scale"
                        placeholder="Measurement scale"
                        boxClasses="mt-3"
                        error={errors.scale && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        disabled={isLoading}
                    />
                    <Textbox
                        type="number"
                        label="Unit Price"
                        name="unit_price"
                        placeholder="Unit Price"
                        boxClasses="mt-3"
                        error={errors.unit_price && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        disabled={isLoading}
                    />
                    {/* <ArrowDropdown
                        text="Select Produce icon"
                        options={status}
                        handleDropdownChange={handleDropdownChange}
                        handleAction={handleAction}
                        selectedOption={selectedOption}
                        open={open}
                        boxClasses="mt-3"
                    /> */}
                    <ArrowDropdown
                        text="Add Category"
                        options={category}
                        handleDropdownChange={handleDropdownChange}
                        handleAction={handleAction}
                        selectedOption={selectedOption || (edit ? singleProduce?.produce?.category : "")}
                        open={open}
                        boxClasses="mt-4"
                        ref={dropdownRef}
                    />
                    <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
                    {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
                    <Button
                        label={edit ? "Edit Produce" : "Create Produce"}
                        btnType="btn-primary"
                        boxClasses="mt-5 mb-5"
                        btnStyle="100%"
                        loading={isLoading}
                    />
                </form>
                <p className="role-p" onClick={closeModal}>
                    Cancel
                </p>
            </div>
        </Modal>
    );
};

export default CreateProduce;
