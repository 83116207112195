import * as React from "react";
import "./styles.scss";
import { ReactComponent as CloseIcon } from "assests/icons/close.svg";

export interface ModalProps {
    children: React.ReactNode;
    onClose: () => void;
    modalText: string;
    boxClasses?: string;
    modallength?: string;
}

const Modal: React.FC<ModalProps> = ({ children, onClose, modalText, boxClasses, modallength }) => {
    const onModalClick = (): void => {
        onClose && onClose();
    };

    //To remove browser scrollbar when modal is Opened
    React.useEffect(() => {
        document.body.classList.add("bodyfix");
        return () => {
            document.body.classList.remove("bodyfix");
        };
    }, []);

    //To close the modal from outside
    const closeModal = (e) => {
        if (e.target.classList.contains("backDrop")) {
            onClose && onClose();
        }
    };
    return (
        <div className="modal-container">
            <div className="backDrop" onClick={closeModal}></div>
            <div className={`contentwrapper ${boxClasses}`}>
                <div className="modal_header">
                    <h4>{modalText}</h4>
                    <CloseIcon onClick={onModalClick} className="cursor-pointer" />
                </div>
                <div className={`${modallength} modal_content`}>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
