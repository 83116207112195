import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { confirmDispatch, getDispatchRecord, getDispatchRecordSummary } from "store/slice/DispatchSlice/DispatchSlice";

type Props = {
    closeModal: () => void;
    dispatchId?: string;
    loading?: boolean;
};
const DispatchConfirmation = ({ closeModal, dispatchId, loading }: Props) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: "onBlur"
    });
    const dispatch = useDispatch();
    type FormData = {
        additionalComments: string;
        status: string;
    };

    const onSubmit = ({ additionalComments, status = "confirmed" }: FormData) => {
        if (loading) return;
        const data = {
            additionalComments,
            status
        };
        if (dispatchId)
            dispatch(
                confirmDispatch(data, dispatchId, () => {
                    dispatch(getDispatchRecord(1, 10));
                    dispatch(getDispatchRecordSummary());
                    closeModal();
                })
            );
    };
    return (
        <Modal modalText="Status Confirmation" onClose={closeModal}>
            <div id="dispatch-modal">
                <p className="mt-3 mb-3">Are you sure you are ready to make this change? Let us know your reason:</p>
                <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                    <textarea
                        name="additionalComments"
                        id="additionalComments"
                        cols={35}
                        rows={10}
                        className="textarea"
                        placeholder="Add comment (optional)"
                        ref={register({
                            required: true
                        })}
                    />
                    <Button
                        label="Confirm"
                        btnType="btn-primary"
                        boxClasses="mt-5 mb-5"
                        btnStyle="100%"
                        loading={loading}
                    />
                </form>
            </div>
        </Modal>
    );
};

export default DispatchConfirmation;
