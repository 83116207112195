import * as React from "react";
import "./styles.scss";
import LgaIcon from "assests/icons/statsCard/Frame_lga.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { dispatchProviderColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/lga_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import {
    createDispatchProviderFail,
    deleteDispatchProvider,
    getDispatchProviders
} from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import CreateOrEditProvider from "components/Modals/CreateOrEditProvider";
import { deleteLga, getLga } from "store/slice/LgaSlice/LgaSlice";
import { toastPopUp } from "utils/utils";
import CreateLga from "components/Modals/CreateOrEditLGA";

type Props = {};

const Lga: React.FunctionComponent<Props> = () => {
    //State
    const [page, setPage] = React.useState<number>(1);
    const [perpage, setPerpage] = React.useState<number>(10);
    const [modal, setModal] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>("");
    const [lgaId, setLgaId] = React.useState<string>("");

    //Hook
    const { search, inputRef, handleChange, searched } = useSearch(getLga, perpage);

    //Redux
    const dispatch = useDispatch();
    const { lga, isLoading } = useSelector((state: RootState) => state.lga);

    //Get LGA'S
    React.useEffect(() => {
        dispatch(getLga(page, perpage));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // setpage number to 1 if searched is done
    React.useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    const edit = (e: any, id: any) => {
        setModal(!modal);
        setValue(e);
        setLgaId(id);
    };

    const deleteLgaHandler = (id: any, name: string) => {
        toastPopUp(
            () => {
                dispatch(
                    deleteLga(id, () => {
                        dispatch(getLga(page, perpage));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete State",
            name,
            "red"
        );
    };

    const LgaList =
        lga &&
        lga?.lga?.map((data: any) => {
            return {
                lga_name: data.lga_name,
                lga_code: data.lga_code,
                edit: edit,
                deleteLgaHandler: () => deleteLgaHandler(data._id, data.lga_name),
                id: data._id
            };
        });

    //--------------------------------------------
    //Pagination
    //---------------------------------------------
    const handlePageChange = React.useCallback(
        (page: number, perpage: number) => {
            setPage(page);
            dispatch(getDispatchProviders(page, perpage, search));
        },
        [search]
    );

    const paginate = React.useCallback(
        (pageNo: number, perpage: number) => {
            setPage(pageNo);
            dispatch(getDispatchProviders(pageNo, perpage, search));
        },
        [search]
    );

    //------------------------------------------------------------
    //Show Number for PerPage Function
    //------------------------------------------------------------
    const handleNewPage = (pageNo: number, page = 1) => {
        setPerpage(pageNo);
        setPage(1);
        dispatch(getDispatchProviders(page, pageNo, search));
    };

    //------------------------------------------------
    //modal toggler
    //------------------------------------------------
    const closeModal = () => {
        setModal(!modal);
        setValue("");
        dispatch(createDispatchProviderFail(""));
    };

    return (
        <div className="lga-page">
            {modal && <CreateLga closeModal={closeModal} lgaId={lgaId} value={value} />}
            <div className="container">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={lga?.pagination?.total || 0}
                        text="LGA"
                        classes="stats-icon"
                        image={LgaIcon}
                    />
                    <StatsCard image={Icon} classes="middle-img" centerText="Create LGA" onClick={closeModal} />
                </div>
                <h4 className="page-title mb-2">LGA Logs</h4>
                <div className="search-field">
                    <SearchIcon
                        placeholder="Search for LGA name or LGA code."
                        boxClasses=" mb-3"
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    />
                    <Dropdown perPage={perpage} action={handleNewPage} />
                </div>
                <DataTable columns={dispatchProviderColumn} data={LgaList} loading={isLoading} />
                <Pagination
                    page={page}
                    lastPage={lga?.pagination?.lastPage}
                    paginate={paginate}
                    total={lga?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perpage}
                />
            </div>
        </div>
    );
};

export default Lga;
