import React from "react";
import "./styles.scss";

type Props = {
    label?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    value?: string;
    placeholder?: string;
    boxClasses?: string;
    type?: string;
    error?: string;
    iconUrl?: string;
    onIconClick?: string;
    disabled?: boolean;
    min?: string;
    customRef?: string | ((instance: HTMLInputElement | null) => void);
};

const Textbox = ({
    label,
    onChange,
    name,
    value,
    placeholder,
    boxClasses,
    type,
    error,
    disabled,
    min,
    customRef
}: Props) => {
    return (
        <div className={`${boxClasses} textbox`}>
            <fieldset className={`${error && "error"} ${disabled && "disabled-input"}`}>
                <legend id="label-legend" className={` pl-2 pr-2 ${error && "label-error"}`}>
                    {label}
                </legend>
                <div className="d-flex">
                    <input
                        autoComplete="off"
                        min={min && min}
                        onChange={onChange}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        type={type}
                        disabled={disabled && disabled}
                        ref={customRef}
                    />
                </div>
            </fieldset>
            <p className={`${error ? "d-block" : "d-none"} input-error mt-1`}>{error}</p>
        </div>
    );
};

export default Textbox;
