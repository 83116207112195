import { useEffect, useState, useRef } from "react";
import Avatar from "assests/images/default-avatar.png";
import "./styles.scss";
import { useForm } from "react-hook-form";
import Textbox from "components/Textbox";
import useArrowDropdown from "hooks/useArrowDropdown";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { getProfile, updateProfile, uploadProfilePicture } from "store/slice/UserSlice/UserSlice";

const ProfileDetails = () => {
  //state
  const [preview, setPreview] = useState<string | undefined>(Avatar);
  const [image, setImage] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  type FormData = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    role: string;
  };
  //hook
  const { open, selectedOption, handleDropdownChange, handleAction } = useArrowDropdown();
  const { user, profile, loading, role, isLoading } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onBlur"
  });

  //Getting profile details
  useEffect(() => {
    dispatch(getProfile(user._id));
  }, []);

  //Update fields
  useEffect(() => {
    if (profile !== null && role !== null) {
      setValue("firstName", profile?.firstName);
      setValue("email", profile?.email);
      setValue("lastName", profile?.lastName);
      setValue("phoneNo", profile?.phoneNo);
      setValue("role", role?.[0]?.role_name);
      setPreview(profile?.image);
    }
  }, [profile]);

  const onFormSubmit = (vals: FormData) => {
    const data = {
      firstName: vals.firstName,
      lastName: vals.lastName,
      email: vals.email,
      phoneNo: vals.phoneNo
    };

    if (image !== undefined) {
      const formData = new FormData();
      formData.append("image", image!);
      dispatch(uploadProfilePicture(formData, user._id));
    }

    dispatch(updateProfile(data, user._id));
  };

  //Validating Phone Number
  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  const handleTriggerImgSelection = e => {
    e.preventDefault();
    fileInputRef.current!.click();
  };

  const handleSelectImage = e => {
    const file = e.target.files[0];
    setImage(file);

    if (file === undefined) return;
    const reader = new FileReader();
    reader.onload = function () {
      setPreview(reader.result as string);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div id="profile-details-page">
      <div className="col-md-12 pl-0 mt-4">
        <h4 className="page-title">Profile Photo</h4>
        <div className="row">
          <div className="col-md-8">
            <div className="d-flex mt-3 profile">
              <div className="profile-image mr-sm-0 mr-lg-3">
                <img
                  src={preview === undefined ? Avatar : preview}
                  className="farmer_image cursor-pointer"
                  alt="user_image"
                  onClick={handleTriggerImgSelection}
                />
                <input
                  name=""
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleSelectImage}
                />
              </div>
              <div className="profile-text mt-2">
                <h4>Change Profile Picture</h4>
                <p>Choose a new avatar to be used across Sebore Farms</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <Button
              type="submit"
              label="Save Changes"
              btnType="btn-primary"
              boxClasses="mt-4"
              xtraClass="btn-class"
              loading={loading}
              onClick={handleSubmit(onFormSubmit)}
            />
          </div>
        </div>

        <div className="col-md-8 pl-0">
          <h4 className="page-title mt-5 ">Basic Information</h4>
          <form>
            <div className="row  profile-form">
              <div className="col-lg-6 col-md-12 col-sm-12 pl-sm-0 mb-sm-3 form-details">
                <Textbox
                  label="First Name"
                  name="firstName"
                  type="text"
                  customRef={register({
                    required: true
                  })}
                  error={errors.firstName && "This field is required"}
                  placeholder="First Name"
                  boxClasses="mt-3"
                  disabled={isLoading}
                />
                <Textbox
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  boxClasses="mt-top"
                  customRef={register({
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  })}
                  error={errors.email && errors.email.message}
                  disabled={true}
                />
                <Textbox
                  label="Role"
                  name="role"
                  placeholder="Role"
                  boxClasses="mt-top"
                  disabled={true}
                  customRef={register({
                    required: true
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pl-lg-5 pl-sm-0 pr-0 form-details ">
                <Textbox
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  customRef={register({
                    required: true
                  })}
                  boxClasses="mt-3"
                  error={errors.lastName && "This field is required"}
                  disabled={isLoading}
                />
                <Textbox
                  label="Phone Number"
                  name="phoneNo"
                  type="number"
                  customRef={register({
                    required: "This field is required",
                    validate: validatePhoneNo
                  })}
                  error={errors.phoneNo && errors.phoneNo.message}
                  placeholder="Phone Number"
                  boxClasses="mt-top"
                  disabled={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
