import * as React from "react";
import "./styles.scss";
import TabMenu from "components/TabMenu";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import Produce from "./Produce";
import Collection from "./CollectionPoint";
import Accounts from "./Accounts";
import DispatchProvider from "./DispatchProvider";
import DispatchEmployee from "./DispatchEmployee";
import Communities from "./Communities";
import States from "./States";
import Lga from "./LGA";

type SettingsProps = {};

const Settings: React.FC<SettingsProps> = () => {
    const menus = [
        {
            name: "Produce",
            path: "produce"
        },
        {
            name: "Collection Points",
            path: "collection-points"
        },
        {
            name: "Accounts",
            path: "accounts"
        },
        {
            name: "Dispatch Provider",
            path: "dispatch-provider"
        },
        {
            name: "Dispatch Employee",
            path: "dispatch-employee"
        },
        {
            name: "Communities",
            path: "communities"
        },
        {
            name: "States",
            path: "states"
        },
        {
            name: "LGA",
            path: "lga"
        }
    ];
    const match = useRouteMatch();

    return (
        <div id="settings-page">
            <div className="container">
                <div className="row">
                    <div className="menus col-lg-12 col-md-12">
                        <TabMenu menus={menus} classes="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Switch>
                            <Route path={match.path} exact>
                                <Redirect to={`${match.url}/produce`} />
                            </Route>
                            <Route path={`${match.path}/produce`}>
                                <Produce />
                            </Route>
                            <Route path={`${match.path}/collection-points`}>
                                <Collection />
                            </Route>
                            <Route path={`${match.path}/accounts`}>
                                <Accounts />
                            </Route>
                            <Route path={`${match.path}/dispatch-provider`}>
                                <DispatchProvider />
                            </Route>
                            <Route path={`${match.path}/dispatch-employee`}>
                                <DispatchEmployee />
                            </Route>
                            <Route path={`${match.path}/communities`}>
                                <Communities />
                            </Route>
                            <Route path={`${match.path}/states`}>
                                <States />
                            </Route>
                            <Route path={`${match.path}/lga`}>
                                <Lga />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
