import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/person 1.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { roleColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/Vector (5).png";
import CreateRoleOrEdit from "components/Modals/CreateRoleOrEdit";
import useSearch from "hooks/useSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import { toastr } from "react-redux-toastr";
import { createRoleFail, deleteRole, getPermissions, getRoles } from "store/slice/RoleSlice/RoleSlice";
import { checkPermission, toastPopUp } from "utils/utils";

type Props = {};

const Roles: React.FunctionComponent<Props> = () => {
  const [page, setPage] = React.useState<number>(1);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>("");
  const [roleId, setRoleId] = React.useState<string>("");
  const [editedPerm, setEditedPerm] = React.useState<any>([]);
  const { search, inputRef, handleChange } = useSearch(getRoles, perpage);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getRoles(page, perpage));
    dispatch(getPermissions());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Hooks
  const { roles, isLoading } = useSelector((state: RootState) => state.roles);
  const { permissions } = useSelector((state: RootState) => state.user);

  const edit = (e: any, id: any, permissions: any) => {
    closeModal();
    setValue(e);
    setRoleId(id);
    setEditedPerm(permissions);
  };

  const deleteRoleId = (e: any, name: string) => {
    if (!permissions?.includes("ROLE_MANAGE")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }

    toastPopUp(
      () => {
        dispatch(
          deleteRole(e, () => {
            dispatch(getRoles(page, perpage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the role",
      name,
      "red"
    );
  };

  const rolesList =
    roles &&
    roles?.myroles?.map((data: any) => {
      return {
        role_name: data.role_name,
        users: data.users,
        role: data.role_name.role_name,
        edit: edit,
        deleteRoleId: deleteRoleId,
        permission: data.permission,
        id: data._id
      };
    });

  const handlePageChange = React.useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(getRoles(page, perpage, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(getRoles(pageNo, perpage, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getRoles(page, pageNo, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const closeModal = () => {
    setModal(!modal);
    setValue("");
    dispatch(createRoleFail(""));
  };

  return (
    <div id="roles-page">
      {modal && checkPermission(permissions, "ROLE_MANAGE") && (
        <CreateRoleOrEdit closeModal={closeModal} value={value} roleId={roleId} checkedPerm={editedPerm} />
      )}
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={roles?.pagination?.total || 0} text="Role" classes="stats-icon-cash" image={People} />
          <StatsCard image={Icon} classes="middle-img" centerText="Create Role" onClick={closeModal} />
        </div>
        <h4 className="page-title mb-2">Roles</h4>

        <div className="search-field">
          <SearchIcon
            placeholder="Search for First name, Phone no. or email"
            boxClasses=" mb-3"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>
        <DataTable columns={roleColumn} data={rolesList} loading={isLoading} />
        <Pagination
          page={page}
          lastPage={roles?.pagination?.lastPage}
          paginate={paginate}
          total={roles?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Roles;
