import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import "./styles.scss";
import greenT from "assests/icons/mini-truck 1 (1).png";
import yellowT from "assests/icons/mini-truck 1 (2).png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { dispatchColumn } from "./data";
import CardContainer from "components/CardContainer";
import blackT from "assests/icons/mini-truck 1.png";
import Icon from "assests/icons/statsCard/Vector (4).png";
import CreateDispatch from "components/Modals/CreateOrEditDispatch";
import DispatchConfirmation from "components/Modals/DispatchConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { getDispatchRecord, getDispatchRecordSummary } from "store/slice/DispatchSlice/DispatchSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import { format } from "date-fns";
import { checkPermission, formatNumber } from "utils/utils";

type Props = {};

const Dispatch: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [dispatchId, setDispatchID] = React.useState<string>("");
  const [value, setValue] = React.useState<string>("");
  const [singleDispatch, SetSingleDispatch] = React.useState<object>({});
  const [modalDispatch, setModalDispatch] = React.useState<boolean>(false);

  //Hook
  const dispatch = useDispatch();
  const { dispatchRecords, dispatchRecordSummary, isLoading } = useSelector(
    (state: RootState) => state.dispatchReducer
  );
  const { permissions } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getDispatchRecord(page, perpage));
    dispatch(getDispatchRecordSummary());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Pagination
  const handlePageChange = useCallback((page: number, perpage: number) => {
    setPage(page);
    dispatch(getDispatchRecord(page, perpage));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginate = useCallback((pageNo: number, perpage: number) => {
    setPage(pageNo);
    dispatch(getDispatchRecord(pageNo, perpage));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Handle Modal
  const closeModal = () => {
    setModal(!modal);
    setValue("");
  };

  const closeConfirmationModal = () => {
    setModalDispatch(!modalDispatch);
  };

  const handleConfirmationModal = e => {
    setModalDispatch(!modalDispatch);
    setDispatchID(e);
  };

  const openDispatch = (e, item) => {
    SetSingleDispatch(item);
    if (e.target.name !== "button") {
      setModal(!modal);
      setValue("filled");
    }
  };

  const dispatchRecordsList =
    dispatchRecords &&
    dispatchRecords?.records?.map((data: any) => {
      return {
        date: data.createdAt,
        collectionPoint: data.collectionPoint,
        produce: data.produce,
        quantity: formatNumber(data.quantity),
        status: data.status,
        modal: handleConfirmationModal,
        id: data._id,
        collectionOfficer: data.collectionOfficer,
        dispatchEmployee: data.dispatchEmployee,
        dispatchProvider: data.dispatchProvider,
        additionalComments: data.additionalComments,
        dateConfirmed: data.dateConfirmed,
        community: data.community_name
      };
    });

  return (
    <div id="dispatch-page">
      {modal && checkPermission(permissions, "DISPATCH_CREATE") && (
        <CreateDispatch closeModal={closeModal} item={singleDispatch} value={value} />
      )}
      {modalDispatch && checkPermission(permissions, "DISPATCH_CONFIRM") && (
        <DispatchConfirmation closeModal={closeConfirmationModal} dispatchId={dispatchId} loading={isLoading} />
      )}
      <div className="container">
        <CardContainer title="Dispatch Stats" classes="mb-5">
          <StatsCard
            statsNumber={dispatchRecordSummary?.dispatchNo || 0}
            text="No of Dispatch"
            classes="stats-icon-black"
            image={blackT}
          />

          <StatsCard
            statsNumber={dispatchRecordSummary?.confirmedDispatch || 0}
            text="Confirmed Dis."
            classes="stats-icon-valid"
            image={greenT}
          />

          <StatsCard
            statsNumber={dispatchRecordSummary?.unconfirmedDispatch || 0}
            text="Unconfirmed Dis."
            classes="stats-icon-orange"
            image={yellowT}
          />
          <StatsCard image={Icon} classes="middle-img" centerText="Create Dispatch " onClick={closeModal} />
        </CardContainer>

        <DataTable columns={dispatchColumn} data={dispatchRecordsList} onClick={openDispatch} />
        <Pagination
          page={page}
          lastPage={dispatchRecords?.pagination?.lastPage}
          paginate={paginate}
          total={dispatchRecords?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Dispatch;
