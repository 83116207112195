import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "pages/Auth/Login";
import ForgotPassword from "pages/Auth/ForgotPassword";
import SetPassword from "pages/Auth/SetPassword";
import Navbar from "components/NavbarHeader";
import Sidebar from "components/Sidebar";
import Dashboard from "pages/Dashboard";
import ScrollToTop from "components/ScrollToTop";
import Household from "pages/Household";
import Transaction from "pages/Transaction";
import Inventory from "pages/Inventory";
import Dispatch from "pages/Dispatch";
import SingleHouseHold from "pages/Household/SingleHousehold";
import SupplyChain from "pages/Inventory/SupplyChain/SupplyChain";
import Manufacturing from "pages/Inventory/Manufacturing";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import FarmerPage from "pages/Household/FarmerPage";
import UserMgt from "pages/UserMgt";
import Settings from "pages/Settings";
import Profile from "pages/Profile";
import ReduxToastr from "react-redux-toastr";
import PayFarmerModal from "components/Modals/PayFarmerModal";
import { showPayFarmerModal } from "store/slice/UISlice/UISlice";
import { checkPermission } from "utils/utils";
import EditHouseHold from "pages/Household/SingleHousehold/EditSingleHousehold";
import { getEyowoBalance } from "store/slice/TransactionSlice/TransactionSlice";

const PrivateRoutes = props => {
  const [menu, setMenu] = useState(false);
  const { payFarmerModal } = useSelector((state: RootState) => state.ui);
  const { permissions } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const handleMenu = (): void => {
    setMenu(!menu);
  };

  useEffect(() => {
    if (permissions?.includes("EYOWO_BALANCE")) {
      dispatch(getEyowoBalance());
    }
  });

  const ToggleModal = () => {
    dispatch(showPayFarmerModal());
  };

  return (
    <div className="app-wrapper">
      <div className="main-body">
        <Sidebar menu={menu} handleMenu={handleMenu} setMenu={setMenu} />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={state => state.toastr} // This is the default
          closeOnToastrClick
          className="toastr"
        />

        <div style={{ width: "100%", overflowX: "hidden" }}>
          <Navbar handleMenu={handleMenu} />
          <div className="right-col">
            <ScrollToTop />
            {payFarmerModal && checkPermission(permissions, "FARMER_PAY") && (
              <PayFarmerModal closeModal={ToggleModal} />
            )}
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/household" exact>
                {permissions?.includes("HOUSEHOLD_VIEW") ? <Household /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/household/farmer/:userId">
                {permissions?.includes("HOUSEHOLD_VIEW") ? <FarmerPage /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/household/singlehousehold/:id" exact>
                {permissions?.includes("HOUSEHOLD_VIEW") ? <SingleHouseHold /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/household/singlehousehold/edit/:id" exact>
                {permissions?.includes("HOUSEHOLD_VIEW") ? <EditHouseHold /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/transaction" exact>
                {permissions?.includes("TRANSACTIONS_VIEW_ALL") ? <Transaction /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/inventory" exact>
                {permissions?.includes("INVENTORY_VIEW") ? <Inventory /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/dispatch" exact>
                {permissions?.includes("DISPATCH_VIEW") ? <Dispatch /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/user-mgt" component={UserMgt}>
                {permissions?.includes("USER_VIEW") ? <UserMgt /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/settings">
                {permissions?.includes("SETTINGS") ? <Settings /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/profile" component={Profile} />
              <Route path="/inventory/manufacturing" exact>
                {permissions?.includes("INVENTORY_VIEW") ? <Manufacturing /> : <Redirect to="/dashboard" />}
              </Route>
              <Route path="/inventory/supply-chain" exact>
                {permissions?.includes("INVENTORY_VIEW") ? <SupplyChain /> : <Redirect to="/dashboard" />}
              </Route>
              <Redirect to="/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/set-password/:term" exact component={SetPassword} />
      <Redirect to="/" />
    </Switch>
  );
};

type Props = {};

const Root: React.FunctionComponent<Props> = () => {
  const { userToken, user, authorized } = useSelector((state: RootState) => state.user);

  const renderRoutes = () => {
    if (userToken && user !== null && authorized) {
      return <PrivateRoutes />;
    } else {
      return <PublicRoutes />;
    }
  };
  return <Router>{renderRoutes()}</Router>;
};

export default Root;
