import React, { useState, useEffect } from "react";
import Textbox from "components/Textbox";
import "./styles.scss";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { editHousehold, getSingleHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import { getLga } from "store/slice/LgaSlice/LgaSlice";
import { getStates } from "store/slice/StateSlice/StateSlice";
import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import ArrowDropdown from "components/ArrowDropdown";
import useArrowDropdown from "hooks/useArrowDropdown";
import Button from "components/Button";
import useCheckMobileScreen from "hooks/checkMobile";
import SelectBox from "components/SelectBox";

type EditHouseholdProps = {};
const EditHouseHold: React.FC<EditHouseholdProps> = props => {
  //State
  const [householdName, setHouseholdName] = useState<string>("");
  const [error2, setError2] = React.useState("");
  const [error, setError] = React.useState("");
  const [errorPaymentFrequency, setErrorPaymentFrequency] = useState("");
  const [selectedOption3, setSelectedOption3] = React.useState("");

  const [selectComboxErrorOption, setSelectComboxErrorOption] = React.useState(false);

  //Hook
  const location: any = useLocation();
  const history = useHistory();
  let { id }: any = useParams<Record<string, string | undefined>>();
  const { register, handleSubmit, errors, setValue } = useForm<FormData>();

  //Redux
  const dispatch = useDispatch();
  const { singleHouseHolds, isLoading } = useSelector((state: RootState) => state.household);
  const { states } = useSelector((state: RootState) => state.stateReducer);
  const { lga } = useSelector((state: RootState) => state.lga);
  const { community } = useSelector((state: RootState) => state.community);
  const [keyOption3, setKeyOption3] = React.useState("");

  //Custom hooks LGA
  const {
    open,
    selectedOption,
    handleDropdownChange,
    handleAction,
    handleToggle,
    setSelectedOption,
    keyOption,
    setKeyOption,
    dropdownRef
  } = useArrowDropdown();

  //Custom hooks State
  const {
    open: soaopen,
    selectedOption: soaselectedOption,
    handleDropdownChange: soahandleDropdownChange,
    handleAction: soahandleAction,
    handleToggle: soahandleToggle,
    setSelectedOption: soasetSelectedOption,
    keyOption: soakeyOption,
    setKeyOption: soasetKeyOption,
    dropdownRef: soadropdownRef
  } = useArrowDropdown();

  const {
    open: openPaymentFrequency,
    selectedOption: selectedOptionPaymentFrequency,
    handleDropdownChange: handleDropdownChangePaymentFrequency,
    handleAction: handleActionPaymentFrequency,
    dropdownRef: dropdownRefPaymentFrequency,
    setKeyOption: setKeyOptionPaymentFrequency,
    setSelectedOption: setSelectedOptionPaymentFrequency
  } = useArrowDropdown();

  const payFrequency = [
    {
      name: "Weekly"
    },
    {
      name: "Bi-weekly"
    },
    {
      name: "Monthly"
    }
  ];

  useEffect(() => {
    dispatch(getSingleHousehold(id));
    dispatch(getLga(1, 1000));
    dispatch(getStates(1, 1000));
    dispatch(getCommunity(1, 1000));
  }, []);

  useEffect(() => {
    const { household } = location.state;
    setHouseholdName(household);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type FormData = {
    id: string;
    peak_milk_period: string;
    business_type: string;
    average_milk_per_day: string;
    house_name: string;
    house_size: string;
    lean_milk_period: string;
    selectCommunity: string;
    // payment_frequency: string;
  };

  useEffect(() => {
    if (singleHouseHolds !== null) {
      setValue("id", singleHouseHolds?.household?.house_id);
      setValue("peak_milk_period", singleHouseHolds?.household?.peak_milk_period);
      setValue("average_milk_per_day", singleHouseHolds?.household?.average_milk_per_day);
      setValue("business_type", singleHouseHolds?.household?.business_type);
      setValue("house_name", singleHouseHolds?.household?.house_name);
      setValue("house_size", singleHouseHolds?.household?.house_size);
      setValue("lean_milk_period", singleHouseHolds?.household?.lean_milk_period);
      setValue("selectCommunity", singleHouseHolds?.household?.house_community);
      soasetSelectedOption(singleHouseHolds?.household?.house_state);
      setSelectedOption(singleHouseHolds?.household?.house_lga);
      setSelectedOption3(singleHouseHolds?.household?.house_community);
      setKeyOption(singleHouseHolds?.household?.house_lga_id);
      setKeyOption3(singleHouseHolds?.household?.house_community_id);
      soasetKeyOption(singleHouseHolds?.household?.house_state_id);
      // setValue("payment_frequency", singleHouseHolds?.household?.payment_frequency);
      setSelectedOptionPaymentFrequency(singleHouseHolds?.household?.payment_frequency);
    }
  }, [singleHouseHolds]);

  //Get State
  const stateList =
    states &&
    states?.state?.map((data: any) => {
      return {
        name: data.state_name,
        id: data._id
      };
    });

  //Get LGA
  let lgaList = [] as any;
  lga &&
    lga?.lga?.map((data: any) => {
      if (soakeyOption === data.state) {
        lgaList.push({ name: data.lga_name, id: data._id });
      }
    });

  //Get Communities
  let array = [] as any;
  community &&
    community?.community.map((data: any) => {
      if (soakeyOption === data.state && keyOption === data.lga) {
        array.push({ label: data.community_name, value: data._id });
      }
    });

  const onSelectChange = vals => {
    if (vals === null) return;
    const { label, value } = vals;
    setSelectedOption3(label);
    setKeyOption3(value);
  };

  //To remove error message from field
  useEffect(() => {
    if (selectedOption) {
      setError("");
    }
    if (soaselectedOption) {
      setError2("");
    }
    if (selectedOption3) {
      setSelectComboxErrorOption(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soaselectedOption, selectedOption, selectedOption3]);

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    if (selectedOption === "" || selectedOption === undefined) return setError("Please select LGA");
    if (soaselectedOption === "" || soaselectedOption === undefined) return setError2("Please select state");
    if (selectedOptionPaymentFrequency === "" || soaselectedOption === undefined)
      return setErrorPaymentFrequency("Please select a Payment Frequency");
    if (selectedOption3 === "" || selectedOption3 === undefined) return setSelectComboxErrorOption(true);
    const data = {
      ...vals,
      id: id,
      house_state: soaselectedOption,
      house_lga: selectedOption,
      house_community: selectedOption3,
      house_state_id: soakeyOption,
      house_lga_id: keyOption,
      house_community_id: keyOption3,
      payment_frequency: selectedOptionPaymentFrequency
    };
    dispatch(
      editHousehold(data, id, () => {
        dispatch(getSingleHousehold(id));
        history.push("/household");
      })
    );
  };

  const mobile = useCheckMobileScreen();
  let selectRef = null as any;

  const clearValue = () => {
    selectRef.select.clearValue();
  };
  return (
    <div className="edit-household-page">
      <div className="container">
        <div className="col-lg-8 col-md-12 pl-0 mb-4">
          <h4 className="page-title mt-5 ">{householdName} HouseHold Information</h4>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row  profile-form">
              <div className="col-lg-6 col-md-12 col-sm-12 form-details">
                <Textbox
                  type="text"
                  label="Household ID"
                  name="id"
                  placeholder="Household ID"
                  boxClasses="mt-3"
                  customRef={register({
                    required: true
                  })}
                  error={errors.id && "This field is required"}
                />

                <Textbox
                  label="Peak Milk Period"
                  name="peak_milk_period"
                  placeholder="Peak Milk Period"
                  boxClasses="mt-top"
                  type="text"
                  customRef={register({
                    required: true
                  })}
                  error={errors.peak_milk_period && "This field is required"}
                />
                <Textbox
                  name="average_milk_per_day"
                  label="Average Milk"
                  placeholder="Average Milk Per Day"
                  boxClasses="mt-top"
                  type="number"
                  customRef={register({
                    required: true
                  })}
                  error={errors.average_milk_per_day && "This field is required"}
                />

                <Textbox
                  label="Business Type"
                  name="business_type"
                  placeholder="Business Type"
                  boxClasses="mt-top"
                  type="text"
                  customRef={register({
                    required: true
                  })}
                  error={errors.business_type && "This field is required"}
                />
                <Textbox
                  label="lean Milk Period"
                  name="lean_milk_period"
                  placeholder="lean Milk Period"
                  boxClasses="mt-top"
                  type="text"
                  customRef={register({
                    required: true
                  })}
                  error={errors.lean_milk_period && "This field is required"}
                />
                {/* <Textbox
                                    label="Payment Frequency"
                                    name="payment_frequency"
                                    placeholder="Payment Frequency"
                                    boxClasses="mt-top"
                                    type="text"
                                    customRef={register()}
                                    disabled={true}
                                /> */}
                <ArrowDropdown
                  text="Payment Frequency"
                  options={payFrequency}
                  handleDropdownChange={handleDropdownChangePaymentFrequency}
                  handleAction={handleActionPaymentFrequency}
                  selectedOption={selectedOptionPaymentFrequency}
                  open={openPaymentFrequency}
                  ref={dropdownRefPaymentFrequency}
                  boxClasses="mt-top-2"
                />
                <p className={`${errorPaymentFrequency ? "d-block" : "d-none"} error mt-1`}>{errorPaymentFrequency}</p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pl-lg-5 pl-xs-0 pr-0 form-details ">
                <Textbox
                  label="Household Name"
                  name="house_name"
                  placeholder="Household Name"
                  boxClasses="mt-3"
                  type="text"
                  customRef={register({
                    required: true
                  })}
                  error={errors.house_name && "This field is required"}
                />
                <Textbox
                  label="House Size"
                  name="house_size"
                  placeholder="House Size"
                  boxClasses="mt-top"
                  type="text"
                  customRef={register({
                    required: true
                  })}
                  error={errors.house_size && "This field is required"}
                />

                <ArrowDropdown
                  text="Select State"
                  options={stateList}
                  handleDropdownChange={soahandleDropdownChange}
                  handleAction={soahandleAction}
                  selectedOption={soaselectedOption}
                  handleToggle={soahandleToggle}
                  open={soaopen}
                  boxClasses="mt-top-2"
                  cb={() => {
                    setSelectedOption("");
                    setKeyOption("");
                    setSelectedOption3("");
                    setKeyOption3("");
                    clearValue();
                  }}
                  ref={soadropdownRef}
                />
                <p className={`${error2 ? "d-block" : "d-none"} error mt-1`}>{error2}</p>

                <ArrowDropdown
                  text="Select LGA"
                  options={lgaList}
                  handleDropdownChange={handleDropdownChange}
                  handleAction={handleAction}
                  selectedOption={selectedOption}
                  handleToggle={handleToggle}
                  open={open}
                  boxClasses="mt-top-2"
                  cb={() => {
                    setSelectedOption3("");
                    setKeyOption3("");
                    clearValue();
                  }}
                  ref={dropdownRef}
                />
                <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

                {/* <ArrowDropdown
                                    text="Community"
                                    options={communities}
                                    handleDropdownChange={handleDropdownChange3}
                                    handleAction={handleAction3}
                                    selectedOption={selectedOption3}
                                    open={open3}
                                    boxClasses="mt-top-2"
                                />
                                <p className={`${error3 ? "d-block" : "d-none"} error mt-1`}>{error3}</p> */}

                {singleHouseHolds && singleHouseHolds !== null && (
                  <SelectBox
                    options={array}
                    placeholder="Select Community"
                    boxClasses="mt-top-2"
                    onChange={onSelectChange}
                    legend={false}
                    isDisabled={isLoading}
                    error={selectComboxErrorOption ? "This field is required" : ""}
                    customRef={ref => {
                      selectRef = ref;
                    }}
                    defaultValue={
                      singleHouseHolds !== null
                        ? {
                            value: singleHouseHolds?.household?.house_community_id,
                            label: singleHouseHolds?.household?.house_community
                          }
                        : null
                    }
                  />
                )}
                {!singleHouseHolds && singleHouseHolds === null && (
                  <SelectBox
                    options={array}
                    placeholder="Select Community"
                    boxClasses="mt-top-2"
                    legend={false}
                    isDisabled={true}
                  />
                )}

                {/* <Textbox label="LGA of Origin" placeholder="LGA of Origin" boxClasses="mt-top" />
                                <Textbox
                                    label="Bank Verification Number"
                                    placeholder="Bank Verification Number"
                                    boxClasses="mt-top"
                                />
                                <Textbox label="Address" placeholder="Address" boxClasses="mt-top" />
                                <Textbox label="Collection Point" placeholder="Collection Point" boxClasses="mt-top" /> */}
              </div>
              <Button
                label="Update Household"
                btnType="btn-primary"
                boxClasses={`mt-5 w-100 ${mobile ? "pl-0" : "pl-3"}`}
                btnStyle="100%"
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditHouseHold;
