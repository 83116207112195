import { toastr } from 'react-redux-toastr';
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { FarmerState, SingleFarmerAction, FarmerAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: FarmerState = {
    isLoading: false,
    singleFarmer: null,
    farmers: null
};

const farmerSlice = createSlice({
    name: "farmer",
    initialState,
    reducers: {
        getSingleFarmerLoading: (state) => {
            state.isLoading = true;
        },
        getSingleFarmerSuccess: (state, action: PayloadAction<SingleFarmerAction>) => {
            state.isLoading = false;
            state.singleFarmer = action.payload;
        },
        getSingleFarmerFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        getFarmersLoading: (state) => {
            state.isLoading = true;
        },
        getFarmersSuccess: (state, action: PayloadAction<FarmerAction>) => {
            state.isLoading = false;
            state.farmers = action.payload;
        },
        getFarmersFail: (state) => {
            state.isLoading = false;
        },
        editFarmerLoading: (state) => {
            state.isLoading = true;
        },
        editFarmerSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        editFarmerFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = farmerSlice;

export const {
    getSingleFarmerLoading,
    getSingleFarmerSuccess,
    getSingleFarmerFail,
    getFarmersFail,
    getFarmersLoading,
    getFarmersSuccess,
    editFarmerFail,
    editFarmerLoading,
    editFarmerSuccess
} = actions;

export const getSingleFarmer = (id): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleFarmerLoading());
    try {
        const response = await axios.get(`${BASE_URL}/farmer/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getSingleFarmerSuccess(response.data.farmer));
        }
    } catch (error) {
        dispatch(getSingleFarmerFail(error?.response?.data?.message));
    }
};

export const getFarmers = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string,
    Farmer_id?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getFarmersLoading());
    try {
        const response = await axios.get(`${BASE_URL}/farmer/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy,
                Farmer_id
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getFarmersSuccess(response.data));
        }
    } catch (error) {
        dispatch(getFarmersFail());
    }
};

export const editFarmer = (data: any, id, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editFarmerLoading());
    try {
        const response = await axios.post(`${BASE_URL}/farmer/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editFarmerSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editFarmerFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
