import { toastr } from "react-redux-toastr";

//Format value with comma and add Naira sign
export const toCurrency = (number: number): any => {
    const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN"
    });

    return formatter.format(number);
};

// Format number to thousandth(k) or millionth(m)
export const kFormatter = (num: any, amount: string) => {
    if (999 < num && num < 1000000) {
        return amount + Math.sign(num) * ((Math.abs(num) / 1000) as any).toFixed(1) + "k";
    } else if (num >= 1000000) {
        return amount + Math.sign(num) * ((Math.abs(num) / 1000000) as any).toFixed(1) + "m";
    } else {
        let value;
        value = Math.sign(num) * Math.abs(num);
        if (isNaN(value)) value = "";
        return value;
    }
};

//check user permission
export const checkPermission = (permissions: any, permission: string): boolean => {
    if (!permissions?.includes(permission)) {
        toastr.error("", "You don't have the Admin rights to do this");
        return false;
    } else {
        return true;
    }
};

export const formatCurrency = (amount: string): string => {
    return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

//toast PopUp
export const toastPopUp = (
    cb: () => void,
    okText?: string,
    cancelText?: string,
    blockName?: string,
    blockText?: string,
    color?: string
): void => {
    const toastrConfirmOptions = {
        onOk: () => cb(),
        okText: okText,
        cancelText: cancelText,
        component: () => (
            <div
                style={{
                    padding: "1rem 2rem",
                    position: "relative",
                    display: "flex"
                }}
            >
                <p style={{ color: `${color}`, marginLeft: "auto", marginRight: "auto" }}>
                    {blockName} {blockText!.toUpperCase()}
                </p>
            </div>
        )
    };
    toastr.confirm(null, toastrConfirmOptions);
};

export const formatNumber = (value: string | number): string => {
    return value.toLocaleString();
};
