import React, { useState } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { payBulk, getTransactions, getSummary } from "store/slice/TransactionSlice/TransactionSlice";
import { RootState } from "store/store";
import { format } from "date-fns";
import { loginFail, loginRequest } from "store/slice/AuthSlice/AuthSlice";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";

type Props = {
  closeModal: () => void;
  search: string;
  dateRange: any;
  bulkPayData: object;
};
const BulkPay = ({ closeModal, search, dateRange, bulkPayData }: Props) => {
  type FormData = {
    email: string;
    password: string;
  };
  const [defaultDate, setDefaultDate] = useState(false);
  const { register, errors, watch, handleSubmit } = useForm<FormData>({
    mode: "onBlur"
  });
  const val = watch();
  //Redux Store
  const dispatch = useDispatch();
  const { payingBulk } = useSelector((state: RootState) => state.transaction);
  const { user } = useSelector((state: RootState) => state.user);
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.auth);
  const [showLogin, setShowLogin] = useState(false);

  //IThis is to check if the datePicker is at default state (which is new Date), If it's not show the selected Date in the modal
  React.useEffect(() => {
    const { startDate } = dateRange;
    if (
      startDate.toDateString() === new Date().toDateString() &&
      endDate.toDateString() === new Date().toDateString()
    ) {
      setDefaultDate(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  React.useEffect(() => {
    setShowLogin(false);
    dispatch(loginFail(""));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PayBulk = () => {
    let start: any = format(new Date(dateRange.startDate), "yyy-MM-dd");
    let end: any = format(new Date(dateRange.endDate), "yyy-MM-dd");

    if (defaultDate) {
      start = undefined;
      end = undefined;
    }
    const data = {
      ...bulkPayData,
      dateFrom: start,
      dateTo: end,
      password: val.password
    };

    dispatch(
      payBulk(data, () => {
        closeModal();
        dispatch(getTransactions(1, 10));
        dispatch(getSummary());
      })
    );
  };

  const data = { email: user.email, password: val.password };
  const submitForm = () => {
    dispatch(loginRequest(data, "verify", () => PayBulk()));
  };

  const { startDate, endDate } = dateRange;
  return (
    <Modal modalText="Confirm Bulk Payment" onClose={closeModal}>
      {!showLogin && (
        <div className="role-modal trans">
          {defaultDate ? (
            <p className="">
              This action will pay all unpaid farmers from your Eyowo account. Are you sure you want to continue?
            </p>
          ) : (
            <p className="">
              This action will pay all unpaid farmers on the {`${startDate.toDateString()} - ${endDate.toDateString()}`}{" "}
              from your Eyowo account. Are you sure you want to continue?
            </p>
          )}

          <form className="mt-4">
            <Button
              label="Yes I'll Pay all"
              btnType="btn-primary"
              boxClasses="mt-4 mb-3"
              btnStyle="100%"
              onClick={() => setShowLogin(true)}
              loading={payingBulk || isLoading}
              type="button"
            />
            <Button
              label="Cancel"
              btnType="btn-primary"
              boxClasses="mb-5"
              xtraClass="xtraClass"
              btnStyle="100%"
              onClick={closeModal}
            />
          </form>
        </div>
      )}
      {showLogin && (
        <div className="trans">
          <form onSubmit={handleSubmit(submitForm)}>
            <p className="pb-4">Enter Password to confirm BULK PAYMENT</p>
            <Textbox
              type="password"
              label="Password"
              placeholder="Enter Password"
              name="password"
              error={errors.password && "Please enter Password"}
              boxClasses="pb-2"
              customRef={register({
                required: true
              })}
            />
            <div className="mb-4">{errorMessage !== "" && <ErrorMesssage message={errorMessage} />}</div>
            <Button label="Submit" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      )}
    </Modal>
  );
};

export default BulkPay;
