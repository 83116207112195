import React from "react";
import Button from "../../components/Button/index";
import "./styles.scss";
import { format } from "date-fns";

export const dispatchColumn = [
  {
    name: "Date",
    // index: "date",
    id: 1,
    render: item => (
      <div className="span-container">
        <p className="date">{format(new Date(item.date), "do LLL, yy")}</p>
        <p className="time pl-3">{format(new Date(item.date), "hh:mmaaa")}</p>
      </div>
    )
  },
  {
    name: "Collection Point",
    index: "collectionPoint",
    id: 2
  },
  {
    name: "Produce",
    index: "produce",
    id: 3
  },
  {
    name: "Quantity",
    index: "quantity",
    id: 4
  },
  {
    name: "Community",
    index: "community",
    id: 5
  },
  {
    name: "Status",
    id: 6,
    render: ({ modal, status, id }) => {
      let btnClass = "button-position";
      if (status === "unconfirmed") {
        btnClass = "button-position unpaid";
      }
      return (
        <Button
          onClick={() => status === "unconfirmed" && modal(id)}
          label={status}
          btnType="btn-secondary"
          boxClasses="button-container"
          xtraClass={btnClass}
          btnStyle="8rem"
          name="button"
        />
      );
    }
  }
];
