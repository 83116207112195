import { toastr } from "react-redux-toastr";
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import {
    HouseHoldState,
    Summary,
    HouseHoldAction,
    SingleHouseHoldAction,
    SingleHouseHoldSummaryAction
} from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: HouseHoldState = {
    isLoading: false,
    gettingAllhousehold: false,
    summary: null,
    households: null,
    singleHouseHolds: null,
    gettingSingleHouseholdSummary: false,
    singleHouseholdSummary: null
};

const householdSlice = createSlice({
    name: "household",
    initialState,
    reducers: {
        householdSummaryLoading: (state) => {
            state.isLoading = true;
        },
        householdSummarySuccess: (state, action: PayloadAction<Summary>) => {
            state.isLoading = false;
            state.summary = action.payload;
        },
        householdSummaryFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },

        allHouseholdLoading: (state) => {
            state.gettingAllhousehold = true;
        },
        allHouseholdSuccess: (state, action: PayloadAction<HouseHoldAction>) => {
            state.gettingAllhousehold = false;
            state.households = action.payload;
        },
        allHouseholdFail: (state, action: PayloadAction<string>) => {
            state.gettingAllhousehold = false;
        },
        singleHouseholdLoading: (state) => {
            state.gettingAllhousehold = true;
        },
        singleHouseholdSuccess: (state, action: PayloadAction<SingleHouseHoldAction>) => {
            state.gettingAllhousehold = false;
            state.singleHouseHolds = action.payload;
        },
        singleHouseholdFail: (state, action: PayloadAction<string>) => {
            state.gettingAllhousehold = false;
        },
        singleHouseholdSummaryLoading: (state) => {
            state.gettingAllhousehold = true;
        },
        singleHouseholdSummarySuccess: (state, action: PayloadAction<SingleHouseHoldSummaryAction>) => {
            state.gettingAllhousehold = false;
            state.singleHouseholdSummary = action.payload;
        },
        singleHouseholdSummaryFail: (state, action: PayloadAction<string>) => {
            state.gettingAllhousehold = false;
        },
        editHouseholdLoading: (state) => {
            state.isLoading = true;
        },
        editHouseholdSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        editHouseholdFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = householdSlice;

export const {
    singleHouseholdSummaryLoading,
    singleHouseholdSummarySuccess,
    singleHouseholdSummaryFail,
    singleHouseholdFail,
    singleHouseholdSuccess,
    singleHouseholdLoading,
    householdSummaryLoading,
    householdSummarySuccess,
    householdSummaryFail,
    allHouseholdLoading,
    allHouseholdSuccess,
    allHouseholdFail,
    editHouseholdFail,
    editHouseholdLoading,
    editHouseholdSuccess
} = actions;

export const getHouseholdSummary = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(householdSummaryLoading());
    try {
        const response = await axios.get(`${BASE_URL}/household/summary`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(householdSummarySuccess(response.data.data));
        }
    } catch (error) {
        dispatch(householdSummaryFail(""));
    }
};

export const getAllHousehold = (
    page?: number,
    perpage?: number,
    search?: string,
    payFrequency?: string,
    community?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    dispatch(allHouseholdLoading());
    const { userToken } = getState().user;
    try {
        const response = await axios.get(`${BASE_URL}/household/get`, {
            params: {
                page,
                perpage,
                search,
                payFrequency,
                community
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(allHouseholdSuccess(response.data));
        }
    } catch (error) {
        dispatch(allHouseholdFail(""));
    }
};

export const getSingleHousehold = (id: string, page?: number, perPage?: number, search?: string): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    dispatch(singleHouseholdLoading());
    const { userToken } = getState().user;
    try {
        const response = await axios.get(`${BASE_URL}/household/get/${id}`, {
            params: {
                page,
                perPage,
                search
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(singleHouseholdSuccess(response.data));
        }
    } catch (error) {
        dispatch(singleHouseholdFail(error.response.data.message));
    }
};

export const getSingleHouseholdSummary = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    dispatch(singleHouseholdSummaryLoading());
    const { userToken } = getState().user;
    try {
        const response = await axios.get(`${BASE_URL}/household/summary/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(singleHouseholdSummarySuccess(response.data.households));
        }
    } catch (error) {
        dispatch(singleHouseholdSummaryFail(error.response.data.message));
    }
};

export const editHousehold = (data: any, id, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editHouseholdLoading());
    try {
        const response = await axios.post(`${BASE_URL}/household/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(editHouseholdSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editHouseholdFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
