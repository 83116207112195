import Button from "components/Button";
import Textbox from "components/Textbox";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editFarmer, getSingleFarmer } from "store/slice/FarmerSlice/FarmerSlice";
import { RootState } from "store/store";
import { toastr } from "react-redux-toastr";
import "./styles.scss";

type Props = {};
const OtherInfo: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const { singleFarmer, isLoading } = useSelector((state: RootState) => state.farmer);
  const { permissions } = useSelector((state: RootState) => state.user);
  const { register, setValue, handleSubmit } = useForm<FormData>();

  //-------------------------------------------------------------------
  let { userId }: any = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    dispatch(getSingleFarmer(userId));
  }, []);

  useEffect(() => {
    if (singleFarmer !== null) {
      setValue("kin", singleFarmer?.kin);
      setValue("kin_contact", singleFarmer?.kin_contact);
      setValue("cooperative_group", singleFarmer?.cooperative_group);
    }
  }, [singleFarmer]);

  const submit = vals => {
    if (!permissions?.includes("FARMER_EDIT")) {
      toastr.error("", "You don't have the Admin rights to do this");
      return;
    }
    if (isLoading) return;
    dispatch(
      editFarmer(vals, userId, () => {
        dispatch(getSingleFarmer(userId));
      })
    );
  };

  return (
    <div id="other-info-page">
      <div className="col-xs-12 pt-4">
        <h4 className="mb-4">Other Information</h4>
        <form onSubmit={handleSubmit(submit)}>
          <Textbox
            label="Next of Kin"
            placeholder="Next of Kin"
            boxClasses="mb-3 input-width"
            name="kin"
            customRef={register({})}
            disabled={isLoading}
          />
          <Textbox
            label="Next of Kin contact"
            placeholder="Next of Kin contact"
            boxClasses="mb-3 input-width"
            name="kin_contact"
            customRef={register({})}
            disabled={isLoading}
          />
          <Textbox
            label="Cooperative group"
            placeholder="Next of Kin contact"
            boxClasses="mb-3 input-width"
            name="cooperative_group"
            customRef={register({})}
            disabled={isLoading}
          />
          <Button
            label="Save Changes"
            btnType="btn-primary"
            boxClasses="mt-5 w-100 input-width"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default OtherInfo;
