import React from "react";
import Avatar from "assests/images/avatar.png";
import Button from "../../components/Button/index";
import { Link } from "react-router-dom";
import { formatCurrency, formatNumber } from "utils/utils";

export const farmersColumn = [
  {
    name: "",
    index: "",
    id: 1,
    render: (item: { image: string | undefined }) => (
      <span>
        <img src={item.image} className="avatar" alt="user_img" />
      </span>
    )
  },
  {
    name: "Name",
    index: "name",
    id: 2
  },
  {
    name: "Produce",
    index: "produce",
    id: 3
  },
  {
    name: "Quantity",
    render: (item: { totalQuantity: string | number }) => <>{formatNumber(item.totalQuantity)}</>,
    id: 4
  },
  {
    name: "Amount",
    id: 5,
    render: (item: { totalAmount: string }) => <p>{formatCurrency(item.totalAmount)}</p>
  },
  {
    name: "LGA",
    index: "lga",
    id: 6
  },
  {
    name: "Community",
    index: "community",
    id: 7
  },
  {
    name: "Actions",
    id: 8,
    render: (item: { farmer_id: any }) => (
      <Link to={`/household/farmer/${item?.farmer_id}`}>
        <Button label="View" btnType="btn-secondary" boxClasses="button-container" xtraClass="button-position" />
      </Link>
    )
  }
];
export const CommunityColumn = [
  {
    name: "Community ",
    index: "community",
    id: 1
  },
  {
    name: "Produce",
    index: "produce",
    id: 2
  },
  {
    name: "Quantity",
    render: (item: { totalQuantity: string | number }) => <>{formatNumber(item.totalQuantity)}</>,
    id: 3
  },
  {
    name: "Amount",
    id: 4,
    render: (item: { totalAmount: string }) => <p>{formatCurrency(item.totalAmount)}</p>
  },
  {
    name: "LGA",
    index: "lga",
    id: 5
  }
];
