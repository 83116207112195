import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";
import { getLga } from "store/slice/LgaSlice/LgaSlice";
import { getStates } from "store/slice/StateSlice/StateSlice";
import { createCommunity, getCommunity, editCommunity } from "store/slice/CommunitySlice/communitySlice";
import { CreateCommunityPayload } from "store/slice/CommunitySlice/interface";

type Props = {
  closeModal: () => void;
  item?: object;
};
const CreateOrEditCommunity = ({ closeModal, item = {} }: Props) => {
  //State
  const [commStates, setCommStates] = useState<any>([]);
  const [commLga, setCommLga] = useState<any>([]);
  //Redux Store
  const dispatch = useDispatch();
  // const { errorMessage } = useSelector((state: RootState) => state.dispatchEmployee);
  const { states } = useSelector((state: RootState) => state.stateReducer);
  const { lga } = useSelector((state: RootState) => state.lga);
  const { isLoading, errorMessage } = useSelector((state: RootState) => state.community);

  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  const [error, setError] = React.useState("");
  const [error2, setError2] = React.useState("");

  //LGA Hooks
  const {
    open,
    selectedOption,
    handleDropdownChange,
    handleAction,
    handleToggle,
    setSelectedOption,
    keyOption,
    dropdownRef,
    setKeyOption
  } = useArrowDropdown();

  //States Hook
  const {
    open: soaopen,
    selectedOption: soaselectedOption,
    handleDropdownChange: soahandleDropdownChange,
    handleAction: soahandleAction,
    handleToggle: soahandleToggle,
    setSelectedOption: soasetSelectedOption,
    keyOption: soakeyOption,
    dropdownRef: soadropdownRef,
    setKeyOption: soasetKeyOption
  } = useArrowDropdown();

  //Get lga's and States
  useEffect(() => {
    dispatch(getLga(1, 1000));
    dispatch(getStates(1, 1000));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // state data
    const stateList =
      states &&
      states?.state?.map((data: any) => {
        return {
          name: data.state_name,
          id: data._id
        };
      });
    setCommStates(stateList);
  }, [states]);

  useEffect(() => {
    let lgaList = [] as any;
    lga &&
      lga?.lga?.map((data: any) => {
        if (soakeyOption === data.state) {
          lgaList.push({ name: data.lga_name, id: data._id });
        }
      });

    setCommLga(lgaList);
  }, [lga, soakeyOption, item]);

  //To remove error message from field
  useEffect(() => {
    if (selectedOption) {
      setError("");
    }
    if (soaselectedOption) {
      setError2("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soaselectedOption, selectedOption]);

  useEffect(() => {
    if (item && item !== {}) {
      setValue("community_name", item["community_name"]);
      setValue("community_code", item["community_code"]);
      if (commStates) {
        soasetKeyOption(item["state"]);
        commStates.forEach(role => {
          if (role.id === item["state"]) {
            soasetSelectedOption(role.name);
          }
        });
      }

      if (commLga) {
        commLga.forEach(lga => {
          if (lga.id === item["lga"]) {
            setSelectedOption(lga.name);
          }
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, states, lga]);

  type FormData = {
    community_name: string;
    community_code: string;
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;

    if (soaselectedOption === "") {
      setError2("Please select a state");
      return;
    }

    if (selectedOption === "") {
      setError("Please select a lga");
      return;
    }

    const data: CreateCommunityPayload = {
      community_name: vals.community_name,
      community_code: vals.community_code,
      lga_id: keyOption,
      state_id: soakeyOption
    };

    const editData: CreateCommunityPayload = {
      community_name: vals.community_name,
      community_code: vals.community_code,
      lga_id: keyOption === "" ? item["lga"] : keyOption,
      state_id: soakeyOption === "" ? item["state"] : soakeyOption
    };

    if (item && item["community_name"]) {
      dispatch(
        editCommunity(editData, item["id"], () => {
          closeModal();
          dispatch(getCommunity(1, 10));
        })
      );
    } else {
      dispatch(
        createCommunity(data, () => {
          closeModal();
          dispatch(getCommunity(1, 10));
        })
      );
    }
  };

  return (
    <Modal
      modalText={item && item["community_name"] ? "Edit Community" : "Create Community"}
      onClose={closeModal}
      boxClasses=""
    >
      <div className="role-modal">
        <form className="" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            label="Community Name"
            placeholder="Enter Community name"
            name="community_name"
            error={errors.community_name && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="Community Code."
            placeholder="Enter Community code."
            name="community_code"
            error={errors.community_code && "This field is required"}
            customRef={register({
              required: true
            })}
            boxClasses="mt-3"
          />
          <ArrowDropdown
            text="Select State"
            options={commStates}
            handleDropdownChange={soahandleDropdownChange}
            handleAction={soahandleAction}
            selectedOption={soaselectedOption}
            handleToggle={soahandleToggle}
            open={soaopen}
            boxClasses="mt-4"
            ref={soadropdownRef}
            cb={() => {
              setSelectedOption("");
              setKeyOption("");
            }}
          />
          <p className={`${error2 ? "d-block" : "d-none"} error mt-1`}>{error2}</p>
          <ArrowDropdown
            text="Select LGA"
            options={commLga}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            handleToggle={handleToggle}
            open={open}
            boxClasses="mt-4"
            ref={dropdownRef}
          />
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

          {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
          <Button
            label={item && item["community_name"] ? "Edit Community" : "Create Community"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default CreateOrEditCommunity;
