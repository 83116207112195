import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "store/slice/AuthSlice/AuthSlice";
import userReducer from "store/slice/UserSlice/UserSlice";
import userMgtReducer from "store/slice/UserMgtSlice/UserMgtSlice";
import inventoryReducer from "store/slice/InventorySlice/InventorySlice";
import dashboardReducer from "store/slice/DashboardSlice/DashboardSlice";
import householdReducer from "../slice/HouseHoldsSlice/HouseholdSlice";
import transactionReducer from "../slice/TransactionSlice/TransactionSlice";
import produceReducer from "store/slice/ProduceSlice/ProduceSlice";
import farmReducer from "store/slice/FarmerSlice/FarmerSlice";
import loanReducer from "store/slice/LoanSlice/loanSlice";
import roleReducer from "store/slice/RoleSlice/RoleSlice";
import dispatchReducer from "store/slice/DispatchSlice/DispatchSlice";
import accountReducer from "store/slice/AccountSlice/accountSlice";
import collectionPointReducer from "store/slice/CollectionPointSlice/CollectionPointSlice";
import dispatchProviderReducer from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import dispatchEmployeeReducer from "store/slice/DispatchEmployeeSlice/DispatchEmployeeSlice";
import communityReducer from "store/slice/CommunitySlice/communitySlice";
import lgaReducer from "store/slice/LgaSlice/LgaSlice";
import stateReducer from "store/slice/StateSlice/StateSlice";
import uiReducer from "store/slice/UISlice/UISlice";
import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    userMgt: userMgtReducer,
    inventory: inventoryReducer,
    dashboard: dashboardReducer,
    household: householdReducer,
    transaction: transactionReducer,
    produce: produceReducer,
    farmer: farmReducer,
    loans: loanReducer,
    roles: roleReducer,
    dispatchReducer,
    points: collectionPointReducer,
    account: accountReducer,
    dispatchProvider: dispatchProviderReducer,
    dispatchEmployee: dispatchEmployeeReducer,
    toastr: toastrReducer,
    community:communityReducer,
    lga:lgaReducer,
    stateReducer:stateReducer,
    ui: uiReducer
});

export default rootReducer;
