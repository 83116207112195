import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import "./styles.scss";
import { useForm } from "react-hook-form";
import StarRating from "components/Star";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { createInventory, getProduceChart } from "store/slice/InventorySlice/InventorySlice";
import { getManufacturingSummary, getManufacturingTransactions } from "store/slice/TransactionSlice/TransactionSlice";
import { CreateInventoryPayload } from "store/slice/InventorySlice/interface";

type Props = {
  closeModal: () => void;
  produceID: string;
  produceName: string;
};
const AddProduce = ({ closeModal, produceID, produceName }: Props) => {
  //State
  const [error, setError] = React.useState("");
  const [starRating, setStarRating] = useState<number | null>(null);

  //Redux Store
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.inventory);

  //Hook
  const { register, handleSubmit, errors, setValue } = useForm<FormData>();

  type FormData = {
    produce: string;
    quantity: number;
  };

  const handleStarRating = (rating: any) => {
    setStarRating(rating);
  };

  const onFormSubmit = (vals: FormData) => {
    if (starRating === null) {
      setError("Please give rating");
      return;
    }

    let data = vals;
    data.produce = produceID;
    const { ...rest } = data;
    const payload: CreateInventoryPayload = { ...rest, rating: starRating };

    dispatch(
      createInventory(payload, () => {
        closeModal();
        dispatch(getManufacturingTransactions(1, 10, undefined, produceID));
        dispatch(getManufacturingSummary(produceID));
        dispatch(getProduceChart(produceID));
      })
    );
  };

  useEffect(() => {
    if (produceID) {
      setValue("produce", produceName);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Remove error message
  useEffect(() => {
    if (starRating) {
      setError("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starRating]);
  return (
    <Modal modalText="Add Produce" onClose={closeModal}>
      <div id="add-produce-modal">
        <p className="">Complete this form with accurate data to add produce manufactured</p>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="produce"
            label="Produce Name"
            customRef={register({
              required: true
            })}
            placeholder="Produce Name"
            disabled={true}
          />
          <Textbox
            type="number"
            label="Produce Quantity"
            name="quantity"
            placeholder="Produce Quantity"
            boxClasses="mt-3"
            error={errors.quantity && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <p className="rating-text mt-4">How would you rate the produce quality?</p>
          <div className="star-div mt-3">
            <StarRating disabled={false} stars={0} onChange={handleStarRating} starClass="star-class" />
          </div>
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          <Button label="Submit" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default AddProduce;
