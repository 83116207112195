import React, { useEffect } from "react";
import DataTable from "components/DataTable";
import "./styles.scss";
import { transactionData, transactionDataColumn } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "store/slice/TransactionSlice/TransactionSlice";
import { useParams } from "react-router-dom";
import { RootState } from "store/store";

type Props = {};
const TransactionData: React.FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();
  let { userId }: any = useParams<Record<string, string | undefined>>();

  //-------------------------------------------------------------
  useEffect(() => {
    const farmerId = userId;
    dispatch(getTransactions(undefined, undefined, undefined, undefined, undefined, farmerId));
  }, []);

  //-------------------------------------------------------------
  const { transactions } = useSelector((state: RootState) => state.transaction);
  return (
    <div id="transaction-data-page">
      <div className="col-xs-12 pt-4">
        <h4 className="mb-4">Transaction Data</h4>
        <DataTable columns={transactionDataColumn} data={transactions?.transactions} />
      </div>
    </div>
  );
};

export default TransactionData;
