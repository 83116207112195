import React, { useState, useCallback, useRef, useEffect } from "react";

const useArrowDropdown = (state?: any, setstate?: any) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [keyOption, setKeyOption] = useState("");

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    //closes the dropdown
    useEffect(() => {
        const hide = (e: any): void => {
            if (!dropdownRef!.current?.contains(e.target)) {
                setOpen(false); 
            }
        };
        window.addEventListener("mousedown", hide);
        return () => {
            window.removeEventListener("mousedown", hide);
        };
    }, []);

    const handleDropdownChange = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const handleAction = (name: string, id: string, cb?: () => void) => {
        setKeyOption(id);
        setOpen(false);
        setSelectedOption(name);
        cb && cb();
    };

    const handleToggle = (e: any) => {
        const { name } = e.target;

        let permission = state;
        permission.forEach((val: any) => {
            if (val.name === name) {
                val.isChecked = !val.isChecked;
            }
        });

        setstate([...permission]);
    };
    return {
        open,
        selectedOption,
        handleDropdownChange,
        handleAction,
        setSelectedOption,
        keyOption,
        handleToggle,
        setKeyOption,
        dropdownRef
    };
};

export default useArrowDropdown;
