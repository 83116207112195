import React, { useRef } from "react";
import { ReactComponent as Arrow } from "assests/icons/arrow.svg";
import { CSVLink } from "react-csv";
import "./styles.scss";

type Props = {
  header?: any[];
  filename?: string;
  data?: any[];
};
const GenerateReportButton = ({ header, filename, data = [] }: Props) => {
  //State
  const csvLink = useRef<any>(null);

  const downloadReport = (): void => {
    csvLink.current!.link.click();
  };

  return (
    <div className="generate-button" role="button" onClick={downloadReport}>
      <Arrow />
      <p>Generate Report</p>
      <CSVLink headers={header} filename={filename} data={data} ref={csvLink} />
    </div>
  );
};

export default GenerateReportButton;
