import { toastr } from 'react-redux-toastr';
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { LoanState, LoanAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: LoanState = {
    isLoading: false,
    loan: null,
    successMessage: "",
    errorMessage: ""
};

const loanSlice = createSlice({
    name: "loan",
    initialState,
    reducers: {
        getLoanLoading: (state) => {
            state.isLoading = true;
        },
        getLoanSuccess: (state, action: PayloadAction<LoanAction>) => {
            state.isLoading = false;
            state.loan = action.payload;
        },
        getLoanFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        createLoanLoading: (state) => {
            state.isLoading = true;
        },
        createLoanSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createLoanFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = loanSlice;

export const {
    getLoanLoading,
    getLoanSuccess,
    getLoanFail,
    createLoanFail,
    createLoanLoading,
    createLoanSuccess
} = actions;
export const getLoan = (page?: number, perpage?: number, search?: string, farmerId?: string): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(getLoanLoading());
    try {
        const response = await axios.get(`${BASE_URL}/loan/get`, {
            params: {
                page,
                perpage,
                search,
                farmerId
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getLoanSuccess(response.data));
        }
    } catch (error) {
        dispatch(getLoanFail(error?.response?.data?.message));
    }
};

export const createLoan = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createLoanLoading());
    try {
        const response = await axios.post(`${BASE_URL}/loan/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(createLoanSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createLoanFail(error.response.data.message));
        toastr.error(error.response.data.message);
    }
};

export default reducer;
