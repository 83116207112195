import React from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { createDispatch, getDispatchRecord } from "store/slice/DispatchSlice/DispatchSlice";
import { format } from "date-fns";
import { getProduce } from "store/slice/ProduceSlice/ProduceSlice";
import ArrowDropdown from "components/ArrowDropdown";
import useArrowDropdown from "hooks/useArrowDropdown";
import { getDispatchProviders } from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import { getCollectionPoint } from "store/slice/CollectionPointSlice/CollectionPointSlice";
import { getQuantity } from "store/slice/TransactionSlice/TransactionSlice";
import SelectBox from "components/SelectBox";
import { getDispatchEmployees } from "store/slice/DispatchEmployeeSlice/DispatchEmployeeSlice";

type Props = {
  closeModal: () => void;
  item?: any;
  value?: string;
};
const CreateDispatch = ({ closeModal, item, value }: Props) => {
  //State
  const [dropdownError, setDropdownError] = React.useState("");
  const [dropdownError2, setDropdownError2] = React.useState("");
  const [options, setOptions] = React.useState<any>([]);
  const [options2, setOptions2] = React.useState<any>([]);
  const [options3, setOptions3] = React.useState<any>([]);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const [selectComboxErrorOption, setSelectComboxErrorOption] = React.useState(false);
  const [selectComboxOption2, setSelectComboxOption2] = React.useState("");
  const [selectComboxErrorOption2, setSelectComboxErrorOption2] = React.useState(false);
  const [selectComboxOption3, setSelectComboxOption3] = React.useState("");
  const [selectComboxErrorOption3, setSelectComboxErrorOption3] = React.useState(false);
  const [collectionCommunity, setcollectionCommunity] = React.useState({
    community_name: null,
    community_id: null
  });

  //Hook
  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, setSelectedOption, dropdownRef } =
    useArrowDropdown();
  const { selectedOption: soaselectedOption } = useArrowDropdown();

  //Redux Store
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.dispatchReducer);
  const { allProduce } = useSelector((state: RootState) => state.produce);
  const { dispatchProvider } = useSelector((state: RootState) => state.dispatchProvider);
  const { allCollectionPoint } = useSelector((state: RootState) => state.points);
  const { dispatchEmployee } = useSelector((state: RootState) => state.dispatchEmployee);
  const { profile } = useSelector((state: RootState) => state.user);

  const { firstName, lastName } = profile;

  React.useEffect(() => {
    if (value && value === "filled") {
      setValue("date", `${format(new Date(item.date), "do LLL, yy")}`);
      setValue("time_logged", `${format(new Date(item.date), "hh:mmaaa")}`);
      item.dateConfirmed &&
        setValue(
          "date_confirmed",
          `${format(new Date(item.date), "do LLL, yy")} -- ${format(new Date(item.dateConfirmed), "hh:mmaaa")}`
        );
      setValue("collectionPoint", item.collectionPoint);
      setValue("community", item.community);
      setValue("produce", item.produce);
      setValue("quantity", item.quantity);
      setValue("status", item.status);
      setValue("collectionOfficer", item.collectionOfficer);
      setValue("dispatchEmployee", item.dispatchEmployee);
      setValue("dispatchProvider", item.dispatchProvider);
      setValue("additionalComments", item.additionalComments);
      setSelectedOption(item.produce);
    } else setValue("collectionOfficer", `${firstName} ${lastName}`);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Fetch produces
  React.useEffect(() => {
    dispatch(getProduce(1, 1000));
    dispatch(getDispatchProviders(1, 1000));
    dispatch(getCollectionPoint(1, 1000));
    dispatch(getDispatchEmployees(1, 1000));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  type FormData = {
    additionalComments: string;
    date: string;
    collectionPoint: string;
    produce: string;
    quantity: string;
    status: string;
    collectionOfficer: string;
    dispatchEmployee: string;
    dispatchProvider: string;
    produceId: string;
    date_confirmed: string;
    time_logged: string;
    community: string;
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    if (keyOption === "") {
      setDropdownError("Please select a Produce");
      return;
    }
    // if (soaselectedOption === "") {
    //     setDropdownError2("Please select a Category");
    //     return;
    // }

    if (selectComboxOption === "") {
      setSelectComboxErrorOption(true);
      return;
    }

    if (selectComboxOption2 === "") {
      setSelectComboxErrorOption2(true);
      return;
    }

    if (selectComboxOption3 === "") {
      setSelectComboxErrorOption3(true);
      return;
    }

    const data = {
      ...vals,
      produceId: keyOption,
      dispatchProvider: selectComboxOption,
      status: "unconfirmed",
      produce: selectedOption,
      collectionPoint: selectComboxOption2,
      collectionOfficer: `${firstName} ${lastName}`,
      dispatchEmployee: selectComboxOption3
    };
    dispatch(
      createDispatch(data, () => {
        dispatch(getDispatchRecord(1, 10));
        closeModal();
      })
    );
  };

  //List of Produce

  const produceList = [] as any;
  allProduce &&
    allProduce?.produces?.forEach(data => {
      if (data.category === "Supply Chain") {
        produceList.push({
          name: data.produce_name,
          id: data._id
        });
      }
    });

  //Remove error message
  React.useEffect(() => {
    if (keyOption) {
      setDropdownError("");
    }
    if (soaselectedOption) {
      setDropdownError2("");
    }

    if (selectComboxOption !== "") {
      setSelectComboxErrorOption(false);
    }

    if (selectComboxOption2 !== "") {
      setSelectComboxErrorOption2(false);
    }

    if (selectComboxOption3 !== "") {
      setSelectComboxErrorOption3(false);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption, selectComboxOption, soaselectedOption, selectComboxOption2, selectComboxOption3]);

  React.useEffect(() => {
    const array = [] as any;
    if (dispatchProvider && dispatchProvider) {
      dispatchProvider &&
        dispatchProvider?.provider.forEach(provider => {
          array.push({ label: provider.providerName, value: provider._id });
        });
    }
    setOptions([...array]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchProvider]);

  React.useEffect(() => {
    if (collectionCommunity.community_name !== null) {
      setValue("community", collectionCommunity.community_name);
      dispatch(getQuantity(collectionCommunity.community_id!, collectionCommunity.community_name!));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCommunity, selectComboxOption2]);

  React.useEffect(() => {
    const array = [] as any;
    if (allCollectionPoint && allCollectionPoint) {
      allCollectionPoint &&
        allCollectionPoint?.collections.forEach((collection: any) => {
          array.push({ label: collection.pointName, value: collection._id });
          if (selectComboxOption2 !== "") {
            if (selectComboxOption2 === collection.pointName) {
              setcollectionCommunity({
                ...collectionCommunity,
                community_name: collection.community_name,
                community_id: collection.community_id
              });
            }
          }
        });
    }
    setOptions2([...array]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCollectionPoint, selectComboxOption2]);

  React.useEffect(() => {
    const array = [] as any;
    if (dispatchEmployee && dispatchEmployee) {
      dispatchEmployee &&
        dispatchEmployee?.employees.forEach((employee: any) => {
          if (selectComboxOption === employee.dispatchProvider)
            array.push({ label: employee.employeeName, value: employee._id });
        });
    }
    setOptions3([...array]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchEmployee, selectComboxOption3, selectComboxOption]);

  let selectRef = null as any;

  const clearValue = () => {
    selectRef.select.clearValue();
    setSelectComboxOption3("");
  };

  const onSelectChange = (vals: { label: React.SetStateAction<string> }) => {
    setSelectComboxOption(vals.label);
    clearValue();
  };
  const onSelectChange2 = (vals: { label: React.SetStateAction<string> }) => {
    setSelectComboxOption2(vals.label);
  };
  const onSelectChange3 = (vals: { label: any } | null) => {
    if (vals === null) return;
    const { label } = vals;
    setSelectComboxOption3(label);
  };

  return (
    <Modal modalText="Dispatch" onClose={closeModal} modallength="modal-length">
      <div id="create-dispatch-modal">
        {value === "filled" && (
          <p className="">
            Here’s a detailed view of the transaction which took place on {format(new Date(item.date), "do LLL, yy")}
          </p>
        )}
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          {value === "filled" && (
            <Textbox
              type="string"
              name="date"
              label="Date"
              placeholder="Date"
              error={errors.date && "This field is required"}
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          )}
          {value === "filled" && (
            <Textbox
              type="string"
              name="time_logged"
              label="Time logged "
              boxClasses="mt-3"
              placeholder="Time logged"
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          )}
          {value === "filled" && item.dateConfirmed && (
            <Textbox
              type="string"
              name="date_confirmed"
              label="Date Confirmed"
              boxClasses="mt-3"
              placeholder="Date Confirmed"
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          )}

          {value === "filled" ? (
            <Textbox
              type="text"
              label="Collection Point"
              name="collectionPoint"
              placeholder="Collection Point"
              boxClasses={value === "filled" ? "mt-3" : "mt-1"}
              error={errors.collectionPoint && "This field is required"}
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          ) : (
            // <ComboTextBox
            //     options={options2}
            //     placeholder="Collection Point"
            //     boxClasses="mt-3"
            //     label="Collection Point"
            //     setSelectComboxOption={setSelectComboxOption2}
            //     setHighlightedOption={setHighlightedOption2}
            //     error={selectComboxErrorOption2 ? "This field is required" : ""}
            // />

            <SelectBox
              options={options2}
              placeholder="Collection Point"
              boxClasses="mt-3"
              label="Collection Point"
              onChange={onSelectChange2}
              legend={true}
              // disabled={farmerDetails && !farmerDetails["disable"]}
              error={selectComboxErrorOption2 ? "This field is required" : ""}
            />
          )}

          {selectComboxOption2 !== "" && (
            <Textbox
              type="string"
              name="community"
              label="Community"
              boxClasses="mt-3"
              placeholder="Community"
              customRef={register({
                required: true
              })}
              disabled={true}
            />
          )}
          {value === "filled" ? (
            <Textbox
              type="text"
              label="Produce"
              name="produce"
              placeholder="Produce"
              boxClasses="mt-3"
              error={errors.produce && "This field is required"}
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          ) : (
            <ArrowDropdown
              text="Select Produce"
              options={produceList}
              handleDropdownChange={handleDropdownChange}
              handleAction={handleAction}
              selectedOption={selectedOption}
              open={open}
              boxClasses=" mt-4"
              disabled={value === "filled"}
              ref={dropdownRef}
            />
          )}
          <p className={`${dropdownError ? "d-block" : "d-none"} error`}>{dropdownError}</p>
          <Textbox
            type={value === "filled" ? "text" : "number"}
            label="Quantity"
            name="quantity"
            placeholder="Quantity"
            boxClasses="mt-3"
            error={errors.quantity && "Must be a number"}
            customRef={register({
              required: true
            })}
            disabled={value === "filled"}
          />
          {value === "filled" && (
            <Textbox
              type="text"
              label="Status"
              name="status"
              placeholder="Status"
              boxClasses="mt-3"
              error={errors.status && "This field is required"}
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          )}
          {/* //     <>
                    //         <ArrowDropdown
                    //             text="Add Category"
                    //             options={status}
                    //             handleDropdownChange={soahandleDropdownChange}
                    //             handleAction={soahandleAction}
                    //             selectedOption={soaselectedOption}
                    //             open={soaOpen}
                    //             boxClasses="mt-4"
                    //             ref={soadropdownRef}
                    //         />
                    //         <p className={`${dropdownError2 ? "d-block" : "d-none"} error`}>{dropdownError2}</p>
                    //     </>
                    // )} */}
          <Textbox
            type="text"
            label="Collection Officer"
            name="collectionOfficer"
            placeholder="Collection Officer"
            boxClasses="mt-3"
            error={errors.collectionOfficer && "This field is required"}
            customRef={register({
              required: true
            })}
            disabled={true}
          />

          {value && value === "filled" ? (
            <Textbox
              type="text"
              label="Logistics Service Provider"
              name="dispatchProvider"
              placeholder="Logistics Service Provider"
              boxClasses="mt-3"
              error={errors.dispatchProvider && "This field is required"}
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          ) : (
            // <ComboTextBox
            //     options={options}
            //     placeholder="Logistics Service Provider"
            //     boxClasses="mt-3"
            //     label="Logistics Service Provider"
            //     setSelectComboxOption={setSelectComboxOption}
            //     setHighlightedOption={setHighlightedOption}
            //     error={selectComboxErrorOption ? "This field is required" : ""}
            // />

            <SelectBox
              options={options}
              placeholder="Logistics Service Provider"
              boxClasses="mt-3"
              label="Logistics Service Provider"
              onChange={onSelectChange}
              legend={true}
              // disabled={farmerDetails && !farmerDetails["disable"]}
              error={selectComboxErrorOption ? "This field is required" : ""}
            />
          )}
          {value && value === "filled" ? (
            <Textbox
              type="text"
              label="Service Provider Employee"
              name="dispatchEmployee"
              placeholder="Service Provider Employee"
              boxClasses="mt-3"
              error={errors.dispatchEmployee && "This field is required"}
              customRef={register({
                required: true
              })}
              disabled={value === "filled"}
            />
          ) : (
            <SelectBox
              options={options3}
              label="Service Provider Employee"
              name="dispatchEmployee"
              boxClasses="mt-3"
              placeholder="Service Provider Employee"
              onChange={onSelectChange3}
              legend={true}
              customRef={ref => {
                selectRef = ref;
              }}
              // disabled={farmerDetails && !farmerDetails["disable"]}
              error={selectComboxErrorOption3 ? "This field is required" : ""}
            />
          )}
          <Textbox
            type="text"
            label="Additional Comments"
            name="additionalComments"
            placeholder="Additional Comments"
            boxClasses="mt-3"
            customRef={register()}
            disabled={value === "filled"}
          />

          {value !== "filled" && (
            <Button label="Submit" btnType="btn-primary" boxClasses="mt-3 mb-5" btnStyle="100%" loading={isLoading} />
          )}
        </form>
      </div>
    </Modal>
  );
};

export default CreateDispatch;
