import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/Group_human.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { CommunityColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/community_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import { getCommunity, deleteCommunity } from "store/slice/CommunitySlice/communitySlice";
import { toastPopUp } from "utils/utils";
import CreateOrEditCommunity from "components/Modals/CreateOrEditCommunity";

type Props = {};

const Communities: React.FunctionComponent<Props> = () => {
    //State
    const [page, setPage] = React.useState<number>(1);
    const [perpage, setPerpage] = React.useState<number>(10);
    const [modal, setModal] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>("");
    const [editData, setEditData] = React.useState<object>({});

    //Hook
    const { search, inputRef, handleChange, searched } = useSearch(getCommunity, perpage);

    //Redux State
    const dispatch = useDispatch();
    const { community, isLoading } = useSelector((state: RootState) => state.community);

    React.useEffect(() => {
        dispatch(getCommunity(page, perpage));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // setpage number to 1 if searched is done
    React.useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    const edit = (id: string, community_name: string, community_code: string, lga: string, state: string) => {
        setModal(!modal);
        setEditData({
            id,
            community_name,
            community_code,
            lga,
            state
        });
    };

    const deleteCommunityHandler = (id: any, name: string) => {
        toastPopUp(
            () => {
                dispatch(
                    deleteCommunity(id, () => {
                        dispatch(getCommunity(page, perpage));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete Community",
            name,
            "red"
        );
    };

    const CommunityList =
        community &&
        community?.community?.map((data: any) => {
            return {
                community_name: data.community_name,
                community_code: data.community_code,
                edit: () => edit(data._id, data.community_name, data.community_code, data.lga, data.state),
                deleteCommunityHandle: () => deleteCommunityHandler(data._id, data.community_name),
                id: data._id
            };
        });

    //--------------------------------------------
    //Pagination
    //---------------------------------------------
    const handlePageChange = React.useCallback((page: number, perpage: number) => {
        setPage(page);
        dispatch(getCommunity(page, perpage, search));
    }, [search]);

    const paginate = React.useCallback((pageNo: number, perpage: number) => {
        setPage(pageNo);
        dispatch(getCommunity(pageNo, perpage, search));
    }, [search]);

    //------------------------------------------------------------
    //Show Number for PerPage Function
    //------------------------------------------------------------
    const handleNewPage = (pageNo: number, page = 1) => {
        setPerpage(pageNo);
        setPage(1);
        dispatch(getCommunity(page, pageNo, search));
    };

    //------------------------------------------------
    //modal toggler
    //------------------------------------------------
    const closeModal = () => {
        setModal(!modal);
        setValue("");
        setEditData({});
        // dispatch(createDispatchProviderFail(""));
    };

    return (
        <div className="community-page">
            {modal && <CreateOrEditCommunity closeModal={closeModal} item={editData} />}
            <div className="container">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={community?.pagination?.total || 0}
                        text="Community"
                        classes="stats-icon"
                        image={People}
                    />
                    <StatsCard image={Icon} classes="middle-img" centerText="Create Community" onClick={closeModal} />
                </div>
                <h4 className="page-title mb-2">Community Logs</h4>
                <div className="search-field">
                    <SearchIcon
                        placeholder="Search for Community name or Community code."
                        boxClasses=" mb-3"
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    />
                    <Dropdown perPage={perpage} action={handleNewPage} />
                </div>
                <DataTable columns={CommunityColumn} data={CommunityList} loading={isLoading} />
                <Pagination
                    page={page}
                    lastPage={community?.pagination?.lastPage}
                    paginate={paginate}
                    total={community?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perpage}
                />
            </div>
        </div>
    );
};

export default Communities;
