import React, { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  createCollectionPoint,
  createCollectionPointFail,
  getCollectionPoint,
  getSingleCollectionPoint,
  editCollectionPoint
} from "store/slice/CollectionPointSlice/CollectionPointSlice";
import ErrorMesssage from "components/ErrorMessage";
import useArrowDropdown from "hooks/useArrowDropdown";
import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import SelectBox from "components/SelectBox";

type Props = {
  closeModal: () => void;
  collectionId: string;
};
const CreateCollectionPoint = ({ closeModal, collectionId }: Props) => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onBlur"
  });
  const [error, setError] = React.useState("");
  const { errorMessage, isLoading, singleCollectionPoint } = useSelector((state: RootState) => state.points);
  const { community } = useSelector((state: RootState) => state.community);
  const [selectComboxOption, setSelectComboxOption] = React.useState({});
  const [selectComboxErrorOption, setSelectComboxErrorOption] = React.useState(false);
  const dispatch = useDispatch();
  const { keyOption } = useArrowDropdown();

  useEffect(() => {
    dispatch(createCollectionPointFail(""));
    dispatch(getCommunity(1, 1000));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    collectionId !== "" && dispatch(getSingleCollectionPoint(collectionId));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  useEffect(() => {
    if (collectionId !== "" && singleCollectionPoint) {
      setValue("pointName", singleCollectionPoint?.collection.pointName);
      setValue("location", singleCollectionPoint?.collection.location);
      setValue("officer", singleCollectionPoint?.collection.officer);
      setValue("community_name", singleCollectionPoint?.collection.community_name);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleCollectionPoint]);

  type FormData = {
    officer: string;
    location: string;
    pointName: string;
    community_id: string;
    community_name: string;
  };
  // console.log(selectedOption, keyOption);

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;

    if (selectComboxOption["community_id"] === undefined && !collectionId) {
      setSelectComboxErrorOption(true);
      return;
    }

    if (!collectionId) {
      let data = vals;
      data.community_id = selectComboxOption["community_id"];
      data.community_name = selectComboxOption["community_name"];

      dispatch(
        createCollectionPoint(data, () => {
          dispatch(getCollectionPoint(1, 10));
          closeModal();
        })
      );
    } else {
      const data = {
        pointName: vals.pointName,
        location: vals.location,
        officer: vals.officer
      };

      dispatch(
        editCollectionPoint(data, collectionId, () => {
          dispatch(getCollectionPoint(1, 10));
          closeModal();
        })
      );
    }
  };

  const communities = community?.community.map(community => {
    return { label: community.community_name, value: community._id };
  });

  //Remove error message
  React.useEffect(() => {
    if (keyOption) {
      setError("");
    }
    if (selectComboxOption !== "") {
      setSelectComboxErrorOption(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption, selectComboxOption]);

  const onSelectChange = vals => {
    // console.log(vals);

    setSelectComboxOption({
      community_id: vals.value,
      community_name: vals.label
    });
  };

  return (
    <Modal
      modalText={collectionId ? "Edit Collection Point" : "Create Collection Point"}
      onClose={closeModal}
      boxClasses="collection-modal-top "
    >
      <div id="create-collection-modal">
        <p className="">Complete this form with accurate data to create a new collection point on Sebore farms.</p>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="pointName"
            label="Collection Point Name"
            placeholder="Collection Point Name"
            error={errors.pointName && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="Location"
            name="location"
            placeholder="Location"
            boxClasses="mt-3"
            error={errors.location && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="officer"
            name="officer"
            placeholder="officer"
            boxClasses="mt-3"
            error={errors.officer && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          {/* <ArrowDropdown
                        text="Community"
                        options={communities}
                        handleDropdownChange={handleDropdownChange}
                        handleAction={handleAction}
                        selectedOption={selectedOption}
                        open={open}
                        boxScroll="box-scroll"
                        boxClasses="mt-4"
                        ref={dropdownRef}
                    /> */}
          {!collectionId ? (
            <SelectBox
              options={communities}
              placeholder="Community"
              label="Community"
              boxClasses="mt-4"
              onChange={onSelectChange}
              legend={true}
              isDisabled={isLoading}
              error={selectComboxErrorOption ? "Please select a Community" : ""}
            />
          ) : (
            <Textbox
              type="text"
              label="Community"
              name="community_name"
              placeholder="Community"
              boxClasses="mt-3"
              disabled={true}
              error={errors.officer && "This field is required"}
              customRef={register({})}
            />
          )}

          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
          <Button
            label="Create Point"
            btnType="btn-primary"
            boxClasses="mt-5 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CreateCollectionPoint;
