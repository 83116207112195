import React, { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";
import { createState, editState, getStateId, getStates } from "store/slice/StateSlice/StateSlice";

type Props = {
    closeModal: () => void;
    value?: string;
    stateId?: string;
};
const CreateState = ({ closeModal, value, stateId }: Props) => {
    //Redux Store
    const dispatch = useDispatch();
    const { singleState, errorMessage, isLoading } = useSelector((state: RootState) => state.stateReducer);
    const { register, handleSubmit, errors, setValue } = useForm<FormData>({
        mode: "onBlur"
    });

    //
    useEffect(() => {
        if (stateId && value !== "") dispatch(getStateId(stateId));
    }, [stateId]);

    //Setting Input Field for edit function
    useEffect(() => {
        if (singleState && value !== "") {
            setValue("state_name", singleState?.state.state_name);
            setValue("state_code", singleState?.state.state_code);
        }
    }, [singleState]);
    type FormData = {
        state_code: string;
        state_name: string;
    };

    const onFormSubmit = (vals: FormData) => {
        if (isLoading) return;

        if (value !== "") {
            dispatch(
                editState(vals, stateId, () => {
                    closeModal();
                    dispatch(getStates(1, 10));
                })
            );
        } else {
            dispatch(
                createState(vals, () => {
                    closeModal();
                    dispatch(getStates(1, 10));
                })
            );
        }
    };
    return (
        <Modal modalText={value === "" ? "Create State" : "Edit State"} onClose={closeModal} boxClasses="">
            <div className="role-modal">
                {value === "" && <p className="">Complete this form with accurate data to create a new State.</p>}
                <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
                    <Textbox
                        type="text"
                        label="State Name"
                        placeholder="Enter State Name"
                        name="state_name"
                        error={errors.state_name && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        boxClasses="mb-3"
                        disabled={isLoading}
                    />
                    <Textbox
                        type="text"
                        label="Code Name"
                        placeholder="Enter Code Name"
                        name="state_code"
                        error={errors.state_code && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                        disabled={isLoading}
                    />

                    {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
                    <Button
                        label={value === "" ? "Create State" : "Edit State"}
                        btnType="btn-primary"
                        boxClasses="mt-4 mb-5"
                        btnStyle="100%"
                        loading={isLoading}
                    />
                </form>
                <p className="role-p" onClick={closeModal}>
                    Cancel
                </p>
            </div>
        </Modal>
    );
};

export default CreateState;
