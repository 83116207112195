import "./styles.scss";

export const dispatchProviderData = [
  {
    produce: "Milk",
    scale: "kg",
    unit: "N700"
  },
  {
    produce: "Milk",
    scale: "kg",
    unit: "N700"
  }
];

export const dispatchProviderColumn = [
  {
    name: "State Name",
    index: "state_name",
    id: 1
  },
  {
    name: "State Code",
    index: "state_code",
    id: 2
  },
  {
    name: "Actions",
    id: 3,
    render: ({ edit, providerName, deleteCommunityHandle, id }) => {
      return (
        <span className="span-container">
          <p className="edit" onClick={() => edit(providerName, id)}>
            Edit
          </p>
          <p className="delete pl-3" onClick={() => deleteCommunityHandle()}>
            Delete
          </p>
        </span>
      );
    }
  }
];
