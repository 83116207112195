import * as React from "react";
import "./styles.scss";
import Frame from "assests/icons/statsCard/Frame.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { dispatchProviderColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/state_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import {
  createDispatchProviderFail,
  getDispatchProviders
} from "store/slice/DispatchProviderSlice/DispatchProviderSlice";
import { deleteState, getStates } from "store/slice/StateSlice/StateSlice";
import { toastPopUp } from "utils/utils";
import CreateState from "components/Modals/CreateOrEditState";

type Props = {};

const States: React.FunctionComponent<Props> = () => {
  const [page, setPage] = React.useState<number>(1);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>("");
  const [stateId, setStateId] = React.useState<string>("");
  const { search, inputRef, handleChange, searched } = useSearch(getStates, perpage);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getStates(page, perpage));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setpage number to 1 if searched is done
  React.useEffect(() => {
    if (searched) setPage(1);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched]);

  const { states, isLoading } = useSelector((state: RootState) => state.stateReducer);

  const edit = (e: any, id: any) => {
    setModal(!modal);
    setValue(e);
    setStateId(id);
  };

  const deleteStateHandler = (id: any, name: string) => {
    toastPopUp(
      () => {
        dispatch(
          deleteState(id, () => {
            dispatch(getStates(page, perpage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete State",
      name,
      "red"
    );
  };

  const stateList =
    states &&
    states?.state?.map((data: any) => {
      return {
        state_name: data.state_name,
        state_code: data.state_code,
        edit: edit,
        deleteCommunityHandle: () => deleteStateHandler(data._id, data.state_name),
        id: data._id
      };
    });

  const handlePageChange = React.useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(getDispatchProviders(page, perpage, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(getDispatchProviders(pageNo, perpage, search));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const handleNewPage = (pageNo: number, page = 1) => {
    setPerpage(pageNo);
    setPage(1);
    dispatch(getDispatchProviders(page, pageNo, search));
  };

  const closeModal = () => {
    setModal(!modal);
    setValue("");
    dispatch(createDispatchProviderFail(""));
  };

  return (
    <div className="state-page">
      {modal && <CreateState closeModal={closeModal} stateId={stateId} value={value} />}
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={states?.pagination?.total || 0} text="States" classes="stats-icon" image={Frame} />
          <StatsCard image={Icon} classes="middle-img" centerText="Create States" onClick={closeModal} />
        </div>
        <h4 className="page-title mb-2">States Logs</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for State name or State code."
            boxClasses=" mb-3"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>
        <DataTable columns={dispatchProviderColumn} data={stateList} loading={isLoading} />
        <Pagination
          page={page}
          lastPage={states?.pagination?.lastPage}
          paginate={paginate}
          total={states?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default States;
