import { toastr } from 'react-redux-toastr';
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { CollectionPointState, SingleCollectionPointAction, CollectionPointAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: CollectionPointState = {
    isLoading: false,
    singleCollectionPoint: null,
    allCollectionPoint: null,
    successMessage: "",
    errorMessage: ""
};

const collectionPointSlice = createSlice({
    name: "collectionPoint",
    initialState,
    reducers: {
        getSingleCollectionPointLoading: (state) => {
            state.isLoading = true;
        },
        getSingleCollectionPointSuccess: (state, action: PayloadAction<SingleCollectionPointAction>) => {
            state.isLoading = false;
            state.singleCollectionPoint = action.payload;
        },
        getSingleCollectionPointFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        getCollectionPointLoading: (state) => {
            state.isLoading = true;
        },
        getCollectionPointSuccess: (state, action: PayloadAction<CollectionPointAction>) => {
            state.isLoading = false;
            state.allCollectionPoint = action.payload;
        },
        getCollectionPointFail: (state) => {
            state.isLoading = false;
        },
        deleteCollectionPointLoading: (state) => {
            state.isLoading = true;
        },
        deleteCollectionPointSuccess: (state) => {
            state.isLoading = false;
        },
        deleteCollectionPointFail: (state) => {
            state.isLoading = false;
        },
        createCollectionPointLoading: (state) => {
            state.isLoading = true;
        },
        createCollectionPointSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createCollectionPointFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editCollectionPointLoading: (state) => {
            state.isLoading = true;
        },
        editCollectionPointSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editCollectionPointFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = collectionPointSlice;

export const {
    getSingleCollectionPointLoading,
    getSingleCollectionPointSuccess,
    getSingleCollectionPointFail,
    getCollectionPointFail,
    getCollectionPointLoading,
    getCollectionPointSuccess,
    deleteCollectionPointFail,
    deleteCollectionPointLoading,
    deleteCollectionPointSuccess,
    createCollectionPointFail,
    createCollectionPointLoading,
    createCollectionPointSuccess,
    editCollectionPointFail,
    editCollectionPointLoading,
    editCollectionPointSuccess
} = actions;

export const getSingleCollectionPoint = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleCollectionPointLoading());
    try {
        const response = await axios.get(`${BASE_URL}/collectionPoint/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getSingleCollectionPointSuccess(response.data));
        }
    } catch (error) {
        dispatch(getSingleCollectionPointFail(error?.response?.data?.message));
    }
};

export const createCollectionPoint = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createCollectionPointLoading());
    try {
        const response = await axios.post(`${BASE_URL}/collectionPoint/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(createCollectionPointSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createCollectionPointFail(error.response.data.error));
        toastr.error(error.response.data.error);
        setTimeout(() => {
            dispatch(createCollectionPointFail(""));
        }, 3000);
    }
};

export const editCollectionPoint = (data: any, id, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editCollectionPointLoading());
    try {
        const response = await axios.post(`${BASE_URL}/collectionPoint/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(editCollectionPointSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editCollectionPointFail(error.response.data.message));
        toastr.error(error.response.data.message);
        setTimeout(() => {
            dispatch(editCollectionPointFail(""));
        }, 3000);
    }
};


export const deleteCollectionPoint = (id: string, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteCollectionPointLoading());
    try {
        const response = await axios.post(`${BASE_URL}/collectionPoint/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deleteCollectionPointSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(deleteCollectionPointFail());
        toastr.error(error.response.data.message);
    }
};

export const getCollectionPoint = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCollectionPointLoading());
    try {
        const response = await axios.get(`${BASE_URL}/collectionPoint/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getCollectionPointSuccess(response.data));
        }
    } catch (error) {
        dispatch(getCollectionPointFail());
    }
};

export default reducer;
