import "./styles.scss";

export const loans = [
  {
    loan: "Phone Purchase",
    amount: "N56,000",
    id: 1,
    deducted: "N2,000"
  },
  {
    loan: "Phone Purchase",
    amount: "N56,000",
    id: 2,
    deducted: "N2,000"
  },
  {
    loan: "Phone Purchase",
    amount: "N56,000",
    id: 3,
    deducted: "N2,000"
  },
  {
    loan: "Phone Purchase",
    amount: "N56,000",
    id: 4,
    deducted: "N2,000"
  },
  {
    loan: "Phone Purchase",
    amount: "N56,000",
    id: 5,
    deducted: "N2,000"
  }
];

export const loansColumn = [
  {
    name: "Name of Loan",
    index: "description",
    id: 1
  },
  {
    name: "Total Amount",
    index: "amount",
    id: 2
  },
  {
    name: "Amount to be deducted",
    index: "amountDeducted",
    id: 3
  }
];
