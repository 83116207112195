import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/home 1.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { collectionColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/add-circle 1.png";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionPoint, deleteCollectionPoint } from "store/slice/CollectionPointSlice/CollectionPointSlice";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import CreateCollectionPoint from "components/Modals/CreateCollectionPoint";
import { toastPopUp } from "utils/utils";

type Props = {};

const Collection: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = React.useState<number>(1);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [collect_id, setCollect_id] = React.useState<string>("");

  //Hook
  const { search, inputRef, handleChange } = useSearch(getCollectionPoint, perpage);
  const dispatch = useDispatch();
  const { allCollectionPoint } = useSelector((state: RootState) => state.points);

  React.useEffect(() => {
    dispatch(getCollectionPoint(page, perpage));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const edit = (id: string) => {
    setModal(!modal);
    setCollect_id(id);
  };

  const deleteCollection = (e: any, name: string) => {
    toastPopUp(
      () => {
        dispatch(
          deleteCollectionPoint(e, () => {
            dispatch(getCollectionPoint(page, perpage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete Collection Point",
      name,
      "red"
    );
  };

  const collections =
    allCollectionPoint &&
    allCollectionPoint?.collections?.map((data: any) => {
      return {
        pointName: data.pointName,
        location: data.location,
        officer: data.officer,
        community_name: data.community_id.community_name,
        lga: data.community_id.lga.lga_name,
        id: data._id,
        edit: edit,
        deleteCol: deleteCollection
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(getCollectionPoint(page, perpage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(getCollectionPoint(pageNo, perpage, search));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = (pageNo: number, page = 1) => {
    setPerpage(pageNo);
    setPage(1);
    dispatch(getCollectionPoint(page, pageNo, search));
  };

  //------------------------------------------------
  //modal toggler
  //------------------------------------------------
  const closeModal = () => {
    setModal(!modal);
    setCollect_id("");
  };

  return (
    <div id="collection-page">
      {modal && <CreateCollectionPoint closeModal={closeModal} collectionId={collect_id} />}
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard
            statsNumber={allCollectionPoint?.pagination?.total || 0}
            text="Collection Points"
            classes="stats-icon"
            image={People}
          />
          <StatsCard image={Icon} classes="middle-img" centerText="Create Point" onClick={closeModal} />
        </div>
        <h4 className="page-title mb-2">Collection Points Logs</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for Point Name, Location"
            boxClasses=" mb-3"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>

        <DataTable columns={collectionColumn} data={collections} />
        <Pagination
          page={page}
          lastPage={allCollectionPoint?.pagination?.lastPage}
          paginate={paginate}
          total={allCollectionPoint?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Collection;
