import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "./data";
import "./styles.scss";
import logo from "assests/images/image 3 (1).png";
import SeboreLogo from "assests/images/sebore_logo_two.png";
import Button from "components/Button";
import CloseMenu from "assests/icons/close-menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { showPayFarmerModal } from "store/slice/UISlice/UISlice";
import { useLocation } from "react-router-dom";
import { ReactComponent as Dispatch } from "assests/icons/sidebar/dispatch.svg";
import { ReactComponent as User } from "assests/icons/sidebar/person-add.svg";
import { ReactComponent as People } from "assests/icons/sidebar/people.svg";
import { ReactComponent as Inventory } from "assests/icons/sidebar/inventory.svg";
import { ReactComponent as Settings } from "assests/icons/sidebar/settings.svg";
import { ReactComponent as Chart } from "assests/icons/sidebar/ios-pie.svg";
import { ReactComponent as Arrow } from "assests/icons/sidebar/swap-horizontal-outline.svg";

export interface SidebarProps {
    menu: boolean;
    handleMenu: () => void;
    setMenu: any;
}

const Sidebar: React.FunctionComponent<SidebarProps> = ({ menu, handleMenu, setMenu }) => {
    const dropdown = useRef<HTMLDivElement | null>(null);
    const { pathname } = useLocation();

    //Closes the sideBar on route changes on mobile view
    React.useEffect(() => {
        if (menu) {
            setMenu(false);
        }
    }, [pathname]);

    //closes the sidebar
    useEffect(() => {
        const hide = (e: any): void => {
            if (!dropdown!.current?.contains(e.target)) {
                setMenu(false);
            }
        };

        window.addEventListener("mousedown", hide);
        return () => {
            window.removeEventListener("mousedown", hide);
        };
    }, []);

    const dispatch = useDispatch();
    const { permissions } = useSelector((state: RootState) => state.user);

    return (
        <div className={`side-bar ${menu ? "show-left" : "hide-left"}`} ref={dropdown}>
            <div className="side-bar-navigation">
                <div className="brand-logo">
                    <img src={SeboreLogo} alt="" className="logo" />
                    {/* <img src={CloseMenu} alt="menu" className="close-menu cursor-pointer" onClick={handleMenu} /> */}
                </div>
                <div className="mt-4 mb-2">
                    <NavLink
                        activeClassName="active"
                        className="d-flex align-items-center nav-link justify-content-center mb-3"
                        to="/dashboard"
                    >
                        <Chart className="icon" />
                        <h6 className="ml-3 route-name">Dashboard</h6>
                    </NavLink>
                    {permissions?.includes("HOUSEHOLD_VIEW") && (
                        <NavLink
                            activeClassName="active"
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to="/household"
                        >
                            <People className="icon" />
                            <h6 className="ml-3 route-name">Household</h6>
                        </NavLink>
                    )}
                    {permissions?.includes("TRANSACTIONS_VIEW_ALL") && (
                        <NavLink
                            activeClassName="active"
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to="/transaction"
                        >
                            <Arrow className="icon" />
                            <h6 className="ml-3 route-name">Transaction</h6>
                        </NavLink>
                    )}
                    {permissions?.includes("INVENTORY_VIEW") && (
                        <NavLink
                            activeClassName="active"
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to="/inventory"
                        >
                            <Inventory className="icon" />
                            <h6 className="ml-3 route-name">Inventory</h6>
                        </NavLink>
                    )}
                    {permissions?.includes("DISPATCH_VIEW") && (
                        <NavLink
                            activeClassName="active"
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to="/dispatch"
                        >
                            <Dispatch className="icon" />
                            <h6 className="ml-3 route-name">Dispatch</h6>
                        </NavLink>
                    )}
                    {permissions?.includes("USER_VIEW") && (
                        <NavLink
                            activeClassName="active"
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to="/user-mgt"
                        >
                            <User className="icon" />
                            <h6 className="ml-3 route-name">User Mgt</h6>
                        </NavLink>
                    )}

                    {permissions?.includes("SETTINGS") && (
                        <NavLink
                            activeClassName="active"
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to="/settings"
                        >
                            <Settings className="icon" />
                            <h6 className="ml-3 route-name">Settings</h6>
                        </NavLink>
                    )}

                    {/* {routes.map((route, index) => (
                        <NavLink
                            activeClassName="active"
                            key={index}
                            className="d-flex align-items-center nav-link justify-content-center mb-3"
                            to={route.path}
                        >
                            <route.Icon className="icon" />
                            <h6 className="ml-3 route-name">{route.name}</h6>
                        </NavLink>
                    ))} */}
                </div>
                {permissions?.includes("FARMER_PAY") && (
                    <Button
                        label="Pay a Farmer"
                        btnType="btn-primary"
                        btnStyle="100%"
                        boxClasses="mbtn-auto"
                        onClick={() => dispatch(showPayFarmerModal())}
                    />
                )}
            </div>
        </div>
    );
};

export default Sidebar;
