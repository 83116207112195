import { toastr } from "react-redux-toastr";
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { ProduceState, SingleProduceAction, ProduceAction } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: ProduceState = {
    isLoading: false,
    singleProduce: null,
    allProduce: null,
    successMessage: "",
    errorMessage: ""
};

const produceSlice = createSlice({
    name: "produce",
    initialState,
    reducers: {
        getSingleProduceLoading: (state) => {
            state.isLoading = true;
        },
        getSingleProduceSuccess: (state, action: PayloadAction<SingleProduceAction>) => {
            state.isLoading = false;
            state.singleProduce = action.payload;
        },
        getSingleProduceFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        getProduceLoading: (state) => {
            state.isLoading = true;
        },
        getProduceSuccess: (state, action: PayloadAction<ProduceAction>) => {
            state.isLoading = false;
            state.allProduce = action.payload;
        },
        getProduceFail: (state) => {
            state.isLoading = false;
        },
        deleteProduceLoading: (state) => {
            state.isLoading = true;
        },
        deleteProduceSuccess: (state) => {
            state.isLoading = false;
        },
        deleteProduceFail: (state) => {
            state.isLoading = false;
        },
        createProduceLoading: (state) => {
            state.isLoading = true;
        },
        createProduceSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        createProduceFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editProduceLoading: (state) => {
            state.isLoading = true;
        },
        editProduceSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.successMessage = action.payload;
        },
        editProduceFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = produceSlice;

export const {
    getSingleProduceLoading,
    getSingleProduceSuccess,
    getSingleProduceFail,
    getProduceFail,
    getProduceLoading,
    getProduceSuccess,
    deleteProduceFail,
    deleteProduceLoading,
    deleteProduceSuccess,
    createProduceFail,
    createProduceLoading,
    createProduceSuccess,
    editProduceFail,
    editProduceLoading,
    editProduceSuccess
} = actions;

export const getSingleProduce = (id: string): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleProduceLoading());
    try {
        const response = await axios.get(`${BASE_URL}/produce/get/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getSingleProduceSuccess(response.data));
        }
    } catch (error) {
        dispatch(getSingleProduceFail(error?.response?.data?.message));
    }
};

export const createProduce = (data: any, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createProduceLoading());
    try {
        const response = await axios.post(`${BASE_URL}/produce/create`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(createProduceSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(createProduceFail(error.response.data.message));
        toastr.error(error.response.data.message);
        setTimeout(() => {
            dispatch(editProduceFail(""));
        }, 3000);
    }
};

export const editProduce = (data: any, id, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editProduceLoading());
    try {
        const response = await axios.post(`${BASE_URL}/produce/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(editProduceSuccess(response.data.message));
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(editProduceFail(error.response.data.message));
        setTimeout(() => {
            dispatch(editProduceFail(""));
        }, 3000);
        toastr.error(error.response.data.message);
    }
};

export const deleteProduce = (id: string, cb: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteProduceLoading());
    try {
        const response = await axios.post(`${BASE_URL}/produce/delete/${id}`, null, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        });
        if (response.status) {
            dispatch(deleteProduceSuccess());
            toastr.success("", response.data.message);
            cb();
        }
    } catch (error) {
        dispatch(deleteProduceFail());
        toastr.error(error.response.data.message);
    }
};

export const getProduce = (
    page?: number,
    perpage?: number,
    search?: string,
    sortBy?: string,
    orderBy?: string
): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getProduceLoading());
    try {
        const response = await axios.get(`${BASE_URL}/produce/get`, {
            params: {
                page,
                perpage,
                search,
                sortBy,
                orderBy
            },
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getProduceSuccess(response.data));
        }
    } catch (error) {
        dispatch(getProduceFail());
    }
};

export default reducer;
