import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { AuthState, Action } from "./interface";
import { updateUser } from "../UserSlice/UserSlice";
import { getProfile } from "store/slice/UserSlice/UserSlice";
import { getEyowoBalance } from "../TransactionSlice/TransactionSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: AuthState = {
    isLoading: false,
    user: null,
    userToken: "",
    errorMessage: "",
    message: ""
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginLoading: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.user = action.payload.user;
            state.userToken = action.payload.token;
        },
        loginFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },

        forgetPasswordLoading: (state) => {
            state.isLoading = true;
        },
        forgetPasswordSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.message = action.payload.message;
            state.errorMessage = "";
        },
        forgetPasswordFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        setPasswordLoading: (state) => {
            state.isLoading = true;
        },
        setPasswordSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.message = action.payload.message;
        },
        setPasswordFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        verifyPassword: (state) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = authSlice;

export const {
    loginLoading,
    loginSuccess,
    loginFail,
    setPasswordFail,
    setPasswordLoading,
    setPasswordSuccess,
    forgetPasswordLoading,
    forgetPasswordSuccess,
    forgetPasswordFail,
    verifyPassword
} = actions;

type Login = {
    email: string;
    password: string;
};

type SetPassword = {
    password: string;
    passwordToken: string;
};

type ForgetPassword = {
    email: string;
};

export const loginRequest = (payload: Login, check?: string, cb?: () => void): AppThunk => async (
    dispatch: AppDispatch
) => {
    dispatch(loginLoading());
    try {
        const response = await axios.post(`${BASE_URL}/users/login`, payload);
        if ([200, 201].includes(response.status)) {
            if (!check) {
                dispatch(loginSuccess(response.data));
                const permissions = response?.data?.permissions.map((item: string) => item["permission_name"]);
                dispatch(
                    updateUser({
                        authorized: true,
                        user: response.data.user,
                        token: response.data.token,
                        permissions: permissions
                    })
                );
                localStorage.setItem("sebore", JSON.stringify(response.data.token));
                dispatch(getProfile(response.data.user._id));
                dispatch(getEyowoBalance());
            }
            if (check && check === "verify") {
                dispatch(verifyPassword());
                cb && cb();
            }
        }
    } catch (error) {
        dispatch(loginFail(error?.response?.data?.message));
        if (check && check === "verify") {
            setTimeout(() => {
                dispatch(loginFail(""));
            }, 5000);
        }
    }
};

export const setPasswordRequest = ({ password, passwordToken }: SetPassword): AppThunk => async (
    dispatch: AppDispatch
) => {
    dispatch(setPasswordLoading());
    try {
        const response = await axios.post(`${BASE_URL}/users/reset-password/${passwordToken}`, password);
        if (response.status) {
            dispatch(setPasswordSuccess(response.data));
        }
    } catch (error) {
        dispatch(setPasswordFail(error.response.data.message));
    }
};

export const forgetPassword = (payload: ForgetPassword, cb: () => void): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(forgetPasswordLoading());
    try {
        const response = await axios.post(`${BASE_URL}/users/recover`, payload);
        if (response.status) {
            dispatch(forgetPasswordSuccess(response.data));
            setTimeout(() => {
                cb();
            }, 1500);
        }
    } catch (error) {
        dispatch(forgetPasswordFail(error.response.data.message));
    }
};

export default reducer;
