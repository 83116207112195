import { useState, useEffect, useRef } from "react";
import { DateRange } from "react-date-range";
import arrowdown from "assests/icons/Vector (2).svg";
import { format } from "date-fns";
import "./styles.scss";

type Props = {
  range: any;
  handleDateChange: (e: {}) => void;
};

const DateRangePicker = ({ range, handleDateChange }: Props) => {
  const [pickerVisible, setpickerVisible] = useState(false);

  const dropdown = useRef<HTMLDivElement | null>(null);
  //closes the dropdown
  useEffect(() => {
    const hide = (e: any): void => {
      if (!dropdown!.current?.contains(e.target)) {
        setpickerVisible(false);
      }
    };

    window.addEventListener("mousedown", hide);
    return () => {
      window.removeEventListener("mousedown", hide);
    };
  }, []);

  const selectionRange = {
    startDate: range.startDate,
    endDate: range.endDate,
    key: "selection"
  };

  const handleSelect = value => {
    handleDateChange && handleDateChange(value.selection);
  };

  return (
    <div className="date-picker" ref={dropdown}>
      <div
        className="d-flex align-items-center selected-range justify-content-between"
        role="button"
        onClickCapture={() => setpickerVisible(!pickerVisible)}
      >
        <p className="range-date">{`${format(new Date(range.startDate), "do LLL yy")} - ${format(
          new Date(range.endDate),
          "do LLL yy"
        )}`}</p>
        <img src={arrowdown} alt="alt" />
      </div>
      <div className={`picker ${pickerVisible && "active"}`}>
        <DateRange
          editableDateInputs={true}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          ranges={[selectionRange]}
          rangeColors={["#00b050"]}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
