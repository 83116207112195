import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/leaf-sharp.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { produceColumn, produceData } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/add-circle 1 2.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduce, getProduce } from "store/slice/ProduceSlice/ProduceSlice";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import CreateProduce from "components/Modals/CreateProduce";
import { toastPopUp } from "utils/utils";

type Props = {};

const Produce: React.FunctionComponent<Props> = () => {
    const [page, setPage] = React.useState<number>(1);
    const [perpage, setPerpage] = React.useState<number>(10);
    const [modal, setModal] = React.useState<boolean>(false);
    const [tableID, setTableID] = React.useState();
    const { search, inputRef, handleChange, searched } = useSearch(getProduce, perpage);
    const [modalData, setModalData] = React.useState({
        edit: false,
        produceID: ""
    });
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getProduce(page, perpage));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { allProduce, isLoading } = useSelector((state: RootState) => state.produce);

    React.useEffect(() => {
        if (modal === false) {
            setModalData({ ...modalData, produceID: "", edit: false });
        }
    }, [modal]);

    // setpage number to 1 if searched is done
    React.useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    const editProduce = (id: string) => {
        closeModal();
        setModalData({ ...modalData, produceID: id, edit: true });
    };

    const deleteProduceId = (id: string, name: string) => {
        toastPopUp(
            () => {
                dispatch(
                    deleteProduce(id, () => {
                        dispatch(getProduce(page, perpage));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete the Produce",
            name,
            "red"
        );
    };

    const produceList =
        allProduce &&
        allProduce?.produces?.map((data: any) => {
            return {
                produce_name: data.produce_name,
                scale: data.scale,
                unit_price: data.unit_price,
                id: data._id,
                // toggleDropdown: handleTableDropdown,
                // tableID: tableID,
                edit: () => editProduce(data._id),
                deleteProduceId: () => deleteProduceId(data._id, data.produce_name)
            };
        });

    //--------------------------------------------
    //Pagination
    //---------------------------------------------
    const handlePageChange = React.useCallback((page: number, perpage: number) => {
        setPage(page);
        dispatch(getProduce(page, perpage, search));
    }, [search]);

    const paginate = React.useCallback((pageNo: number, perpage: number) => {
        setPage(pageNo);
        dispatch(getProduce(pageNo, perpage, search));
    }, [search]);

    //------------------------------------------------------------
    //Show Number for PerPage Function
    //------------------------------------------------------------
    const handleNewPage = (pageNo: number, page = 1) => {
        setPerpage(pageNo);
        setPage(1);
        dispatch(getProduce(page, pageNo, search));
    };

    //------------------------------------------------
    //modal toggler
    //------------------------------------------------
    const closeModal = () => {
        setModal(!modal);
    };

    return (
        <div id="produce-page">
            {modal && <CreateProduce closeModal={closeModal} modalData={modalData} />}
            <div className="container">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={allProduce?.pagination?.total || 0}
                        text="Produce"
                        classes="stats-icon"
                        image={People}
                    />
                    <StatsCard image={Icon} classes="middle-img" centerText="Create Produce" onClick={closeModal} />
                </div>
                <h4 className="page-title mb-2">Produce Logs</h4>
                <div className="search-field">
                    <SearchIcon
                        placeholder="Search for Produce name or Measurement scale"
                        boxClasses=" mb-3"
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    />
                    <Dropdown perPage={perpage} action={handleNewPage} />
                </div>

                <DataTable columns={produceColumn} data={produceList} loading={isLoading} />
                <Pagination
                    page={page}
                    lastPage={allProduce?.pagination?.lastPage}
                    paginate={paginate}
                    total={allProduce?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perpage}
                />
            </div>
        </div>
    );
};

export default Produce;
