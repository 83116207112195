import React from "react";
import Root from "Root";

type AppProps = {};

const App: React.FunctionComponent<AppProps> = () => {
    return <Root />;
};

export default App;
