import React, { useRef } from "react";
import AuthLayout from "./AuthContainer/AuthLayout";
import image from "assests/images/Negative_ Your investment not make a profit@1x.png";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setPasswordFail, setPasswordRequest } from "store/slice/AuthSlice/AuthSlice";
import ErrorMesssage from "components/ErrorMessage";
import { RootState } from "store/store";

type Props = {};

const SetPassword: React.FC = (props: Props) => {
  type FormData = {
    password: string;
    password_confirm: string;
    passwordToken: string;
  };
  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    mode: "onBlur"
  });
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  // Get Current password
  const password = useRef({});
  password.current = watch("password", "");

  // Get token from URl
  const token = props["match"].params.term;

  const onFormSubmit = ({ password, passwordToken = token }: FormData) => {
    const data = {
      password,
      passwordToken
    };
    dispatch(setPasswordRequest(data));
  };

  React.useEffect(() => {
    dispatch(setPasswordFail(""));
  }, []);

  return (
    <AuthLayout
      imgWidth="29rem"
      bottomImage={image}
      content="Make sure sure to set a password that’s unique to you, difficult and not easy to guess"
      headerText="Set Password"
      firstLink="Remember your password?"
      firstLinkColor="Sign In"
      padTop="6.8rem"
      link="/"
    >
      {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
      <form className="pt-3" onSubmit={handleSubmit(onFormSubmit)}>
        <Textbox
          type="password"
          label=" New Password"
          placeholder="Enter Password"
          name="password"
          boxClasses="pb-4"
          error={errors.password && "This field is required"}
          customRef={register({
            required: true
          })}
        />
        <Textbox
          type="password"
          label=" Confirm Password"
          placeholder="Confirm Password"
          name="password_confirm"
          boxClasses="pb-4"
          error={errors.password_confirm && errors.password_confirm.message}
          customRef={register({
            required: "This field is required",
            validate: value => value === password.current || "The passwords do not match"
          })}
        />
        <Button label="Set Password" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
      </form>
    </AuthLayout>
  );
};

export default SetPassword;
