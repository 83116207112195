import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/people 2 2.png";
import Leaf from "assests/icons/statsCard/leaf-sharp.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { householdColumns } from "./data";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import ArrowDropdown from "components/ArrowDropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import useCheckMobileScreen from "hooks/checkMobile";
import SelectBox from "components/SelectBox";
import AsynSelectBox from "components/AsyncSelectBox";
import axios from "axios";

type Props = {};

const Household: React.FunctionComponent<Props> = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const dispatch = useDispatch();

  //Redux
  const { summary, households } = useSelector((state: RootState) => state.household);
  const { community } = useSelector((state: RootState) => state.community);
  const { userToken } = useSelector((state: RootState) => state.user);

  //hook
  // const { open, selectedOption, handleDropdownChange, handleAction } = useArrowDropdown();
  const {
    open: open2,
    selectedOption: selectedOption2,
    handleDropdownChange: handleDropdownChange2,
    handleAction: handleAction2,
    dropdownRef: dropdownRef2
  } = useArrowDropdown();

  //
  const params = [
    selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
    selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined
  ];
  const { search, inputRef, handleChange, searched } = useSearch(getAllHousehold, perpage, params);

  // setpage number to 1 if searched is done
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Filter for Payment frequency and Community
  useEffect(() => {
    if ((selectedOption2 !== "" && selectedOption2) || (selectComboxOption !== "" && selectComboxOption)) {
      setPage(1);
      dispatch(
        getAllHousehold(
          1,
          perpage,
          search !== "" ? search : undefined,
          selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
          selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined
        )
      );
    }
  }, [selectedOption2, selectComboxOption]);

  useEffect(() => {
    dispatch(getHouseholdSummary());
    dispatch(getAllHousehold(page, perpage));
    dispatch(getCommunity(1, 100));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback(
    (page: number, perpage: number) => {
      setPage(page);
      dispatch(
        getAllHousehold(
          page,
          perpage,
          search,
          selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
          selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined
        )
      );
    },
    [selectedOption2, selectComboxOption, search]
  );

  const paginate = useCallback(
    (pageNo: number, perpage: number) => {
      setPage(pageNo);
      dispatch(
        getAllHousehold(
          pageNo,
          perpage,
          search,
          selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
          selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined
        )
      );
    },
    [selectedOption2, selectComboxOption, search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(
        getAllHousehold(
          page,
          pageNo,
          search,
          selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined,
          selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined
        )
      );
    },
    [selectedOption2, selectComboxOption, search]
  );

  //Get Communities
  const communities = [{ label: "All", value: "" }];
  community?.community.map((community: { community_name: string; _id: string }) => {
    return communities.push({ label: community.community_name, value: community._id });
  });

  const payFrequency = [
    {
      name: "All"
    },
    {
      name: "Weekly"
    },
    {
      name: "Bi-weekly"
    },
    {
      name: "Monthly"
    }
  ];

  const isMobile = useCheckMobileScreen();

  const onSelectChange = vals => {
    setSelectComboxOption(vals.label);
  };

  // handle input change event
  const [inputValue, setValue] = useState("");
  const handleInputChange = (value: React.SetStateAction<string>) => {
    setValue(value);
  };

  //Edge Case
  const loadOptions = (inputValue: string) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return axios
      .get(`${BASE_URL}/community/get?search=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      })
      .then(res =>
        res.data.community.map((community: { community_name: string; _id: string }) => {
          return { label: community.community_name, value: community._id };
        })
      )
      .catch();
  };

  return (
    <div id="household-page">
      <div className="container">
        <CardContainer title="People Stats" classes="mb-5">
          <StatsCard statsNumber={summary?.householdCount || 0} text="Households" classes="stats-icon" image={People} />

          <StatsCard
            statsNumber={summary?.farmerCount || 0}
            text="Total Farmers"
            classes="stats-icon-leaf"
            image={Leaf}
          />
        </CardContainer>

        <h4 className="page-title mb-2">Household List</h4>
        <div className="search-field">
          <SearchIcon
            placeholder="Search for Household name or ID"
            boxClasses=" mb-4"
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>
        <div className="d-flex flex-column flex-md-row">
          <div className=" col-md-3 col-sm-12 col-xs-12 pl-0 mb-3">
            <div className="arrow-div">
              {/* <SelectBox
                                options={communities}
                                placeholder="Communities"
                                label="Search Communities"
                                boxClasses=""
                                onChange={onSelectChange}
                                legend={false}
                            /> */}
              <AsynSelectBox
                legend={false}
                placeholder="Communities"
                options={communities}
                onChange={onSelectChange}
                onInputChange={handleInputChange}
                loadedOptions={loadOptions}
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12  pl-0 mb-3">
            <div className="arrow-div">
              <ArrowDropdown
                text="Payment Frequency"
                options={payFrequency}
                handleDropdownChange={handleDropdownChange2}
                handleAction={handleAction2}
                selectedOption={selectedOption2}
                open={open2}
                ref={dropdownRef2}
              />
            </div>
          </div>
        </div>

        <DataTable columns={householdColumns} data={households?.household} />
        <Pagination
          page={page}
          lastPage={households?.pagination?.lastPage}
          paginate={paginate}
          total={households?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={perpage}
        />
      </div>
    </div>
  );
};

export default Household;
