import "./styles.scss";
import { Link } from "react-router-dom";

export const householdColumns = [
  {
    name: "HouseHold Name",
    index: "house_name",
    id: 1
  },
  {
    name: "House ID",
    index: "house_id",
    id: 2
  },
  {
    name: "House Size",
    index: "house_size",
    id: 3
  },
  {
    name: "LGA",
    index: "house_lga",
    id: 4
  },
  {
    name: "Community",
    index: "house_community",
    id: 5
  },
  // {
  //     name: "State",
  //     index: "house_state",
  //     id: 4
  // },
  {
    name: "Actions",
    id: 6,
    render: item => (
      <span className="span-container">
        <p className="edit">
          <Link to={`/household/singlehousehold/${item._id}`}>View</Link>
        </p>
        <p className="delete pl-3">
          <Link
            to={{
              pathname: `/household/singlehousehold/edit/${item._id}`,
              state: { household: item.house_name }
            }}
          >
            Edit
          </Link>
        </p>
      </span>
    )
  }
];
