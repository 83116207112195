import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

type DropdownMenuProps = {
    options: string | any[];
    onSelect?: () => any;
    toggle?: boolean;
    setToggle?: () => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    boxClasses?: string;
};

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = ({
    options,
    onSelect,
    toggle,
    onClick,
    setToggle,
    boxClasses,
    children
}) => {
    //hook
    const dropdown = useRef<HTMLDivElement | null>(null);
    const [state, setstate] = useState(false);

    const handleChange = () => {
        setstate(!state);
    };

    //closes the dropdown
    useEffect(() => {
        const hide = (e: any): void => {
            if (!dropdown!.current?.contains(e.target)) {
                setstate(false);
            }
        };

        window.addEventListener("mousedown", hide);
        return () => {
            window.removeEventListener("mousedown", hide);
        };
    }, []);
    return (
        <div className="" onClick={handleChange} ref={dropdown}>
            {children}
            <div className="custom-dropdown-menu">
                {state && (
                    <ul className={`menu-box  ${boxClasses}`}>
                        {options && Array.isArray(options) ? (
                            options.map((item) => (
                                <li
                                    className={` ${item.style && item.style} menu-item mb-2`}
                                    key={Math.random() * 1000}
                                    onClick={item.select}
                                >
                                    {item.name}
                                </li>
                            ))
                        ) : (
                            <li className="menu-item" onClick={onSelect}>
                                {options}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default DropdownMenu;
