import React, { useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import StarRating from "components/Star";
import ComboTextBox from "components/ComboTextBox";
import { getProduce } from "store/slice/ProduceSlice/ProduceSlice";
import { getFarmers } from "store/slice/FarmerSlice/FarmerSlice";
import { payFarmer } from "store/slice/TransactionSlice/TransactionSlice";
import SelectBox from "components/SelectBox";
import AsynSelectBox from "components/AsyncSelectBox";
import axios from "axios";

type Props = {
    closeModal: () => void;
    farmerDetails?: object;
};
const PayFarmerModal = ({ closeModal, farmerDetails = {} }: Props) => {
    //State
    const [error, setError] = React.useState("");
    const [starRating, setStarRating] = React.useState<number | null>(null);
    const [starError, setStarError] = React.useState("");
    const [farmerInfo, setFarmerInfo] = React.useState({
        farmerID: farmerDetails !== {} ? farmerDetails["id"] : "",
        farmerName: "",
        phoneNo: ""
    });
    const [options, setOptions] = React.useState<any>([]);
    const [selectComboxOption, setSelectComboxOption] = React.useState("");
    const [selectComboxErrorOption, setSelectComboxErrorOption] = React.useState(false);
    const [highlightedOption, setHighlightedOption] = React.useState("");

    type FormData = {
        farmer_name: string;
        phoneNo: string;
        quantity: number;
        deduction: number;
    };
    //Hooks
    const { register, handleSubmit, errors, setValue } = useForm<FormData>({
        mode: "onChange"
    });
    const { farmerID, farmerName, phoneNo } = farmerInfo;
    //Redux Store
    const dispatch = useDispatch();
    const { allProduce } = useSelector((state: RootState) => state.produce);
    const { farmers } = useSelector((state: RootState) => state.farmer);
    const { isLoading } = useSelector((state: RootState) => state.transaction);
    const { userToken } = useSelector((state: RootState) => state.user);

    React.useEffect(() => {
        dispatch(getProduce(1, 1000));
        dispatch(getFarmers(1, 100));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (farmerDetails && farmerDetails !== {}) {
            setValue("farmer_name", farmerDetails["farmerName"]);
            setValue("phoneNo", farmerDetails["phoneNo"]);
            // console.log(farmerDetails["farmer_id"]);

            // setSelectComboxOption(farmerDetails["farmer_id"]);
            // setFarmerInfo({ ...farmerInfo, farmerID: farmerDetails["id"] });
        }
        if (selectComboxOption !== "") {
            setValue("farmer_name", farmerName);
            setValue("phoneNo", phoneNo);
        }
    }, [farmerDetails]);

    //List of Produce

    const produceList = [] as any;
    allProduce &&
        allProduce?.produces?.forEach((data) => {
            if (data.category === "Supply Chain") {
                produceList.push({
                    name: data.produce_name,
                    id: data._id
                });
            }
        });

    const { open, selectedOption, handleDropdownChange, handleAction, keyOption, dropdownRef } = useArrowDropdown();

    //Validating Phone Number
    const validatePhoneNo = (value: any) => {
        let values = value.replace(/[^0-9]*/g, "");
        if (value.length > 11 || value !== values) return "Should be a phone number";
    };

    //Setting Star rating value
    const handleStarRating = (rating: any) => {
        setStarRating(rating);
    };

    const onFormSubmit = (vals: FormData) => {
        if (keyOption === "") {
            setError("Please select a Produce");
            return;
        }

        if (starRating === null) {
            setStarError("Please give rating");
            return;
        }
        if (farmerID === "") {
            setSelectComboxErrorOption(true);
            return;
        }

        const data = {
            farmer_id: farmerID,
            produce_id: keyOption,
            produce_quantity: vals.quantity,
            rating: starRating,
            deduction: vals.deduction
        };
        dispatch(
            payFarmer(data, () => {
                closeModal();
            })
        );
    };

    //Remove error message
    React.useEffect(() => {
        if (keyOption) {
            setError("");
        }

        if (starRating) {
            setStarError("");
        }
        if (farmerID !== "") {
            setSelectComboxErrorOption(false);
            return;
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyOption, starRating]);

    React.useEffect(() => {
        const array = [] as any;
        if (farmers && farmers) {
            farmers &&
                farmers?.farmer.forEach((farm) => {
                    array.push({ label: farm.farmer_id, value: farm._id });
                    if (selectComboxOption !== "") {
                        if (selectComboxOption === farm.farmer_id) {
                            setFarmerInfo({
                                ...farmerInfo,
                                farmerID: farm._id,
                                farmerName: farm.first_name,
                                phoneNo: farm.phone
                            });
                        }
                    }
                });
        }
        setOptions([...array]);
    }, [farmers, selectComboxOption]);

    const onSelectChange = (vals) => {
        setSelectComboxOption(vals.label);
    };

    // handle input change event
    const [inputValue, set1nputValue] = useState("");
    const handleInputChange = (value: React.SetStateAction<string>) => {
        set1nputValue(value);
    };

    //Edge Case
    const loadOptions = (inputValue: string) => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        return axios
            .get(`${BASE_URL}/farmer/get?search=${inputValue}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            })
            .then((res) =>
                res.data.farmer.map((farm: { farmer_id: string; _id: string }) => {
                    return { label: farm.farmer_id, value: farm._id };
                })
            )
            .catch();
    };

    return (
        <Modal modalText="Pay a Farmer" onClose={closeModal} modallength="modal-length">
            <div id="pay-farmer-page">
                <p className="mb-2">Complete this form with accurate data to pay farmer for produce collected</p>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    {/* <ComboTextBox
                        options={options}
                        placeholder="Farmer's ID"
                        boxClasses="mb-3"
                        label="Farmer's ID"
                        defaultValue={farmerDetails && farmerDetails !== {} && farmerDetails["farmer_id"]}
                        setSelectComboxOption={setSelectComboxOption}
                        setHighlightedOption={setHighlightedOption}
                        error={selectComboxErrorOption ? "This field is required" : ""}
                        disabled={farmerDetails && !farmerDetails["disable"]}
                    /> */}

                    <AsynSelectBox
                        legend={true}
                        placeholder="Farmer's ID"
                        options={options}
                        label="Farmer's ID"
                        onChange={onSelectChange}
                        boxClasses="mb-3"
                        onInputChange={handleInputChange}
                        loadedOptions={loadOptions}
                        defaultValue={
                            farmerDetails && farmerDetails !== {}
                                ? {
                                      value: farmerDetails["farmer_id"],
                                      label: farmerDetails["farmer_id"]
                                  }
                                : null
                        }
                        isDisabled={farmerDetails && farmerDetails["disable"]}
                        error={selectComboxErrorOption ? "This field is required" : ""}
                    />

                    {/* <SelectBox
                        options={options}
                        label="Farmer's ID"
                        placeholder="Farmer's ID"
                        boxClasses="mb-3"
                        onChange={onSelectChange}
                        legend={true}
                        defaultValue={
                            farmerDetails && farmerDetails !== {}
                                ? {
                                      value: farmerDetails["farmer_id"],
                                      label: farmerDetails["farmer_id"]
                                  }
                                : null
                        }
                        isDisabled={farmerDetails && farmerDetails["disable"]}
                        error={selectComboxErrorOption ? "This field is required" : ""}
                    /> */}

                    <Textbox
                        type="text"
                        name="farmer_name"
                        label="Farmer's Name"
                        placeholder="Farmer's Name"
                        boxClasses="mb-3"
                        customRef={register({
                            required: true
                        })}
                        disabled={true}
                    />
                    <Textbox
                        type="number"
                        name="phoneNo"
                        label="Phone Number"
                        placeholder="Phone Number"
                        boxClasses="mb-4"
                        customRef={register({
                            required: "This field is required",
                            validate: validatePhoneNo
                        })}
                        disabled={true}
                    />
                    <ArrowDropdown
                        text="Select Produce"
                        options={produceList}
                        handleDropdownChange={handleDropdownChange}
                        handleAction={handleAction}
                        selectedOption={selectedOption}
                        open={open}
                        boxClasses="mb-3"
                        ref={dropdownRef}
                    />
                    <p className={`${error ? "d-block" : "d-none"} error`}>{error}</p>
                    <Textbox
                        type="number"
                        name="quantity"
                        label="Produce Quantity"
                        placeholder="Produce Quantity"
                        boxClasses="mb-3"
                        error={errors.quantity && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    <div className="pb-3">
                        <p className="pb-3">How would you rate the produce quality?</p>
                        <div className="star-div">
                            <StarRating disabled={false} stars={0} onChange={handleStarRating} starClass="star-class" />
                        </div>
                        <p className={`${starError ? "d-block" : "d-none"} error `}>{starError}</p>
                    </div>

                    {/* <Textbox
                        type="number"
                        name="produce_amount"
                        label="Produce Amount"
                        placeholder="Produce Amount"
                        boxClasses="mb-3"
                        // error={errors.firstName && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    /> */}
                    <Textbox
                        type="number"
                        name="deduction"
                        label="Deductions"
                        placeholder="Deductions"
                        boxClasses="mb-4"
                        error={errors.deduction && "This field is required"}
                        customRef={register({
                            required: true
                        })}
                    />
                    {/* <div className="d-flex justify-content-between"> */}
                    <Button
                        label="Submit"
                        btnType="btn-primary"
                        boxClasses="box-class"
                        xtraClass="xtra-left"
                        btnStyle=""
                        loading={isLoading}
                    />
                    {/* <Button
                            label="Pay Later"
                            btnType="btn-secondary"
                            boxClasses="box-class"
                            xtraClass="xtra-right"
                            btnStyle=""
                        /> */}
                    {/* </div> */}
                </form>
            </div>
        </Modal>
    );
};

export default PayFarmerModal;
