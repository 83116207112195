export const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: "#737a91",
        fontSize: 16,
        backgroundColor: state.isSelected ? "#cbeedb" : "",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: state.isSelected ? "" : "#cbeedb"
        },
        maxHeight: "3rem"
    }),
    container: (base, state) => ({
        ...base,
        width: "100%",
        padding: "6px 0"
    }),
    control: (base) => ({
        ...base,
        height: 32,
        minHeight: 32,
        fontSize: 16,
        borderRadius: 0,
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        border: 0,
        boxShadow: "none",
        backgroundColor: "white",
        paddingRight: "12px"
    }),
    singleValue: (base) => ({
        ...base,
        color: "#737a91"
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none"
    }),
    valueContainer: (base, state) => ({
        ...base,
        padding: 0,
        paddingLeft: "1rem",
        "&:focus": {
            outline: "none"
        }
    })
};
