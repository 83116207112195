import React from "react";
import "./styles.scss";
import { format } from "date-fns";

export const dispatchProviderData = [
    {
        produce: "Milk",
        scale: "kg",
        unit: "N700"
    },
    {
        produce: "Milk",
        scale: "kg",
        unit: "N700"
    }
];

export const dispatchProviderColumn = [
    {
        name: "Date",
        render: (item) => (
            <span className="span-container">
                <p className="date">{format(new Date(item.createdAt), "do LLL, yy")}</p>
                {/* <p className="time pl-3">{format(new Date(item.createdAt), "hh:mmaaa")}</p> */}
            </span>
        ),
        id: 1
    },
    {
        name: "Provider Name",
        index: "providerName",
        id: 2
    },
    {
        name: "Contact Name",
        index: "contactName",
        id: 3
    },
    {
        name: "Email",
        index: "companyEmail",
        id: 4
    },
    {
        name: "State",
        index: "state",
        id: 5
    },
    {
        name: "Phone No.",
        index: "companyPhoneNo",
        id: 6
    },
    {
        name: "Actions",
        id: 7,
        render: ({ edit, providerName, deleteProvider, id }) => {
            return (
                <div className="span-container">
                    <p className="edit" onClick={() => edit(providerName, id)}>
                        Edit
                    </p>
                    <p className="delete pl-3" onClick={() => deleteProvider(id, providerName)}>
                        Delete
                    </p>
                </div>
            );
        }
    }
];
