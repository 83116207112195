import * as React from "react";
import "./styles.scss";
import TabMenu from "components/TabMenu";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import Users from "./Users/index";
import Roles from "./Roles/Roles";
import Permissions from "./Permissions";

type UserMgtProps = {};

const UserMgt: React.FC<UserMgtProps> = () => {
    const menus = [
        {
            name: "Users",
            path: "users"
        },
        {
            name: "Roles",
            path: "roles"
        },
        {
            name: "Permissions",
            path: "permissions"
        }
    ];
    const match = useRouteMatch();
    return (
        <div id="user-page">
            <div className="container">
                <div className="row">
                    <div className="menus col-lg-12 col-md-12">
                        <TabMenu menus={menus} classes="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Switch>
                            <Route path={match.path} exact>
                                <Redirect to={`${match.url}/users`} />
                            </Route>
                            <Route path={`${match.path}/users`}>
                                <Users />
                            </Route>
                            <Route path={`${match.path}/roles`}>
                                <Roles />
                            </Route>
                            <Route path={`${match.path}/permissions`}>
                                <Permissions />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserMgt;
