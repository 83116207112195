import React, { useEffect } from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/people 2.png";
import Cash from "assests/icons/statsCard/cash.png";
import Leaf from "assests/icons/statsCard/leaf-sharp.png";
import Folder from "assests/icons/statsCard/folder.png";
import Community from "assests/icons/statsCard/Group_human.png";
import { getdashboardSummary } from "store/slice/DashboardSlice/DashboardSlice";
import StatsCard from "components/StatsCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import TabMenu from "components/TabMenu";
import TopFarmers from "./TopFarmers";
import TopCommunities from "./TopCommunities";
import { toCurrency } from "utils/utils";

type Props = {};

const Dashboard: React.FunctionComponent<Props> = () => {
  //Redux
  const dispatch = useDispatch();
  const { dashboardSummary, isLoading } = useSelector((state: RootState) => state.dashboard);
  const { eyowoBalance } = useSelector((state: RootState) => state.transaction);
  const { permissions } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getdashboardSummary());
  }, []);

  //Hooks
  const match = useRouteMatch();

  const menus = [
    {
      name: "Top Farmers",
      path: "farmers"
    },
    {
      name: "Top Communities",
      path: "communities"
    }
  ];

  return (
    <div id="dashboard-page">
      <div className="container">
        <div className="quick-stats mb-5">
          <h4 className="page-title">Quick Stats</h4>
          {permissions?.includes("EYOWO_BALANCE") && (
            <h5 className="eyowo_balance my-3 mr-2">Eyowo Balance : {toCurrency(eyowoBalance)}</h5>
          )}
          <div className="row mt-2">
            <StatsCard
              statsNumber={dashboardSummary?.farmers || 0}
              text="Total Farmers"
              classes="stats-icon-orange"
              image={People}
            />

            <StatsCard
              statsNumber={dashboardSummary?.community || 0}
              text="Total Communties"
              classes="stats-icon-leaf"
              image={Community}
            />

            <StatsCard
              statsNumber={dashboardSummary?.household || 0}
              text="Households"
              classes="stats-icon-folder"
              image={Folder}
            />

            <StatsCard
              statsNumber={dashboardSummary?.amount || 0}
              text="Total Amount"
              classes="stats-icon-cash"
              image={Cash}
              amount="N"
            />
          </div>
        </div>

        <div className="row">
          <div className="menus col-lg-12 col-md-12">
            <TabMenu menus={menus} classes="mt-0" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Switch>
              <Route path={match.path} exact>
                <Redirect to={`${match.url}/farmers`} />
              </Route>
              <Route path={`${match.path}/farmers`}>
                <TopFarmers />
              </Route>
              <Route path={`${match.path}/communities`}>
                <TopCommunities />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
