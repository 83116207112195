import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";
import { getStates } from "store/slice/StateSlice/StateSlice";
import ArrowDropdown from "components/ArrowDropdown";
import useArrowDropdown from "hooks/useArrowDropdown";
import { createLga, editLga, getLga, getLgaId } from "store/slice/LgaSlice/LgaSlice";
import "./style.scss";

type Props = {
  closeModal: () => void;
  value?: string;
  lgaId?: string;
};
const CreateLga = ({ closeModal, value, lgaId = "" }: Props) => {
  //Redux Store
  const dispatch = useDispatch();
  const { states } = useSelector((state: RootState) => state.stateReducer);
  const { singlelgaId, errorMessage, isLoading } = useSelector((state: RootState) => state.lga);
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onBlur"
  });

  //State
  const [currentState, setCurrentState] = useState("");
  const [error, setError] = React.useState("");

  const { open, selectedOption, handleDropdownChange, handleAction, handleToggle, keyOption, dropdownRef } =
    useArrowDropdown();

  useEffect(() => {
    dispatch(getStates(1, 1000));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lgaId && value !== "") dispatch(getLgaId(lgaId));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lgaId]);

  useEffect(() => {
    if (singlelgaId && value !== "") {
      setValue("lga_name", singlelgaId?.lga.lga_name);
      setValue("lga_code", singlelgaId?.lga.lga_code);
      states?.state?.map((data: any) => {
        if (data._id === singlelgaId?.lga?.state) return setCurrentState(data.state_name);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlelgaId]);

  type FormData = {
    lga_code: string;
    lga_name: string;
  };

  const stateList =
    states &&
    states?.state?.map((data: any) => {
      return {
        name: data.state_name,
        id: data._id
      };
    });

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    if (keyOption === "" && value === "") {
      setError("Please select a state");
      return;
    }
    if (value !== "") {
      const data = {
        ...vals,
        state_id: keyOption === "" ? singlelgaId?.lga?.state : keyOption
      };
      dispatch(
        editLga(data, lgaId, () => {
          closeModal();
          dispatch(getLga(1, 10));
        })
      );
    } else {
      const data = {
        ...vals,
        state_id: keyOption
      };
      dispatch(
        createLga(data, () => {
          closeModal();
          dispatch(getLga(1, 10));
        })
      );
    }
  };

  useEffect(() => {
    if (keyOption) {
      setError("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption]);

  return (
    <Modal modalText={value === "" ? "Create LGA" : "Edit LGA"} onClose={closeModal} boxClasses="">
      <div className="role-modal">
        {value === "" && <p className="">Complete this form with accurate data to create a new State.</p>}
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            label="State Name"
            placeholder="Enter State Name"
            name="lga_name"
            error={errors.lga_name && "This field is required"}
            customRef={register({
              required: true
            })}
            boxClasses="mb-3"
            disabled={isLoading}
          />
          <Textbox
            type="text"
            label="Code Name"
            placeholder="Enter Code Name"
            name="lga_code"
            error={errors.lga_code && "This field is required"}
            customRef={register({
              required: true
            })}
            disabled={isLoading}
          />

          <ArrowDropdown
            text="Select State"
            options={stateList}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption || currentState}
            handleToggle={handleToggle}
            open={open}
            boxClasses="mt-4"
            boxScroll="box-scroll"
            ref={dropdownRef}
          />
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

          {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
          <Button
            label={value === "" ? "Create LGA" : "Edit LGA"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default CreateLga;
